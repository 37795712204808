import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ContentCard from "../../common/template/contentCard";
import ContentCardHeader from "../../common/template/contentCardHeader";
import ContentCardBody from "../../common/template/contentCardBody";
import Row from "../../common/layout/row";
import Grid from "../../common/layout/grid";
import Table from "../../common/table/table";
import THead from "../../common/table/tHead";
import Tr from "../../common/table/tr";
import Th from "../../common/table/th";
import TBody from "../../common/table/tBody";
import Td from "../../common/table/td";
import Button from "../../common/button/button";
import Select from "../../common/form/select";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import FormatUtils from "../../common/formatUtils/FormatUtils";
import {
	getListaGraficoCustoTiposMultaAnual,
	getListaGraficoCustoMultaAnual,
	getListaGraficoCustoMultaAgrupado,
	getListaGraficoCustoReativoTipoMulta,
	getListaGraficoCustoMultaPorUnidadeAcumuladoAno,
	setFiltro,
} from "../analiseActions";
import { montarGraficoPieAnual } from "../../common/graficos/montarGraficoPieAnual.js";
import { montarGraficoClusteredColumnAnual } from "../../common/graficos/montarGraficoClusteredColumnAnual.js";
import { montarGraficoClusteredColumnGrupo } from "../../common/graficos/montarGraficoClusteredColumnGrupo.js";

function analiseMulta(props) {
	const {
		state,
		listar,
		filtro,
		listaGraficoCustoTiposMulta,
		listaGraficoCustoMulta,
		listaGraficoCustoMultaAgrupado,
		listarGraficoCustoReativoTipoMulta,
		listaGraficoCustoMultaPorUnidade,
		getListaGraficoCustoTiposMultaAnual,
		getListaGraficoCustoMultaAnual,
		getListaGraficoCustoMultaAgrupado,
		getListaGraficoCustoReativoTipoMulta,
		getListaGraficoCustoMultaPorUnidadeAcumuladoAno,
		setAguardando,
		setFiltro,
	} = props;

	const [graficoCustoTiposMultaAnual, setGraficoCustoTiposMultaAnual] = useState(null);
	const [graficoCustoMultaAnual, setGraficoCustoMultaAnual] = useState(null);
	const [graficoCustoMultaTipo, setGraficoCustoMultaTipo] = useState(null);
	const [graficoCustoReativoTipoMulta, setGraficoCustoReativoTipoMulta] = useState(null);

	useEffect(() => {
		atualizarTodosGraficos();
		return desmontarTodosGraficos;
	}, []);

	useEffect(() => {
		const buscarDados = async () => {
			if (!state.aguardando) return;

			await Promise.all([
				getListaGraficoCustoTiposMultaAnual(),
				getListaGraficoCustoMultaAnual(),
				getListaGraficoCustoMultaAgrupado(),
				getListaGraficoCustoReativoTipoMulta(),
				getListaGraficoCustoMultaPorUnidadeAcumuladoAno(),
			]);

			setAguardando(false);
		};

		buscarDados();
		atualizarTodosGraficos();
	}, [state.aguardando]);

	function atualizarTodosGraficos() {
		atualizarListaGraficoCustoTiposMultaAnual();
		atualizarListaGraficoCustoMultaAnual();
		atualizarListaGraficoCustoMultaAgrupado();
		atualizarListaGraficoCustoReativoTipoMulta();
	}
	function desmontarTodosGraficos() {
		if (graficoCustoTiposMultaAnual) graficoCustoTiposMultaAnual.dispose();
		if (graficoCustoMultaAnual) graficoCustoMultaAnual.dispose();
		if (graficoCustoMultaTipo) graficoCustoMultaTipo.dispose();
		if (graficoCustoReativoTipoMulta) graficoCustoReativoTipoMulta.dispose();
	}
	function atualizarListaGraficoCustoTiposMultaAnual() {
		const graficoCustoTiposMultaAnualAtualizado = montarGraficoClusteredColumnGrupo(
			state,
			graficoCustoTiposMultaAnual,
			"graficoCustoTiposMultaAnual",
			listaGraficoCustoTiposMulta,
			"Custo Total Por Tipo de Multa (R$)",
			"R$",
			""
		);
		setGraficoCustoTiposMultaAnual(graficoCustoTiposMultaAnualAtualizado);
	}

	function atualizarListaGraficoCustoMultaAnual() {
		const graficoCustoMultaAnualAtualizado = montarGraficoClusteredColumnAnual(
			state,
			graficoCustoMultaAnual,
			"graficoCustoMultaAnual",
			listaGraficoCustoMulta,
			"Custo Total de Multas (R$)",
			"R$",
			""
		);
		setGraficoCustoMultaAnual(graficoCustoMultaAnualAtualizado);
	}

	function atualizarListaGraficoCustoMultaAgrupado() {
		const graficoCustoMultaTipoAtualizado = montarGraficoPieAnual(
			state,
			graficoCustoMultaTipo,
			"graficoCustoMultaTipo",
			listaGraficoCustoMultaAgrupado,
			"Custo Geral por Tipo de Multa (R$)",
			"R$",
			""
		);
		setGraficoCustoMultaTipo(graficoCustoMultaTipoAtualizado);
	}

	function atualizarListaGraficoCustoReativoTipoMulta() {
		const graficoCustoReativoTipoMultaAtualizado = montarGraficoPieAnual(
			state,
			graficoCustoReativoTipoMulta,
			"graficoCustoReativoTipoMulta",
			listarGraficoCustoReativoTipoMulta,
			"Custo de Multa de Reativo por Tipo (R$)",
			"R$",
			""
		);
		setGraficoCustoReativoTipoMulta(graficoCustoReativoTipoMultaAtualizado);
	}

	let anos = [];
	listaGraficoCustoMulta.forEach((item) => {
		item.anos.forEach((itemAno) => {
			if (anos.filter((ano) => ano.ano == itemAno.ano)[0]) {
				anos = anos.map((ano) => {
					if (ano.ano == itemAno.ano) {
						return {
							...ano,
							valorMulta: ano.valorMulta + (itemAno.valor || 0),
							valorFatura: ano.valorFatura + (itemAno.valor_fatura || 0),
						};
					} else {
						return ano;
					}
				});
			} else {
				anos.push({
					ano: itemAno.ano,
					valorMulta: itemAno.valor || 0,
					valorFatura: itemAno.valor_fatura || 0,
				});
			}
		});
	});

	anos = anos.sort((a, b) => a.ano - b.ano);

	let valorUnidadeAnualTotal = 0;
	listaGraficoCustoMultaPorUnidade.forEach((item) => {
		valorUnidadeAnualTotal = parseFloat(item.valor) > valorUnidadeAnualTotal ? parseFloat(item.valor) : valorUnidadeAnualTotal;
	});

	return (
        <div>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <div id="graficoCustoMultaAnual" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                </ContentCardBody>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <div
                        style={{
                            minWidth: "890px",
                        }}
                    >
                        <Table responsive>
                            <THead>
                                <Tr>
                                    <Th width={200}></Th>
                                    {anos.map((ano) => (
                                        <Th alignCenter>{ano.ano}</Th>
                                    ))}
                                </Tr>
                            </THead>
                            <TBody>
                                <Tr>
                                    <Td color="#fff">R$ Total</Td>
                                    {anos.map((ano) => (
                                        <Td key={ano.ano} alignCenter color="#fff">
                                            {parseFloat(ano.valorFatura || 0)
                                                .toFixed(2)
                                                .replace(".", ",")}
                                        </Td>
                                    ))}
                                </Tr>
                                <Tr>
                                    <Td color="#fff">R$ Multa</Td>
                                    {anos.map((ano) => (
                                        <Td key={ano.ano} alignCenter color="#fff">
                                            {parseFloat(ano.valorMulta || 0)
                                                .toFixed(2)
                                                .replace(".", ",")}
                                        </Td>
                                    ))}
                                </Tr>
                                <Tr>
                                    <Td color="#fff">Rel. %</Td>
                                    {anos.map((ano) => (
                                        <Td key={ano.ano} alignCenter color="#fff">
                                            {parseFloat(ano.valorFatura > 0 ? (ano.valorMulta * 100) / ano.valorFatura : 0)
                                                .toFixed(2)
                                                .replace(".", ",")}
                                            %
                                        </Td>
                                    ))}
                                </Tr>
                            </TBody>
                        </Table>
                    </div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard
                style={{
                    color: "#fff",
                    padding: 8,
                    display: true /*!buscarPropriedadeUcFiltrada() || buscarPropriedadeUcFiltrada("irrigante")*/ ? "" : "none",
                }}
            >
                <ContentCardHeader style={{ backgroundColor: "#fff" }}>
                    <Row>
                        <Grid cols="6 4 3 2">
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                <DatePicker
                                    variant="inline"
                                    openTo="year"
                                    views={["year", "month"]}
                                    label="De"
                                    autoOk
                                    value={
                                        filtro.competencia_inicial_multa
                                            ? `${String(filtro.competencia_inicial_multa).substring(0, 4)}/${String(filtro.competencia_inicial_multa).substring(4, 6)}/01 12:00:00`
                                            : null
                                    }
                                    onChange={(value) => {
                                        setFiltro({
                                            ...filtro,
                                            competencia_inicial_multa: value ? parseInt(`${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${value.getMonth() + 1}`) : null,
                                        });
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid cols="6 4 3 2">
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                <DatePicker
                                    variant="inline"
                                    openTo="year"
                                    views={["year", "month"]}
                                    label="Até"
                                    autoOk
                                    value={
                                        filtro.competencia_final_multa
                                            ? `${String(filtro.competencia_final_multa).substring(0, 4)}/${String(filtro.competencia_final_multa).substring(4, 6)}/01 12:00:00`
                                            : null
                                    }
                                    onChange={(value) => {
                                        setFiltro({
                                            ...filtro,
                                            competencia_final_multa: value ? parseInt(`${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${value.getMonth() + 1}`) : null,
                                        });
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid cols="12 4 3 2" style={{ paddingTop: 10 }}>
                            <Button
                                text="Limpar Período"
                                type={"primary"}
                                icon={"fa fa-minus"}
                                event={async () => {
                                    setFiltro({
                                        ...filtro,
                                        competencia_inicial_multa: null,
                                        competencia_final_multa: null,
                                    });
                                }}
                            />
                        </Grid>
                        <Grid cols="6 4 3 2" style={{ paddingTop: 10 }}>
                            <Button text="Atualizar" type={"primary"} icon={"fa fa-sync"} event={listar} />
                        </Grid>
                    </Row>
                </ContentCardHeader>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <Row>
                        <Grid cols="6 6 6 6">
                            <div id="graficoCustoMultaTipo" style={{ width: "100%", paddingBlock: 20, height: "300px" }}></div>
                        </Grid>
                        <Grid cols="6 6 6 6">
                            <div id="graficoCustoReativoTipoMulta" style={{ width: "100%", paddingBlock: 20, height: "300px" }}></div>
                        </Grid>
                    </Row>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ padding: 8 }}>
                <ContentCardHeader>
                    <Select
                        name="ano"
                        options={Array.from({ length: 20 }, (v, i) => ({
                            id: new Date().getFullYear() - 5 + i,
                            valor: new Date().getFullYear() - 5 + i,
                        }))}
                        label="Ano"
                        cols="12 6 2"
                        placeholder="Selecione o ano"
                        value={filtro.anoMultaAcumulado}
                        onChange={(value) => {
                            setFiltro({
                                ...filtro,
                                anoMultaAcumulado: value,
                            });
                            listar();
                        }}
                    />
                </ContentCardHeader>
                <ContentCardBody
                    style={{
                        color: "#fff",
                        backgroundColor: "#30303d",

                        overflowX: "scroll",
                    }}
                >
                    <div style={{ width: "100%", minHeight: "200px" }}>
                        <p className="text-center" style={{ fontSize: 16 }}>
                            Custo Acumulado Anual de Multas por UC (R$)
                        </p>
                        {listaGraficoCustoMultaPorUnidade.map((item, i) => (
                            <div className="progress-group" style={{ paddingLeft: 8, paddingRight: 8 }}>
                                <Row>
                                    <Grid cols="8 8 8 8">{item.agrupador}</Grid>
                                    <Grid cols="4 4 4 4">
                                        <strong>
                                            <span className="float-right">R$ {FormatUtils.formatarValorTela(item.valor, 2)}</span>
                                        </strong>
                                    </Grid>
                                </Row>
                                <div className="progress progress-sm">
                                    <div
                                        className="progress-bar"
                                        style={{
                                            backgroundColor: state.cores[i],
                                            width: `${(parseFloat(item.valor) * 100) / valorUnidadeAnualTotal}%`,
                                        }}
                                    ></div>
                                </div>
                            </div>
                        ))}
                    </div>
                </ContentCardBody>
            </ContentCard>
        </div>
    );
}

const mapStateToProps = (state) => ({
	filtro: state.analise.filtro,
	listaGraficoCustoTiposMulta: state.analise.listaGraficoCustoTiposMulta,
	listaGraficoCustoMulta: state.analise.listaGraficoCustoMulta,
	listaGraficoCustoMultaAgrupado: state.analise.listaGraficoCustoMultaAgrupado,
	listarGraficoCustoReativoTipoMulta: state.analise.listarGraficoCustoReativoTipoMulta,
	listaGraficoCustoMultaPorUnidade: state.analise.listaGraficoCustoMultaPorUnidade,
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getListaGraficoCustoMultaAnual,
			getListaGraficoCustoTiposMultaAnual,
			getListaGraficoCustoMultaAgrupado,
			getListaGraficoCustoReativoTipoMulta,
			getListaGraficoCustoMultaPorUnidadeAcumuladoAno,
			setFiltro,
		},
		dispatch
	);
const AnaliseMulta = connect(mapStateToProps, mapDispatchToProps)(analiseMulta);
export { AnaliseMulta };
