import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import Select from "../../common/form/select";
import Content from "../../common/template/content";
import ContentCard from "../../common/template/contentCard";
import ContentCardHeader from "../../common/template/contentCardHeader";
import ContentCardBody from "../../common/template/contentCardBody";
import ContentCardFooter from "../../common/template/contentCardFooter";
import Row from "../../common/layout/row";
import Grid from "../../common/layout/grid";
import Table from "../../common/table/table";
import THead from "../../common/table/tHead";
import Tr from "../../common/table/tr";
import Th from "../../common/table/th";
import TBody from "../../common/table/tBody";
import Td from "../../common/table/td";
import NewTr from "../../common/table/newTr";
import Button from "../../common/button/button";
import Form from "./faturaItemForm";

import { setModoTela, initForm, salvar, excluir, getLista, getListaProduto, getListaUnidadeMedida, importar, limpar, redefinirTipo } from "./faturaItemActions";

class FaturaItem extends Component {
    state = {
        linhaSelecionada: null,
        idItemAlteracaoTipo: null,
        id_tipo: null,
    };

    componentWillMount() {
        this.props.getListaProduto();
        this.props.getListaUnidadeMedida();
        if (this.props.faturaFormularioValues && this.props.faturaFormularioValues.id) {
            this.props.getLista(this.props.faturaFormularioValues.id);
        }
    }

    render() {
        return (
            <div>
                <Content>
                    <Row>
                        <Grid cols="12 12 12 12">
                            <label>Itens</label>
                        </Grid>
                    </Row>
                    {this.props.modoTela == "cadastro" ? <Form onSubmit={this.props.salvar} /> : null}
                    {this.props.modoTela == "importar" ? this.importar() : null}
                    {this.props.modoTela == "limpar" ? this.limpar() : null}
                    {this.props.modoTela == "exclusao" ? <Form excluir onSubmit={this.props.excluir} /> : null}
                    {this.props.modoTela == "lista" ? this.lista() : null}
                </Content>
            </div>
        );
    }

    lista() {
        let usuarioEdicao = this.props.usuarioLogado && (this.props.usuarioLogado.acesso_fatura_editar || this.props.usuarioLogado.acesso_fatura_editar_fechado);
        let usuarioLogadoAdmin = [1, 2].includes(this.props.usuarioLogado.id); //só permite para TI ou para o marthielo
        let valorTotal = 0;

        return (
            <ContentCard>
                {!this.props.visualizacao ? (
                    <ContentCardHeader>
                        <Row>
                            <Grid cols="6 6 4 2">
                                <Button
                                    text="Adicionar"
                                    type={"success"}
                                    disabled={this.props.faturaFormularioValues.importacao_validada || !usuarioEdicao}
                                    icon={"fa fa-plus"}
                                    event={() => {
                                        this.props.setModoTela("cadastro", {
                                            id_fatura: this.props.faturaFormularioValues && this.props.faturaFormularioValues.id ? this.props.faturaFormularioValues.id : null,
                                        });
                                        this.props.initForm({
                                            id_fatura: this.props.faturaFormularioValues && this.props.faturaFormularioValues.id ? this.props.faturaFormularioValues.id : null,
                                        });
                                    }}
                                />
                            </Grid>
                            {this.props.listaUnidadeConsumidora.filter((uc) => uc.id == this.props.faturaFormularioValues.id_unidade_consumidora)[0] &&
                            this.props.listaUnidadeConsumidora.filter((uc) => uc.id == this.props.faturaFormularioValues.id_unidade_consumidora)[0].geracao ? (
                                <Grid cols="6 6 4 3">
                                    <Button
                                        text="Produção Gerador Solar (kWh)"
                                        type={"success"}
                                        disabled={this.props.faturaFormularioValues.importacao_validada || !usuarioEdicao}
                                        icon={"fa fa-plus"}
                                        event={() => {
                                            this.props.setModoTela("cadastro", {
                                                id_fatura: this.props.faturaFormularioValues && this.props.faturaFormularioValues.id ? this.props.faturaFormularioValues.id : null,
                                                id_produto: 659,
                                                id_unidade_medida: 12,
                                            });
                                            this.props.initForm({
                                                id_fatura: this.props.faturaFormularioValues && this.props.faturaFormularioValues.id ? this.props.faturaFormularioValues.id : null,
                                                id_produto: 659,
                                                id_unidade_medida: 12,
                                            });
                                        }}
                                    />
                                </Grid>
                            ) : null}
                            <Grid cols="6 6 4 2">
                                <Button
                                    text="Importar"
                                    type={"primary"}
                                    disabled={this.props.faturaFormularioValues.importacao_validada || !usuarioEdicao}
                                    icon={"fa fa-file-import"}
                                    event={() => {
                                        this.props.setModoTela("importar", {
                                            id_fatura: this.props.faturaFormularioValues && this.props.faturaFormularioValues.id ? this.props.faturaFormularioValues.id : null,
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid cols="6 6 4 2">
                                <Button
                                    text="Limpar"
                                    type={"danger"}
                                    disabled={this.props.faturaFormularioValues.importacao_validada || !usuarioEdicao}
                                    icon={"fa fa-minus"}
                                    event={() => {
                                        this.props.setModoTela("limpar", {
                                            id_fatura: this.props.faturaFormularioValues && this.props.faturaFormularioValues.id ? this.props.faturaFormularioValues.id : null,
                                        });
                                    }}
                                />
                            </Grid>
                        </Row>
                    </ContentCardHeader>
                ) : null}
                <ContentCardBody>
                    <Table responsive>
                        <THead>
                            <Tr>
                                <Th sticky>Produto</Th>
                                <Th sticky>Tipo</Th>
                                <Th sticky>Unid Medida</Th>
                                <Th sticky alignRight>
                                    Qtd Registrada
                                </Th>
                                <Th sticky alignRight>
                                    Qtd Faturada
                                </Th>
                                <Th sticky alignRight>
                                    Tarifa
                                </Th>
                                <Th sticky alignRight>
                                    Vlr Imposto
                                </Th>
                                <Th sticky alignRight>
                                    Vlr Total
                                </Th>
                                <Th sticky></Th>
                            </Tr>
                        </THead>
                        <TBody>
                            {this.props.lista.map((item, i) => {
                                valorTotal += parseFloat(item.valor_total || 0);

                                const botoesICMS = [
                                    {
                                        type: "warning",
                                        icon: "fas fa-pencil-alt",
                                        disabled: this.props.faturaFormularioValues.importacao_validada || this.props.visualizacao,
                                        event: this.props.exibirModalIcms,
                                    },
                                ];
                                const botoesItens = [
                                    {
                                        type: "warning",
                                        icon: "fas fa-pencil-alt",
                                        disabled: this.props.faturaFormularioValues.importacao_validada || this.props.visualizacao,
                                        event: () => {
                                            let registro = {
                                                ...item,
                                                quantidade_registrada: String(item.quantidade_registrada || 0)
                                                    .split(".")
                                                    .join(","),
                                                quantidade_faturada: String(item.quantidade_faturada || 0)
                                                    .split(".")
                                                    .join(","),
                                                tarifa: String(item.tarifa || 0)
                                                    .split(".")
                                                    .join(","),
                                                valor_imposto: String(item.valor_imposto || 0)
                                                    .split(".")
                                                    .join(","),
                                                valor_total: String(item.valor_total || 0)
                                                    .split(".")
                                                    .join(","),
                                            };
                                            this.props.setModoTela("cadastro", registro);
                                            this.props.initForm(registro);
                                        },
                                    },
                                    {
                                        type: "danger",
                                        icon: "fas fa-trash-alt",
                                        disabled: this.props.faturaFormularioValues.importacao_validada || this.props.visualizacao || !usuarioEdicao,
                                        event: () => {
                                            let registro = {
                                                ...item,
                                                quantidade_registrada: String(item.quantidade_registrada || 0)
                                                    .split(".")
                                                    .join(","),
                                                quantidade_faturada: String(item.quantidade_faturada || 0)
                                                    .split(".")
                                                    .join(","),
                                                tarifa: String(item.tarifa || 0)
                                                    .split(".")
                                                    .join(","),
                                                valor_imposto: String(item.valor_imposto || 0)
                                                    .split(".")
                                                    .join(","),
                                                valor_total: String(item.valor_total || 0)
                                                    .split(".")
                                                    .join(","),
                                            };
                                            this.props.setModoTela("exclusao", registro);
                                            this.props.initForm(registro);
                                        },
                                    },
                                ];

                                return (
                                    <>
                                        {i > 0 ? null : (
                                            <NewTr
                                                key={this.props.faturaFormularioValues.id + "-icms"}
                                                style={{
                                                    filter: (isHovered) => (isHovered ? "brightness(0.95)" : ""),
                                                }}
                                                celulas={
                                                    <>
                                                        <Td>ICMS Importado</Td>
                                                        <Td></Td>
                                                        <Td>%</Td>
                                                        <Td alignRight>
                                                            {String(this.props.faturaFormularioValues.icms_importado || 0)
                                                                .split(".")
                                                                .join(",")}
                                                        </Td>
                                                        <Td></Td>
                                                        <Td></Td>
                                                        <Td></Td>
                                                        <Td></Td>
                                                    </>
                                                }
                                                botoes={botoesICMS}
                                            />
                                        )}
                                        <NewTr
                                            key={item.id}
                                            style={{
                                                filter: (isHovered) => (isHovered ? "brightness(0.95)" : ""),
                                            }}
                                            celulas={
                                                <>
                                                    <Td>{item.nome_produto}</Td>
                                                    <Td>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                padding: 5,
                                                                marginRight: 80,
                                                                borderRadius: "0.3rem",
                                                                border: this.state.linhaSelecionada == item.id ? "1px solid #ccc" : "1px solid transparent",
                                                            }}
                                                            onMouseEnter={(e) => {
                                                                //se não for um dos admin não exibe os botões
                                                                if (!usuarioLogadoAdmin) return;
                                                                this.setState({ ...this.state, linhaSelecionada: this.state.idItemAlteracaoTipo ? null : item.id });
                                                            }}
                                                            onMouseLeave={(e) => {
                                                                this.setState({ ...this.state, linhaSelecionada: null });
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexWrap: "nowrap",
                                                                    flexGrow: 1,
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                {this.state.idItemAlteracaoTipo == item.id ? (
                                                                    <>
                                                                        <Select
                                                                            options={this.props.listaProdutoTipo}
                                                                            cols="12 12 12 12"
                                                                            placeholder="Selecione o tipo"
                                                                            value={this.state.id_tipo}
                                                                            onChange={(value) => {
                                                                                this.setState({
                                                                                    ...this.state,
                                                                                    id_tipo: value,
                                                                                });
                                                                            }}
                                                                        />
                                                                        <button
                                                                            style={{
                                                                                borderRadius: "0.3rem",
                                                                                marginTop: 5,
                                                                                padding: "5px 10px",
                                                                                color: "#FFF",
                                                                                backgroundColor: "#F44",
                                                                                height: "fit-content",
                                                                                outline: "none",
                                                                            }}
                                                                            disabled={
                                                                                this.props.faturaFormularioValues.importacao_validada ||
                                                                                this.props.visualizacao ||
                                                                                !usuarioLogadoAdmin
                                                                            }
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    ...this.state,
                                                                                    idItemAlteracaoTipo: null,
                                                                                    id_tipo: null,
                                                                                });
                                                                            }}
                                                                        >
                                                                            <i className="fas fa-times"></i>
                                                                        </button>
                                                                        <button
                                                                            style={{
                                                                                borderRadius: "0.3rem",
                                                                                marginTop: 5,
                                                                                padding: "5px 8px",
                                                                                color: "#FFF",
                                                                                backgroundColor: "#39d139",
                                                                                height: "fit-content",
                                                                                outline: "none",
                                                                            }}
                                                                            disabled={
                                                                                this.props.faturaFormularioValues.importacao_validada ||
                                                                                this.props.visualizacao ||
                                                                                !usuarioLogadoAdmin
                                                                            }
                                                                            onClick={() => {
                                                                                this.props.redefinirTipo({
                                                                                    id: item.id_produto,
                                                                                    id_tipo: this.state.id_tipo,
                                                                                    id_fatura: this.props.faturaFormularioValues.id,
                                                                                });
                                                                                this.setState({
                                                                                    ...this.state,
                                                                                    idItemAlteracaoTipo: null,
                                                                                    id_tipo: null,
                                                                                });
                                                                            }}
                                                                        >
                                                                            <i className="fas fa-check"></i>
                                                                        </button>
                                                                    </>
                                                                ) : (
                                                                    item.nome_tipo
                                                                )}
                                                            </div>

                                                            {this.state.idItemAlteracaoTipo ? null : (
                                                                <button
                                                                    style={{
                                                                        visibility: this.state.linhaSelecionada == item.id ? "visible" : "hidden",
                                                                        borderRadius: "0.3rem",
                                                                        outline: "none",
                                                                        padding: "5px 8px",
                                                                        color: `${item.id_tipo ? "#000" : "#FFF"}`,
                                                                        backgroundColor: `${item.id_tipo ? "#Fd2" : "#05F"}`,
                                                                    }}
                                                                    disabled={
                                                                        this.props.faturaFormularioValues.importacao_validada || this.props.visualizacao || !usuarioLogadoAdmin
                                                                    }
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            ...this.state,
                                                                            idItemAlteracaoTipo: item.id,
                                                                            id_tipo: item.id_tipo,
                                                                            linhaSelecionada: null,
                                                                        });
                                                                    }}
                                                                >
                                                                    <i className={`fas ${item.id_tipo ? "fa-pencil-alt" : "fa-plus"}`}></i>
                                                                </button>
                                                            )}
                                                        </div>
                                                    </Td>
                                                    <Td>{item.nome_unidade_medida}</Td>
                                                    <Td alignRight>
                                                        {String(item.quantidade_registrada || 0)
                                                            .split(".")
                                                            .join(",")}
                                                    </Td>
                                                    <Td alignRight>
                                                        {String(item.quantidade_faturada || 0)
                                                            .split(".")
                                                            .join(",")}
                                                    </Td>
                                                    <Td alignRight>
                                                        {String(item.tarifa || 0)
                                                            .split(".")
                                                            .join(",")}
                                                    </Td>
                                                    <Td alignRight>
                                                        {String(item.valor_imposto || 0)
                                                            .split(".")
                                                            .join(",")}
                                                    </Td>
                                                    <Td alignRight>
                                                        {String(item.valor_total || 0)
                                                            .split(".")
                                                            .join(",")}
                                                    </Td>
                                                </>
                                            }
                                            botoes={botoesItens}
                                        />
                                    </>
                                );
                            })}

                            <Tr>
                                <Td>Total</Td>
                                <Td></Td>
                                <Td></Td>
                                <Td alignRight></Td>
                                <Td alignRight></Td>
                                <Td alignRight></Td>
                                <Td alignRight></Td>
                                <Td alignRight>{(valorTotal || 0).toFixed(5).split(".").join(",")}</Td>
                                <Td alignRight minWidth={100}></Td>
                            </Tr>
                        </TBody>
                    </Table>
                </ContentCardBody>
            </ContentCard>
        );
    }

    importar() {
        return (
            <ContentCard>
                <ContentCardBody>
                    <div className="form-group">
                        <div className="custom-file">
                            <input
                                className="custom-file-input"
                                type="file"
                                id="customFile"
                                accept="application/pdf"
                                value={this.state.file}
                                onChange={(event) => {
                                    event.preventDefault();
                                    let reader = new FileReader();
                                    let file = event.target.files[0];

                                    if (!file.type.includes("application/pdf")) {
                                        //toastr.error('Erro', 'Selecione apenas PDF.');
                                        return;
                                    }

                                    reader.onloadend = () => {
                                        this.props.importar(
                                            this.props.faturaFormularioValues && this.props.faturaFormularioValues.id ? this.props.faturaFormularioValues.id : null,
                                            reader.result
                                        );
                                    };

                                    reader.readAsDataURL(file);
                                }}
                            />
                            <label className="custom-file-label" for="customFile">
                                Selecione
                            </label>
                        </div>
                    </div>
                </ContentCardBody>
            </ContentCard>
        );
    }

    limpar() {
        return (
            <ContentCard>
                <ContentCardBody>
                    <span>Ao confirmar a exclusão, todos os itens dessa fatura serão removidos. Deseja realmente limpar os itens da fatura?</span>
                </ContentCardBody>
                <ContentCardFooter>
                    <Row alignCenter>
                        <Grid cols="6 6 4 3">
                            <Button
                                text="Confirmar Exclusão"
                                type={"danger"}
                                icon={"fa fa-trash"}
                                event={() => {
                                    this.props.limpar(this.props.lista);
                                }}
                            />
                        </Grid>
                        <Grid cols="6 6 4 3">
                            <Button text="Voltar" type={"warning"} icon={"fa fa-chevron-left"} event={() => this.props.setModoTela("lista")} />
                        </Grid>
                    </Row>
                </ContentCardFooter>
            </ContentCard>
        );
    }
}

const mapStateToProps = (state) => ({
    sessao: state.auth.usuario,
    usuarioLogado: state.auth.usuarioLogado,
    modoTela: state.faturaItem.modoTela,
    lista: state.faturaItem.lista,
    listaUnidadeConsumidora: state.fatura.listaUnidadeConsumidora,
    listaProdutoTipo: state.fatura.listaProdutoTipo,
    faturaFormularioValues: getFormValues("faturaForm")(state),
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, getListaProduto, getListaUnidadeMedida, importar, limpar, redefinirTipo }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(FaturaItem);
