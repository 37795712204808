import React from "react";
import ReactDOM from "react-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { setError } from "../common/toast/toast";

export function htmlToPdf(componente, callbackAguardando, orientation = "landscape") {
	return new Promise((resolve, reject) => {
		// Cria um novo frame invisível e adiciona à div app
		const screen = document.createElement("iframe");
		screen.setAttribute("id", "printFrame");
		const app = document.getElementById("app");
		app.appendChild(screen);
		screen.style.visibility = "hidden";
		screen.contentWindow.document.body.style = `
      margin:0;
      font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #212529;
      text-align: left;
      background-color:#ffffff;
    `;

		const targetNode = screen.contentWindow.document.body;

		if (componente.current) {
			targetNode.appendChild(componente.current.cloneNode(true));
			processHtmlToPdf(screen, orientation, callbackAguardando)
				.then(resolve)
				.catch(reject)
				.finally(() => app.removeChild(screen));
		} else {
			ReactDOM.render(componente, targetNode, () => {
				processHtmlToPdf(screen, orientation, callbackAguardando)
					.then(resolve)
					.catch(reject)
					.finally(() => app.removeChild(screen));
			});
		}
	});
}

function processHtmlToPdf(screen, orientation, callbackAguardando) {
	return new Promise((resolve, reject) => {
		html2canvas(screen.contentWindow.document.body, {
			allowTaint: true,
		})
			.then((canvas) => {
				const doc = new jsPDF(orientation, "mm", "a4");
				const imgData = canvas.toDataURL("image/jpeg", 1.0);
				const imgWidth = doc.internal.pageSize.getWidth();
				const pageHeight = doc.internal.pageSize.getHeight();
				const imgHeight = (canvas.height * imgWidth) / canvas.width;
				const totalPageSize = Math.ceil(imgHeight / pageHeight);
				let yPosition = 10;

				doc.addImage(imgData, "JPEG", 0, yPosition, imgWidth, imgHeight);

				for (let pageIndex = 1; pageIndex < totalPageSize; pageIndex++) {
					yPosition = pageIndex * pageHeight * -1 + 10;
					if (pageIndex < totalPageSize - 1) doc.addPage("a4", orientation);
					doc.addImage(imgData, "JPEG", 0, yPosition, imgWidth, imgHeight);
				}

				callbackAguardando(false);

				const blob = doc.output("blob");
				const reader = new FileReader();
				reader.readAsDataURL(blob);
				reader.onloadend = function () {
					const pdfBase64 = reader.result;
					resolve(pdfBase64);
				};
			})
			.catch((err) => {
				reject(setError("Erro ao gerar o relatório em PDF"));
			});
	});
}
