import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";

import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import Tr from "../common/table/tr";
import NewTr from "../common/table/newTr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Select from "../common/form/select";
import Td from "../common/table/td";
import ButtonTable from "../common/table/buttonTable";
import Button from "../common/button/button";
import Form from "./usuarioForm";
import Switch from "react-switch";

import { setModoTela, initForm, salvar, excluir, getLista, setPermissao, getListaCliente } from "./usuarioActions";

import { buscarSessao } from "../auth/authActions";

class Usuario extends Component {
	state = {
		id_cliente: null,
	};
	componentWillUnmount() {
		this.props.buscarSessao();
	}

	componentWillMount() {
		let idInterval = setInterval(() => {
			if (this.props.usuarioLogado && this.props.usuarioLogado.administrador) {
				this.props.getLista();
				clearInterval(idInterval);
			} else if (this.props.usuarioLogado && this.props.usuarioLogado.acesso_cliente) {
				this.props.getLista(this.props.usuarioLogado.id_cliente);
				clearInterval(idInterval);
			}
		}, 2000);
		this.props.getListaCliente();
	}

	render() {
		return (
			<div style={{ paddingBottom: "calc(100vh - 100%)" }}>
				<Content>
					{this.props.modoTela == "cadastro" ? <Form onSubmit={this.props.salvar} /> : null}
					{this.props.modoTela == "exclusao" ? <Form excluir onSubmit={this.props.excluir} /> : null}
					{this.props.modoTela == "lista" ? this.lista() : null}
				</Content>
			</div>
		);
	}

	lista() {
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols="6 6 4 2">
							<Button
								text="Adicionar"
								type={"success"}
								icon={"fa fa-plus"}
								disabled={!this.props.usuarioLogado.acesso_usuario_adicionar}
								event={() => {
									this.props.setModoTela("cadastro", {});
									this.props.initForm({
										administrador: this.props.usuarioLogado.id_cliente ? false : true,
										acesso_cliente: this.props.usuarioLogado.id_cliente ? true : false,
										id_cliente: this.props.usuarioLogado.id_cliente,
									});
								}}
							/>
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					{this.props.usuarioLogado && this.props.usuarioLogado.administrador ? (
						<Row>
							<Select
								name="id_cliente"
								options={this.props.listaCliente}
								label="Cliente"
								cols="12 6 4"
								placeholder="Selecione o Cliente"
								value={this.state.id_cliente}
								onChange={(value) => {
									this.setState({
										...this.state,
										id_cliente: value,
									});
								}}
							/>
						</Row>
					) : null}

					<Table responsive>
						<THead>
							<Tr>
								<Th sticky>Nome</Th>
								<Th sticky>Login</Th>
								{this.props.usuarioLogado && this.props.usuarioLogado.administrador ? <Th sticky>Senha</Th> : null}
								{this.props.usuarioLogado && this.props.usuarioLogado.administrador ? <Th sticky>Cliente</Th> : null}
								<Th sticky minWidth={70} alignCenter>
									Usuários
								</Th>
								<Th sticky minWidth={70} alignCenter>
									Usuários Logados
								</Th>
								<Th sticky minWidth={70} alignCenter>
									Cliente
								</Th>
								<Th sticky minWidth={70} alignCenter>
									Empenho
								</Th>
								<Th sticky minWidth={70} alignCenter>
									Gestão Tarefas
								</Th>
								<Th sticky minWidth={70} alignCenter>
									Gestão Tarefas Andamento
								</Th>
								<Th sticky minWidth={70} alignCenter>
									Demanda
								</Th>
								<Th sticky minWidth={70} alignCenter>
									Período Teste Demanda
								</Th>
								<Th sticky minWidth={70} alignCenter>
									Geração
								</Th>
								<Th sticky minWidth={70} alignCenter>
									Tributação
								</Th>
								<Th sticky minWidth={70} alignCenter>
									Fatura
								</Th>
								<Th sticky minWidth={70} alignCenter>
									Identificador Multas (Energia)
								</Th>
								<Th sticky minWidth={70} alignCenter>
									Verificador ICMS (Energia)
								</Th>
								<Th sticky minWidth={70} alignCenter>
									Verificador Consumo (Água)
								</Th>
								<Th sticky minWidth={70} alignCenter>
									Análises
								</Th>
								<Th sticky minWidth={70} alignCenter>
									Relatório
								</Th>
								<Th sticky minWidth={80} alignCenter>
									Documento
								</Th>
								<Th sticky minWidth={80} alignCenter>
									Documento Eficience
								</Th>
								<Th sticky minWidth={60} alignCenter>
									Redes
								</Th>
								<Th sticky minWidth={60} alignCenter>
									Solar
								</Th>
								<Th sticky></Th>
							</Tr>
						</THead>

						<TBody>
							{this.props.lista
								.filter(
									(item) =>
										!this.state.id_cliente || (this.state.id_cliente == "admin" && !item.id_cliente) || item.id_cliente == this.state.id_cliente
								)
								.map((item) => (
									<NewTr
										key={item.id}
										style={{
											filter: (isHovered) => (isHovered ? "brightness(0.95)" : ""),
											backgroundColor: "#ffffff",
										}}
										celulas={
											<>
												<Td verticalAlign="top" paddingBlock={10}>
													{item.nome}
												</Td>
												<Td verticalAlign="top" paddingBlock={10}>
													{item.login}
												</Td>
												{this.props.usuarioLogado && this.props.usuarioLogado.administrador ? (
													<Td verticalAlign="top" paddingBlock={10}>
														{item.senha_cliente}
													</Td>
												) : null}
												{this.props.usuarioLogado && this.props.usuarioLogado.administrador ? (
													<Td verticalAlign="top" paddingBlock={10}>
														{item.nome_cliente}
													</Td>
												) : null}
												<Td alignCenter verticalAlign="top" paddingBlock={10}>
													<div
														style={{
															display: "flex",
															width: "73px",
															padding: 0,
															flexWrap: "wrap",
														}}
													>
														<BotaoPermissao
															title="Permite visualizar"
															colorCondition={item.acesso_usuario_visualizar}
															newValue={{
																...item,
																acesso_usuario_visualizar: !item.acesso_usuario_visualizar,
															}}
															icon="fa fa-eye"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>

														<BotaoPermissao
															title="Permite adicionar"
															colorCondition={item.acesso_usuario_adicionar}
															newValue={{
																...item,
																acesso_usuario_adicionar: !item.acesso_usuario_adicionar,
															}}
															icon="fa fa-plus"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>

														<BotaoPermissao
															title="Permite editar"
															colorCondition={item.acesso_usuario_editar}
															newValue={{
																...item,
																acesso_usuario_editar: !item.acesso_usuario_editar,
															}}
															icon="fa fa-pencil-alt"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>

														<BotaoPermissao
															title="Permite excluir"
															colorCondition={item.acesso_usuario_excluir}
															newValue={{
																...item,
																acesso_usuario_excluir: !item.acesso_usuario_excluir,
															}}
															icon="fa fa-trash"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
													</div>
												</Td>
												<Td alignCenter verticalAlign="top" paddingBlock={10}>
													<div
														style={{
															display: "flex",
															flexDirection: "column",
															alignItems: "center",
														}}
													>
														<BotaoPermissao
															title="Permite visualizar"
															colorCondition={item.acesso_usuario_logado}
															newValue={{
																...item,
																acesso_usuario_logado: !item.acesso_usuario_logado,
															}}
															icon="fa fa-eye"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
													</div>
												</Td>
												<Td alignCenter verticalAlign="top" paddingBlock={10}>
													<div
														style={{
															display: "flex",
															width: "73px",
															padding: 0,
															flexWrap: "wrap",
														}}
													>
														<BotaoPermissao
															title="Permite visualizar"
															colorCondition={item.acesso_cadastro_cliente_visualizar}
															newValue={{
																...item,
																acesso_cadastro_cliente_visualizar: !item.acesso_cadastro_cliente_visualizar,
															}}
															icon="fa fa-eye"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>

														<BotaoPermissao
															title="Permite adicionar"
															colorCondition={item.acesso_cadastro_cliente_adicionar}
															newValue={{
																...item,
																acesso_cadastro_cliente_adicionar: !item.acesso_cadastro_cliente_adicionar,
															}}
															icon="fa fa-plus"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>

														<BotaoPermissao
															title="Permite editar"
															colorCondition={item.acesso_cadastro_cliente_editar}
															newValue={{
																...item,
																acesso_cadastro_cliente_editar: !item.acesso_cadastro_cliente_editar,
															}}
															icon="fa fa-pencil-alt"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>

														<BotaoPermissao
															title="Permite excluir"
															colorCondition={item.acesso_cadastro_cliente_excluir}
															newValue={{
																...item,
																acesso_cadastro_cliente_excluir: !item.acesso_cadastro_cliente_excluir,
															}}
															icon="fa fa-trash"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
													</div>
												</Td>
												<Td alignCenter verticalAlign="top" paddingBlock={10}>
													<div
														style={{
															display: "flex",
															width: "73px",
															padding: 0,
															flexWrap: "wrap",
														}}
													>
														<BotaoPermissao
															title="Permite visualizar"
															colorCondition={item.acesso_empenho_visualizar}
															newValue={{
																...item,
																acesso_empenho_visualizar: !item.acesso_empenho_visualizar,
															}}
															icon="fa fa-eye"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>

														<BotaoPermissao
															title="Permite adicionar"
															colorCondition={item.acesso_empenho_adicionar}
															newValue={{
																...item,
																acesso_empenho_adicionar: !item.acesso_empenho_adicionar,
															}}
															icon="fa fa-plus"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>

														<BotaoPermissao
															title="Permite editar"
															colorCondition={item.acesso_empenho_editar}
															newValue={{
																...item,
																acesso_empenho_editar: !item.acesso_empenho_editar,
															}}
															icon="fa fa-pencil-alt"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>

														<BotaoPermissao
															title="Permite excluir"
															colorCondition={item.acesso_empenho_excluir}
															newValue={{
																...item,
																acesso_empenho_excluir: !item.acesso_empenho_excluir,
															}}
															icon="fa fa-trash"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
													</div>
												</Td>
												<Td alignCenter verticalAlign="top" paddingBlock={10}>
													<div
														style={{
															display: "flex",
															width: "73px",
															padding: 0,
															flexWrap: "wrap",
														}}
													>
														<BotaoPermissao
															title="Permite visualizar"
															colorCondition={item.acesso_gestao_processo_visualizar}
															newValue={{
																...item,
																acesso_gestao_processo_visualizar: !item.acesso_gestao_processo_visualizar,
															}}
															icon="fa fa-eye"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>

														<BotaoPermissao
															title="Permite adicionar"
															colorCondition={item.acesso_gestao_processo_adicionar}
															newValue={{
																...item,
																acesso_gestao_processo_adicionar: !item.acesso_gestao_processo_adicionar,
															}}
															icon="fa fa-plus"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>

														<BotaoPermissao
															title="Permite editar"
															colorCondition={item.acesso_gestao_processo_editar}
															newValue={{
																...item,
																acesso_gestao_processo_editar: !item.acesso_gestao_processo_editar,
															}}
															icon="fa fa-pencil-alt"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>

														<BotaoPermissao
															title="Permite excluir"
															colorCondition={item.acesso_gestao_processo_excluir}
															newValue={{
																...item,
																acesso_gestao_processo_excluir: !item.acesso_gestao_processo_excluir,
															}}
															icon="fa fa-trash"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
													</div>
												</Td>
												<Td alignCenter verticalAlign="top" paddingBlock={10}>
													<div
														style={{
															display: "flex",
															flexDirection: "column",
															alignItems: "center",
														}}
													>
														<BotaoPermissao
															title="Permite adicionar"
															colorCondition={item.acesso_gestao_processo_andamento_adicionar}
															newValue={{
																...item,
																acesso_gestao_processo_andamento_adicionar: !item.acesso_gestao_processo_andamento_adicionar,
															}}
															icon="fa fa-plus"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>

														<BotaoPermissao
															title="Permite editar"
															colorCondition={item.acesso_gestao_processo_andamento_editar}
															newValue={{
																...item,
																acesso_gestao_processo_andamento_editar: !item.acesso_gestao_processo_andamento_editar,
															}}
															icon="fa fa-pencil-alt"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
													</div>
												</Td>
												<Td alignCenter verticalAlign="top" paddingBlock={10}>
													<div
														style={{
															display: "flex",
															width: "73px",
															padding: 0,
															flexWrap: "wrap",
														}}
													>
														<BotaoPermissao
															title="Permite visualizar"
															colorCondition={item.acesso_cadastro_demanda_visualizar}
															newValue={{
																...item,
																acesso_cadastro_demanda_visualizar: !item.acesso_cadastro_demanda_visualizar,
															}}
															icon="fa fa-eye"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
														<BotaoPermissao
															title="Permite adicionar"
															colorCondition={item.acesso_cadastro_demanda_adicionar}
															newValue={{
																...item,
																acesso_cadastro_demanda_adicionar: !item.acesso_cadastro_demanda_adicionar,
															}}
															icon="fa fa-plus"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
														<BotaoPermissao
															title="Permite editar"
															colorCondition={item.acesso_cadastro_demanda_editar}
															newValue={{
																...item,
																acesso_cadastro_demanda_editar: !item.acesso_cadastro_demanda_editar,
															}}
															icon="fa fa-pencil-alt"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
														<BotaoPermissao
															title="Permite excluir"
															colorCondition={item.acesso_cadastro_demanda_excluir}
															newValue={{
																...item,
																acesso_cadastro_demanda_excluir: !item.acesso_cadastro_demanda_excluir,
															}}
															icon="fa fa-trash"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
													</div>
												</Td>
												<Td alignCenter verticalAlign="top" paddingBlock={10}>
													<div
														style={{
															display: "flex",
															width: "73px",
															padding: 0,
															flexWrap: "wrap",
														}}
													>
														<BotaoPermissao
															title="Permite visualizar"
															colorCondition={item.acesso_periodo_teste_demanda_visualizar}
															newValue={{
																...item,
																acesso_periodo_teste_demanda_visualizar: !item.acesso_periodo_teste_demanda_visualizar,
															}}
															icon="fa fa-eye"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
														<BotaoPermissao
															title="Permite adicionar"
															colorCondition={item.acesso_periodo_teste_demanda_adicionar}
															newValue={{
																...item,
																acesso_periodo_teste_demanda_adicionar: !item.acesso_periodo_teste_demanda_adicionar,
															}}
															icon="fa fa-plus"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
														<BotaoPermissao
															title="Permite editar"
															colorCondition={item.acesso_periodo_teste_demanda_editar}
															newValue={{
																...item,
																acesso_periodo_teste_demanda_editar: !item.acesso_periodo_teste_demanda_editar,
															}}
															icon="fa fa-pencil-alt"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
														<BotaoPermissao
															title="Permite excluir"
															colorCondition={item.acesso_periodo_teste_demanda_excluir}
															newValue={{
																...item,
																acesso_periodo_teste_demanda_excluir: !item.acesso_periodo_teste_demanda_excluir,
															}}
															icon="fa fa-trash"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
													</div>
												</Td>
												<Td alignCenter verticalAlign="top" paddingBlock={10}>
													<div
														style={{
															display: "flex",
															width: "73px",
															padding: 0,
															flexWrap: "wrap",
														}}
													>
														<BotaoPermissao
															title="Permite visualizar"
															colorCondition={item.acesso_cadastro_geracao_visualizar}
															newValue={{
																...item,
																acesso_cadastro_geracao_visualizar: !item.acesso_cadastro_geracao_visualizar,
															}}
															icon="fa fa-eye"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
														<BotaoPermissao
															title="Permite adicionar"
															colorCondition={item.acesso_cadastro_geracao_adicionar}
															newValue={{
																...item,
																acesso_cadastro_geracao_adicionar: !item.acesso_cadastro_geracao_adicionar,
															}}
															icon="fa fa-plus"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>

														<BotaoPermissao
															title="Permite editar"
															colorCondition={item.acesso_cadastro_geracao_editar}
															newValue={{
																...item,
																acesso_cadastro_geracao_editar: !item.acesso_cadastro_geracao_editar,
															}}
															icon="fa fa-pencil-alt"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
														<BotaoPermissao
															title="Permite excluir"
															colorCondition={item.acesso_cadastro_geracao_excluir}
															newValue={{
																...item,
																acesso_cadastro_geracao_excluir: !item.acesso_cadastro_geracao_excluir,
															}}
															icon="fa fa-trash"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
													</div>
												</Td>
												<Td alignCenter verticalAlign="top" paddingBlock={10}>
													<div
														style={{
															display: "flex",
															width: "73px",
															padding: 0,
															flexWrap: "wrap",
														}}
													>
														<BotaoPermissao
															title="Permite visualizar"
															colorCondition={item.acesso_tributacao_visualizar}
															newValue={{
																...item,
																acesso_tributacao_visualizar: !item.acesso_tributacao_visualizar,
															}}
															icon="fa fa-eye"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
														<BotaoPermissao
															title="Permite adicionar"
															colorCondition={item.acesso_tributacao_adicionar}
															newValue={{
																...item,
																acesso_tributacao_adicionar: !item.acesso_tributacao_adicionar,
															}}
															icon="fa fa-plus"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>

														<BotaoPermissao
															title="Permite editar"
															colorCondition={item.acesso_tributacao_editar}
															newValue={{
																...item,
																acesso_tributacao_editar: !item.acesso_tributacao_editar,
															}}
															icon="fa fa-pencil-alt"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
														<BotaoPermissao
															title="Permite excluir"
															colorCondition={item.acesso_tributacao_excluir}
															newValue={{
																...item,
																acesso_tributacao_excluir: !item.acesso_tributacao_excluir,
															}}
															icon="fa fa-trash"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
													</div>
												</Td>
												<Td alignCenter verticalAlign="top" paddingBlock={10}>
													<div
														style={{
															display: "flex",
															width: "73px",
															padding: 0,
															flexWrap: "wrap",
														}}
													>
														<BotaoPermissao
															title="Permite visualizar"
															colorCondition={item.acesso_fatura_visualizar}
															newValue={{
																...item,
																acesso_fatura_visualizar: !item.acesso_fatura_visualizar,
															}}
															icon="fa fa-eye"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
														<BotaoPermissao
															title="Permite adicionar, importar, editar e excluir (apenas faturas abertas)"
															colorCondition={item.acesso_fatura_editar}
															newValue={{
																...item,
																acesso_fatura_editar: !item.acesso_fatura_editar,
															}}
															icon="fa fa-pencil-alt"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>

														<BotaoPermissao
															title="Permite editar faturas fechadas"
															colorCondition={item.acesso_fatura_editar_fechado}
															newValue={{
																...item,
																acesso_fatura_editar_fechado: !item.acesso_fatura_editar_fechado,
															}}
															icon="fa fa-edit"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
														<BotaoPermissao
															title="Permite adicionar e remover data de pagamento das faturas"
															colorCondition={item.acesso_fatura_data_pagamento_editar}
															newValue={{
																...item,
																acesso_fatura_data_pagamento_editar: !item.acesso_fatura_data_pagamento_editar,
															}}
															icon="fas fa-dollar-sign"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
													</div>
												</Td>
												<Td alignCenter verticalAlign="top" paddingBlock={10}>
													<div
														style={{
															display: "flex",
															flexDirection: "column",
															alignItems: "center",
														}}
													>
														<BotaoPermissao
															title="Permite visualizar"
															colorCondition={item.acesso_multa_visualizar}
															newValue={{
																...item,
																acesso_multa_visualizar: !item.acesso_multa_visualizar,
															}}
															icon="fa fa-eye"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
														<BotaoPermissao
															title="Permite editar (apenas faturas abertas)"
															colorCondition={item.acesso_multa_editar}
															newValue={{
																...item,
																acesso_multa_editar: !item.acesso_multa_editar,
															}}
															icon="fa fa-pencil-alt"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
														<BotaoPermissao
															title="Permite editar faturas fechadas"
															colorCondition={item.acesso_multa_editar_fechado}
															newValue={{
																...item,
																acesso_multa_editar_fechado: !item.acesso_multa_editar_fechado,
															}}
															icon="fa fa-edit"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
													</div>
												</Td>
												<Td alignCenter verticalAlign="top" paddingBlock={10}>
													<div
														style={{
															display: "flex",
															flexDirection: "column",
															alignItems: "center",
														}}
													>
														<BotaoPermissao
															title="Permite visualizar"
															colorCondition={item.acesso_icms_energia_visualizar}
															newValue={{
																...item,
																acesso_icms_energia_visualizar: !item.acesso_icms_energia_visualizar,
															}}
															icon="fa fa-eye"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
													</div>
												</Td>
												<Td alignCenter verticalAlign="top" paddingBlock={10}>
													<div
														style={{
															display: "flex",
															flexDirection: "column",
															alignItems: "center",
														}}
													>
														<BotaoPermissao
															title="Permite visualizar"
															colorCondition={item.acesso_consumo_agua_visualizar}
															newValue={{
																...item,
																acesso_consumo_agua_visualizar: !item.acesso_consumo_agua_visualizar,
															}}
															icon="fa fa-eye"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
													</div>
												</Td>
												<Td alignCenter verticalAlign="top" paddingBlock={10}>
													<div
														style={{
															display: "flex",
															flexDirection: "column",
															alignItems: "center",
														}}
													>
														<BotaoPermissao
															title="Permite visualizar"
															colorCondition={item.acesso_analise}
															newValue={{
																...item,
																acesso_analise: !item.acesso_analise,
															}}
															icon="fa fa-eye"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
													</div>
												</Td>
												<Td alignCenter verticalAlign="top" paddingBlock={10}>
													<div
														style={{
															display: "flex",
															flexDirection: "column",
															alignItems: "center",
														}}
													>
														<BotaoPermissao
															title="Permite visualizar"
															colorCondition={item.acesso_relatorio}
															newValue={{
																...item,
																acesso_relatorio: !item.acesso_relatorio,
															}}
															icon="fa fa-eye"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
														<BotaoPermissao
															title="Permite enviar e-mail"
															colorCondition={item.acesso_relatorio_email}
															newValue={{
																...item,
																acesso_relatorio_email: !item.acesso_relatorio_email,
															}}
															icon="fa fa-envelope"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
													</div>
												</Td>
												<Td alignCenter verticalAlign="top" paddingBlock={10}>
													<div
														style={{
															display: "flex",
															flexDirection: "column",
															alignItems: "center",
														}}
													>
														<BotaoPermissao
															title="Permite visualizar"
															colorCondition={item.acesso_documento_visualizar}
															newValue={{
																...item,
																acesso_documento_visualizar: !item.acesso_documento_visualizar,
															}}
															icon="fa fa-eye"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
														<BotaoPermissao
															title="Permite editar"
															colorCondition={item.acesso_documento_editar}
															newValue={{
																...item,
																acesso_documento_editar: !item.acesso_documento_editar,
															}}
															icon="fa fa-edit"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
													</div>
												</Td>
												<Td alignCenter verticalAlign="top" paddingBlock={10}>
													<div
														style={{
															display: "flex",
															flexDirection: "column",
															alignItems: "center",
														}}
													>
														<BotaoPermissao
															title="Permite visualizar"
															colorCondition={item.acesso_documento_adm_visualizar}
															newValue={{
																...item,
																acesso_documento_adm_visualizar: !item.acesso_documento_adm_visualizar,
															}}
															icon="fa fa-eye"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
														<BotaoPermissao
															title="Permite editar"
															colorCondition={item.acesso_documento_adm_editar}
															newValue={{
																...item,
																acesso_documento_adm_editar: !item.acesso_documento_adm_editar,
															}}
															icon="fa fa-edit"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
													</div>
												</Td>
												<Td alignCenter verticalAlign="top" paddingBlock={10}>
													<div
														style={{
															display: "flex",
															flexDirection: "column",
															alignItems: "center",
														}}
													>
														<BotaoPermissao
															title="Permite visualizar"
															colorCondition={item.acesso_redes_visualizar}
															newValue={{
																...item,
																acesso_redes_visualizar: !item.acesso_redes_visualizar,
															}}
															icon="fa fa-eye"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>

														<BotaoPermissao
															title="Permite editar"
															colorCondition={item.acesso_redes_editar}
															newValue={{
																...item,
																acesso_redes_editar: !item.acesso_redes_editar,
															}}
															icon="fa fa-edit"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
													</div>
												</Td>
												<Td alignCenter verticalAlign="top" paddingBlock={10}>
													<div
														style={{
															display: "flex",
															flexDirection: "column",
															alignItems: "center",
														}}
													>
														<BotaoPermissao
															title="Permite visualizar"
															colorCondition={item.acesso_solar_visualizar}
															newValue={{
																...item,
																acesso_solar_visualizar: !item.acesso_solar_visualizar,
															}}
															icon="fa fa-eye"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
														<BotaoPermissao
															title="Permite editar"
															colorCondition={item.acesso_solar_editar}
															newValue={{
																...item,
																acesso_solar_editar: !item.acesso_solar_editar,
															}}
															icon="fa fa-edit"
															usuarioLogado={this.props.usuarioLogado}
															setPermissao={this.props.setPermissao}
														/>
													</div>
												</Td>
											</>
										}
										botoes={[
											{
												type: "warning",
												icon: "fas fa-pencil-alt",
												disabled: !this.props.usuarioLogado.acesso_usuario_editar,
												event: () => {
													this.props.setModoTela("cadastro", {
														...item,
														senha: null,
													});
													this.props.initForm({
														...item,
														senha: null,
													});
												},
											},
											{
												type: "danger",
												icon: "fas fa-trash-alt",
												disabled: !this.props.usuarioLogado.acesso_usuario_excluir,
												event: () => {
													this.props.setModoTela("exclusao", {
														...item,
														senha: null,
													});
													this.props.initForm({
														...item,
														senha: null,
													});
												},
											},
										]}
									/>
								))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}
}

const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	modoTela: state.usuario.modoTela,
	lista: state.usuario.lista,
	listaCliente: state.usuario.listaCliente,
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setModoTela,
			initForm,
			salvar,
			excluir,
			getLista,
			setPermissao,
			getListaCliente,
			buscarSessao,
		},
		dispatch
	);
export default connect(mapStateToProps, mapDispatchToProps)(Usuario);

//componente interno
function BotaoPermissao(props) {
	return (
		<button
			title={props.title}
			style={{
				width: 30,
				height: 30,
				marginBottom: "2px",
				padding: props.icon === "fa fa-eye" ? 4 : 0,
				...props.style,
			}}
			disabled={!props.usuarioLogado.administrador}
			type="button"
			className={`btn btn-${!!props.colorCondition ? "success" : "default"} btn-sm ${!props.usuarioLogado.administrador ? "disabled" : ""}`}
			onClick={() => {
				props.setPermissao({
					...props.newValue,
				});
			}}
		>
			{props.icon ? <i className={`${props.icon}`}></i> : null}
			{props.text}
		</button>
	);
}
