import axios from "axios";
import consts from "../../consts";
import { setErroAPI, setSuccess } from "../../common/toast/toast";
import { initialize } from "redux-form";
import confirmDialog from "../../common/dialog/confirmDialog";
import { getLista as getListaFatura, getFatura } from "../faturaActions";

export function setModoTela(modo, registro = {}) {
	return {
		type: "FATURA_ITEM_MODO_TELA",
		payload: {
			modo: modo,
			registro: registro,
		},
	};
}
export function initForm(registro = {}) {
	return [initialize("faturaItemForm", registro)];
}

export function setAguardando(aguardando) {
	return {
		type: "FATURA_SET_AGUARDANDO",
		payload: aguardando,
	};
}

export function getLista(idFatura) {
	return (dispatch, getState) => {
		dispatch(getFatura(idFatura)); //atualiza o formulário de faturas carregado
		const request = axios.get(`${consts.API_URL}/faturaItem?id_fatura=${idFatura}`);
		dispatch({
			type: "FATURA_ITEM_LISTADO",
			payload: request,
		});
	};
}

export function salvar(registro) {
	return (dispatch, getState) => {
		if (!registro.id) {
			axios
				.post(`${consts.API_URL}/faturaItem`, {
					...registro,
					quantidade_registrada: String(registro.quantidade_registrada || 0)
						.split(",")
						.join("."),
					quantidade_faturada: String(registro.quantidade_faturada || 0)
						.split(",")
						.join("."),
					tarifa: String(registro.tarifa || 0)
						.split(",")
						.join("."),
					valor_imposto: String(registro.valor_imposto || 0)
						.split(",")
						.join("."),
					valor_total: String(registro.valor_total || 0)
						.split(",")
						.join("."),
				})
				.then((resp) => {
					setSuccess("Operação Realizada com sucesso.");
					dispatch(getLista(registro.id_fatura));
					dispatch(getListaFatura());
					dispatch(setModoTela("lista"));
				})
				.catch((e) => {
					setErroAPI(e);
				});
		} else {
			confirmDialog("Deseja realmente alterar?", () => {
				axios
					.put(`${consts.API_URL}/faturaItem`, {
						...registro,
						quantidade_registrada: String(registro.quantidade_registrada || 0)
							.split(",")
							.join("."),
						quantidade_faturada: String(registro.quantidade_faturada || 0)
							.split(",")
							.join("."),
						tarifa: String(registro.tarifa || 0)
							.split(",")
							.join("."),
						valor_imposto: String(registro.valor_imposto || 0)
							.split(",")
							.join("."),
						valor_total: String(registro.valor_total || 0)
							.split(",")
							.join("."),
					})
					.then((resp) => {
						setSuccess("Operação Realizada com sucesso.");
						dispatch(getLista(registro.id_fatura));
						dispatch(getListaFatura());
						dispatch(setModoTela("lista"));
					})
					.catch((e) => {
						setErroAPI(e);
					});
			});
		}
	};
}

export function excluir(registro) {
	return (dispatch) => {
		confirmDialog("Deseja realmente excluir?", () => {
			axios
				.delete(`${consts.API_URL}/faturaItem?id=${registro.id}`)
				.then((resp) => {
					setSuccess("Operação Realizada com sucesso.");
					dispatch(getLista(registro.id_fatura));
					dispatch(getListaFatura());
					dispatch(setModoTela("lista"));
				})
				.catch((e) => {
					setErroAPI(e);
				});
		});
	};
}

export function limpar(lista) {
	return async (dispatch) => {
		confirmDialog("Deseja realmente limpar?", async () => {
			let id_fatura = null;

			dispatch(setAguardando(true));

			for (var i = 0; i < lista.length; i++) {
				let registro = lista[i];
				id_fatura = registro.id_fatura;

				await axios
					.delete(`${consts.API_URL}/faturaItem?id=${registro.id}`)
					.then((resp) => {})
					.catch((e) => {
						setErroAPI(e);
					});
			}
			setSuccess("Operação Realizada com sucesso.");
			if (id_fatura) {
				dispatch(getLista(id_fatura));
				dispatch(getListaFatura());
			}
			dispatch(setModoTela("lista"));
			dispatch(setAguardando(false));
		});
	};
}

//Produto

export function getListaProduto() {
	const request = axios.get(`${consts.API_URL}/produto/listarSelect`);
	return {
		type: "FATURA_ITEM_PRODUTO_SELECT_LISTADO",
		payload: request,
	};
}

export function salvarProduto(registro, formulario) {
	return (dispatch, getState) => {
		if (!registro.id) {
			axios
				.post(`${consts.API_URL}/produto`, {
					...registro,
				})
				.then((resp) => {
					setSuccess("Operação Realizada com sucesso.");
					dispatch(getListaProduto());
					if (!registro.id) {
						dispatch(
							initForm({
								...formulario,
								id_produto: resp.data.id,
							})
						);
					}
				})
				.catch((e) => {
					setErroAPI(e);
				});
		} else {
			axios
				.put(`${consts.API_URL}/produto`, {
					...registro,
				})
				.then((resp) => {
					setSuccess("Operação Realizada com sucesso.");
					dispatch(getListaProduto());
				})
				.catch((e) => {
					setErroAPI(e);
				});
		}
	};
}

export function redefinirTipo(registro) {
    return async (dispatch, getState) => {
        try {
            await axios.put(`${consts.API_URL}/produto/redefinirTipo`, { ...registro });
            await dispatch(getLista(registro.id_fatura));
            setSuccess("Operação Realizada com sucesso.");
        } catch (e) {
            setErroAPI(e);
        }
    };
}

//Unidade de Medida

export function getListaUnidadeMedida() {
	const request = axios.get(`${consts.API_URL}/unidadeMedida/listarSelect`);
	return {
		type: "FATURA_ITEM_UNIDADE_MEDIDA_SELECT_LISTADO",
		payload: request,
	};
}

export function salvarUnidadeMedida(registro, formulario) {
	return (dispatch, getState) => {
		if (!registro.id) {
			axios
				.post(`${consts.API_URL}/unidadeMedida`, {
					...registro,
				})
				.then((resp) => {
					setSuccess("Operação Realizada com sucesso.");
					dispatch(getListaUnidadeMedida());
					if (!registro.id) {
						dispatch(
							initForm({
								...formulario,
								id_unidade_medida: resp.data.id,
							})
						);
					}
				})
				.catch((e) => {
					setErroAPI(e);
				});
		} else {
			axios
				.put(`${consts.API_URL}/unidadeMedida`, {
					...registro,
				})
				.then((resp) => {
					setSuccess("Operação Realizada com sucesso.");
					dispatch(getListaUnidadeMedida());
				})
				.catch((e) => {
					setErroAPI(e);
				});
		}
	};
}

export function reprocessar(id) {
	return (dispatch, getState) => {
		confirmDialog("Deseja realmente reprocessar?", () => {
			dispatch(setAguardando(true));

			axios
				.get(`${consts.API_URL}/fatura/buscaPdf?id=${id}`)
				.then((resp) => {
					if (resp.data && resp.data.arquivo_pdf) {
						axios
							.post(`${consts.API_URL}/faturaItem/importar`, {
								id_fatura: id,
								base64: resp.data.arquivo_pdf,
							})
							.then((resp) => {
								setSuccess("Operação Realizada com sucesso.");
								dispatch(getLista(id));
								dispatch(getListaFatura());
								dispatch(setModoTela("lista"));
								dispatch(setAguardando(false));
							})
							.catch((e) => {
								//console.log(e);
								try {
									setErroAPI(e);
								} catch (ex) {
									//console.log(ex);
								}
								dispatch(setAguardando(false));
							});
					} else {
						dispatch(setAguardando(false));
					}
				})
				.catch((ex) => {
					dispatch(setAguardando(false));
				});
		});
	};
}

export function reprocessarTodas(lista) {
    return async (dispatch, getState) => {
        try {
            if (!lista.length) return {};
            let listaFiltrata = [...lista];
            const existeFaturaImportacaoValidada = lista.find((fatura) => fatura.importacao_validada);
            if (existeFaturaImportacaoValidada && !window.confirm(`Deseja reprocessar também as faturas com importação validada?`)) {
                listaFiltrata = lista.filter((fatura) => !fatura.importacao_validada); //separa somente as com erro de importação
            }
            if (!window.confirm(`Deseja realmente reprocessar as ${listaFiltrata.length} fatura${listaFiltrata.length == 1 ? "" : "s"}?`)) return {};

            dispatch(setAguardando(true));
            for (let i = 0; i < listaFiltrata.length; i++) {
                const item = listaFiltrata[i];

                const resp = await axios.get(`${consts.API_URL}/fatura/buscaPdf?id=${item.id}`);
                if (resp.data && resp.data.arquivo_pdf) {
                    await axios.post(`${consts.API_URL}/faturaItem/importar`, {
                        id_fatura: item.id,
                        base64: resp.data.arquivo_pdf,
                    });
                }
            }
            setSuccess("Operação Finalizada.");
        } catch (erro) {
            setErroAPI(erro);
        } finally {
            dispatch(getListaFatura());
            dispatch(setAguardando(false));
        }
    };
}

//Importação
export function importar(id_fatura, base64) {
    return (dispatch, getState) => {
        dispatch(setAguardando(true));

        axios
            .post(`${consts.API_URL}/faturaItem/importar`, {
                id_fatura: id_fatura,
                base64: base64,
            })
            .then((resp) => {
                setSuccess("Operação Realizada com sucesso.");
                dispatch(getLista(id_fatura));
                dispatch(getListaFatura());
                dispatch(setModoTela("lista"));
                dispatch(setAguardando(false));
            })
            .catch((e) => {
                //console.log(e);
                try {
                    setErroAPI(e);
                } catch (ex) {
                    //console.log(ex);
                }
                dispatch(setAguardando(false));
            });
    };
}

export function executarManutencao(lista, id_manutencao_produto_tipo) {
    return async (dispatch, getState) => {
        try {
            if (!lista.length) return {};
            let listaFiltrata = [...lista];
            const existeFaturaImportacaoValidada = lista.find((fatura) => fatura.importacao_validada);
            if (existeFaturaImportacaoValidada && !window.confirm(`Deseja executar também a manutenção nas faturas com importação validada?`)) {
                listaFiltrata = lista.filter((fatura) => !fatura.importacao_validada); //separa somente as com erro de importação
            }
            if (!window.confirm(`Deseja realmente executar a manutenção nas ${listaFiltrata.length} fatura${listaFiltrata.length == 1 ? "" : "s"}?`)) return {};

            dispatch(setAguardando(true));

            for (let i = 0; i < listaFiltrata.length; i++) {
                const item = listaFiltrata[i];

                const resp = await axios.get(`${consts.API_URL}/fatura/buscaPdf?id=${item.id}`);
                if (resp.data && resp.data.arquivo_pdf) {
                    await axios.post(`${consts.API_URL}/faturaItem/importar`, {
                        id_fatura: item.id,
                        base64: resp.data.arquivo_pdf,
                        manutencao: !!id_manutencao_produto_tipo,
                        manutencao_tipo_produto: id_manutencao_produto_tipo,
                    });
                }
            }
            setSuccess("Operação Finalizada.");
        } catch (erro) {
            setErroAPI(erro);
        } finally {
            dispatch(getListaFatura());
            dispatch(setAguardando(false));
        }
    };
}
