import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./geracaoLeitura.css";
import InfoBox from "../common/template/infoBox";
import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import Tr from "../common/table/tr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import Select from "../common/form/select";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import imagemSoLogo from "../assets/images/logo_aguardando.gif";
import noEnergy from "../assets/images/no-energy.png";
import solarField from "../assets/images/solar-field.svg";
import solarPanelCharge from "../assets/images/solar-panel-charge-thin.svg";
import solarPanelSun from "../assets/images/solar-panel-sun-thin.svg";
import calendarCheck from "../assets/images/calendar-check.svg";
import charging90 from "../assets/images/battery-level-90-charging.svg";
import charging50 from "../assets/images/battery-level-50-charging.svg";
import charging20 from "../assets/images/battery-level-20-charging.svg";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import locale from "@amcharts/amcharts4/lang/pt_BR.js";
import DateFormat from "../common/dateFormat/DateFormat";
import LabelAndCheckbox from "../common/form/labelAndCheckbox";
import DivBox from "../common/divBox/divBox";
import somNotificacaoArquivo from "../assets/sounds/notificacao.wav";
import { weatherIcons } from "../assets/weatherIcons.js";
import { ProgressBar } from "../common/progressBar/progressBar.jsx";
import {
    setModoTela,
    setFiltro,
    setDataGrafico,
    getLista,
    getListaCliente,
    getListaUnidadeConsumidora,
    getListaGraficoTodasIntegracoesAgrupadasPorHora,
    getListaGraficoEnergiaDia,
    getListaGraficoEnergiaDiasMes,
    getListaGraficoEnergiaMesesAno,
    setStatusVisualizado,
    setAguardando,
} from "./geracaoLeituraActions";

import { setEmitirAlertaSonoro } from "../usuario/usuarioActions";

import { setError } from "../common/toast/toast";
import { Loading } from "../common/loading/loading";

class GeracaoLeitura extends Component {
    state = {
        dispositivos: null,
        //coisas do grafico
        mobile: window.innerWidth < 500,
        graficoEnergiaDia: null,
        graficoEnergiaDiasMes: null,
        graficoEnergiaMesesAno: null,
        ultima_atualizacao_lista: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()),
        apenasIndisponiveis: false,
        idIntervaloNotificacao: null,
        idIntervaloBusca: null,
    };

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
        clearInterval(this.state.idIntervaloNotificacao);
        clearInterval(this.state.idIntervaloBusca);
    }
    componentWillMount() {
        this.props.getListaCliente();
        this.props.getListaUnidadeConsumidora();
        this.atualizarDadosCada5Min();
        this.emitirAlertaSonoro();
    }
    componentDidUpdate(prevProps, prevState) {
        window.scrollTo(0, 0);

        if (
            this.props.modoTela === "graficoEnergiaDia" &&
            (prevState.graficoEnergiaDia !== this.state.graficoEnergiaDia ||
                JSON.stringify(prevProps.listaGraficoEnergiaDia) !== JSON.stringify(this.props.listaGraficoEnergiaDia))
        ) {
            this.exibirGrafico("graficoEnergiaDia");
        }
        if (
            this.props.modoTela === "graficoEnergiaDiasMes" &&
            (prevState.graficoEnergiaDiasMes !== this.state.graficoEnergiaDiasMes ||
                JSON.stringify(prevProps.listaGraficoEnergiaDiasMes) !==
                    JSON.stringify(this.props.listaGraficoEnergiaDiasMes))
        ) {
            this.exibirGrafico("graficoEnergiaDiasMes");
        }
        if (
            this.props.modoTela === "graficoEnergiaMesesAno" &&
            (prevState.graficoEnergiaMesesAno !== this.state.graficoEnergiaMesesAno ||
                JSON.stringify(prevProps.listaGraficoEnergiaMesesAno) !==
                    JSON.stringify(this.props.listaGraficoEnergiaMesesAno))
        ) {
            this.exibirGrafico("graficoEnergiaMesesAno");
        }

        if (
            prevProps.modoTela !== this.props.modoTela ||
            JSON.stringify(prevProps.listaGraficoTodasIntegracoesAgrupadasPorHora) !==
                JSON.stringify(this.props.listaGraficoTodasIntegracoesAgrupadasPorHora) ||
            !this.chart ||
            prevState.ultima_atualizacao_lista !== this.state.ultima_atualizacao_lista
        ) {
            this.montarGraficoLineTodasIntegracoesAgrupadasPorHora(
                this.props.listaGraficoTodasIntegracoesAgrupadasPorHora,
            );
        }

        if (
            JSON.stringify(prevProps.lista) !== JSON.stringify(this.props.lista) ||
            prevProps.usuarioLogado.emitir_alerta_sonoro !== this.props.usuarioLogado.emitir_alerta_sonoro
        ) {
            this.emitirAlertaSonoro();
        }
    }

    render() {
        // Sem acesso
        if (
            !this.props.usuarioLogado ||
            (!this.props.usuarioLogado.acesso_solar_visualizar && !this.props.usuarioLogado.acesso_solar_editar)
        ) {
            return <div />;
        }
        return (
            <div>
                {this.props.aguardando ? <Loading /> : null}

                <Content>
                    {this.props.modoTela === "lista" ? this.lista() : null}
                    {this.props.modoTela === "dispositivos" ? this.listaDispositivos(this.props.registro) : null}
                    {this.props.modoTela.includes("grafico") ? this.exibirGrafico(this.props.modoTela) : null}
                </Content>
            </div>
        );
    }

    lista() {
        const telaDetalhesAtiva = !!this.props.filtro.id_agrupador;
        // VALORES DOS CARDS DO CABEÇALHO --------------------------------------
        const totalUsinas = this.props.lista.filter(
            (item) => !this.props.usuarioLogado.id_cliente || item.id_cliente == this.props.usuarioLogado.id_cliente,
        ).length;
        const potenciaTotalInstalada =
            Number(
                this.props.lista.reduce((acc, item) => {
                    if (
                        !this.props.usuarioLogado.id_cliente ||
                        item.id_cliente == this.props.usuarioLogado.id_cliente
                    ) {
                        const modulosPotenciaTotal =
                            parseFloat(item.modulos_potencia_total) >= 0 ? parseFloat(item.modulos_potencia_total) : 0;
                        const inversorPotenciaTotal =
                            parseFloat(item.inversor_potencia_total) >= 0
                                ? parseFloat(item.inversor_potencia_total)
                                : 0;
                        //considera o menor valor entre os 2 valores cadastrados
                        acc += Math.min(modulosPotenciaTotal, inversorPotenciaTotal);
                    }
                    return acc;
                }, 0) / 1000,
            )
                .toFixed(2)
                .replace(".", ",") + " MW";

        const energiaTotalDia =
            Number(
                this.props.lista.reduce((acc, item) => {
                    if (
                        !this.props.usuarioLogado.id_cliente ||
                        item.id_cliente == this.props.usuarioLogado.id_cliente
                    ) {
                        acc += parseFloat(item.energia_dia || 0);
                    }
                    return acc;
                }, 0) / 1000,
            )
                .toFixed(2)
                .replace(".", ",") + " MWh";
        const energiaTotalMes =
            Number(
                this.props.lista.reduce((acc, item) => {
                    if (
                        !this.props.usuarioLogado.id_cliente ||
                        item.id_cliente == this.props.usuarioLogado.id_cliente
                    ) {
                        acc += parseFloat(item.energia_mes || 0);
                    }
                    return acc;
                }, 0) / 1000,
            )
                .toFixed(2)
                .replace(".", ",") + " MWh";
        const totalUsinasOperandoNormalmente = this.props.lista.filter((item) => {
            //se NÃO estiver na tela de detalhes considera também a hora pra validar o status
            return telaDetalhesAtiva
                ? item.status &&
                      item.potencia_instantanea > 0 &&
                      (!this.props.usuarioLogado.id_cliente || item.id_cliente == this.props.usuarioLogado.id_cliente)
                : item.status &&
                      item.potencia_instantanea > 0 &&
                      (!this.props.usuarioLogado.id_cliente ||
                          item.id_cliente == this.props.usuarioLogado.id_cliente) &&
                      new Date().setHours(new Date().getHours() - 3) < new Date(item.data_hora);
        }).length;
        const totalUsinasSemComunicacao = this.props.lista
            .filter(
                (item) =>
                    !this.props.usuarioLogado.id_cliente || item.id_cliente == this.props.usuarioLogado.id_cliente,
            )
            .filter((item) => {
                //se NÃO estiver na tela de detalhes considera também a hora pra validar o status
                return telaDetalhesAtiva
                    ? !(item.status && item.potencia_instantanea > 0)
                    : !(
                          item.status &&
                          item.potencia_instantanea > 0 &&
                          new Date().setHours(new Date().getHours() - 3) < new Date(item.data_hora)
                      );
            }).length;

        const totalUsinasProducaoOtima = this.props.lista.filter(
            (item) =>
                (!this.props.usuarioLogado.id_cliente || item.id_cliente == this.props.usuarioLogado.id_cliente) &&
                item.status &&
                item.potencia_instantanea > 0 &&
                new Date().setHours(new Date().getHours() - 3) < new Date(item.data_hora) &&
                item.percentual_producao >= 85,
        ).length;

        const totalUsinasProducaoModerada = this.props.lista.filter(
            (item) =>
                (!this.props.usuarioLogado.id_cliente || item.id_cliente == this.props.usuarioLogado.id_cliente) &&
                item.status &&
                item.potencia_instantanea > 0 &&
                new Date().setHours(new Date().getHours() - 3) < new Date(item.data_hora) &&
                item.percentual_producao > 50 &&
                item.percentual_producao < 85,
        ).length;
        const totalUsinasProducaoBaixa = this.props.lista.filter(
            (item) =>
                (!this.props.usuarioLogado.id_cliente || item.id_cliente == this.props.usuarioLogado.id_cliente) &&
                item.status &&
                item.potencia_instantanea > 0 &&
                new Date().setHours(new Date().getHours() - 3) < new Date(item.data_hora) &&
                item.percentual_producao > 0 &&
                item.percentual_producao <= 50,
        ).length;
        //----------------------------------------------------------------------

        //CARDS

        const cardsTotalizadores = (
            <>
                <Grid cols="12 6 6 6" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                    <InfoBox text="Nº Total de Usinas" number={totalUsinas}>
                        <img
                            src={solarField}
                            style={{
                                height: 50,
                                width: 50,
                                filter: "brightness(0) invert(1)",
                            }}
                            alt=""
                        />
                    </InfoBox>
                </Grid>
                <Grid cols="12 6 6 6" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                    <InfoBox text="Potência Total Instalada" number={potenciaTotalInstalada}>
                        <img
                            src={solarPanelCharge}
                            style={{
                                height: 35,
                                width: 35,
                                filter: "brightness(0) invert(1)",
                            }}
                            alt=""
                        />
                    </InfoBox>
                </Grid>
                <Grid cols="12 6 6 6" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                    <InfoBox text="Energia Total do Dia" number={energiaTotalDia}>
                        <img
                            src={solarPanelSun}
                            style={{
                                height: 35,
                                width: 35,
                                filter: "brightness(0) invert(1)",
                            }}
                            alt=""
                        />
                    </InfoBox>
                </Grid>
                <Grid cols="12 6 6 6" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                    <InfoBox text="Energia Total do Mês" number={energiaTotalMes}>
                        <img
                            src={calendarCheck}
                            style={{
                                height: 30,
                                width: 30,
                                filter: "brightness(0) invert(1)",
                            }}
                            alt=""
                        />
                    </InfoBox>
                </Grid>
            </>
        );

        const cardsStatusProducao = (
            <>
                <Grid cols="12 6 6 6" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                    <InfoBox
                        icon="fas fa-check"
                        text="Operando Normalmente"
                        number={totalUsinasOperandoNormalmente}
                        twoColors="#3EFF3E"
                    ></InfoBox>
                </Grid>
                <Grid cols="12 6 6 6" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                    <InfoBox
                        icon="fas fa-exclamation-triangle"
                        text="Sem Comunicação ou Desligado"
                        number={totalUsinasSemComunicacao}
                        twoColors="#F56969"
                    ></InfoBox>
                </Grid>
                <Grid cols="12 4 4 4" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                    <InfoBox text="Produção Ótima" number={totalUsinasProducaoOtima} twoColors="#4AE30E">
                        <img
                            src={charging90}
                            style={{
                                height: 30,
                                width: 30,
                                filter: "brightness(0) invert(1)",
                            }}
                            alt=""
                        />
                    </InfoBox>
                </Grid>
                <Grid cols="12 4 4 4" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                    <InfoBox text="Produção Moderada" number={totalUsinasProducaoModerada} twoColors="#FFE53C">
                        <img
                            src={charging50}
                            style={{
                                height: 30,
                                width: 30,
                                filter: "brightness(0) invert(1)",
                            }}
                            alt=""
                        />
                    </InfoBox>
                </Grid>
                <Grid cols="12 4 4 4" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                    <InfoBox text="Produção Baixa" number={totalUsinasProducaoBaixa} twoColors="#FF3838">
                        <img
                            src={charging20}
                            style={{
                                height: 30,
                                width: 30,
                                filter: "brightness(0) invert(1)",
                            }}
                            alt=""
                        />
                    </InfoBox>
                </Grid>
            </>
        );
        //----------------------------------------------------------------------

        return (
            <ContentCard>
                <ContentCardBody>
                    {!this.props.filtro.id_agrupador && (
                        <>
                            <Row
                                style={{
                                    marginBottom: 25,
                                    justifyContent: "space-between",
                                    paddingInline: 10,
                                }}
                            >
                                {/* só mostra o gráfico com a soma da energia produzida por todas instegraços se for admin */}
                                {this.props.usuarioLogado.administrador ? (
                                    <>
                                        <Grid
                                            cols="12 12 12 6"
                                            style={{
                                                backgroundColor: "#1e3e49",
                                                borderRadius: 8,
                                                marginTop: 10,
                                                height: "fit-content",
                                            }}
                                        >
                                            <Row>
                                                <Grid
                                                    cols="12 12 12 12"
                                                    style={{
                                                        display: "flex",
                                                        minWidth: "fit-content",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        paddingTop: 10,
                                                    }}
                                                >
                                                    <button
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            fontSize: "1.2rem",
                                                            fontWeight: 500,
                                                            // boxShadow: this.desabilitarBotaoVoltarData() ? "inset 0 0 16px 1px #ccc" : "0 0 10px 1px #ccc",
                                                            boxShadow: this.desabilitarBotaoVoltarData()
                                                                ? "inset 0 0 16px 1px #1a2f2a"
                                                                : "0 0 10px 1px #1a2f2a",
                                                            // color: "#0070cc",
                                                            color: "#fff",
                                                            width: 40,
                                                            height: 40,
                                                            borderRadius: "100%",
                                                            // backgroundColor: "#fff",
                                                            backgroundColor: "#1e3e49",
                                                            border: "none",
                                                            marginInline: 20,
                                                        }}
                                                        onFocus={(e) => {
                                                            e.target.style.outline = "none";
                                                        }}
                                                        onClick={() => {
                                                            const dataGrafico = this.props.dataGrafico;
                                                            const modoTela = this.props.modoTela;
                                                            const tempo = -1;
                                                            this.voltarAvancarDataGrafico({
                                                                dataGrafico,
                                                                tempo,
                                                                modoTela,
                                                            });
                                                        }}
                                                        disabled={this.desabilitarBotaoVoltarData()}
                                                    >
                                                        {"<"}
                                                    </button>
                                                    <input
                                                        style={{
                                                            fontSize: "0.9rem",
                                                            padding: 7,
                                                            // border: "1px solid #999",
                                                            border: "1px solid #1a2f2a",
                                                            backgroundColor: "#1e3e49",
                                                            color: "#fff",
                                                            borderRadius: 10,
                                                        }}
                                                        required
                                                        type="date"
                                                        value={this.props.dataGrafico}
                                                        max={DateFormat.getDataAtual().replace(/\//g, "-")}
                                                        onFocus={(e) => (e.target.style.outline = "none")}
                                                        onChange={(e) => {
                                                            const valorData =
                                                                e.target.value ||
                                                                DateFormat.getDataAtual().replace(/\//g, "-");
                                                            this.props.setDataGrafico(valorData);
                                                            this.props.getListaGraficoTodasIntegracoesAgrupadasPorHora();
                                                        }}
                                                    ></input>
                                                    <button
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            fontSize: "1.2rem",
                                                            fontWeight: 500,
                                                            // boxShadow: this.desabilitarBotaoAvancarData() ? "inset 0 0 16px 1px #ccc" : "0 0 10px 1px #ccc",
                                                            boxShadow: this.desabilitarBotaoAvancarData()
                                                                ? "inset 0 0 16px 1px #1a2f2a"
                                                                : "0 0 10px 1px #1a2f2a",
                                                            // color: "#0070cc",
                                                            color: "#fff",
                                                            width: 40,
                                                            height: 40,
                                                            borderRadius: "100%",
                                                            // backgroundColor: "#fff",
                                                            backgroundColor: "#1e3e49",
                                                            border: "none",
                                                            marginInline: 20,
                                                        }}
                                                        onFocus={(e) => {
                                                            e.target.style.outline = "none";
                                                        }}
                                                        onClick={() => {
                                                            const dataGrafico = this.props.dataGrafico;
                                                            const modoTela = this.props.modoTela;
                                                            const tempo = 1;
                                                            this.voltarAvancarDataGrafico({
                                                                dataGrafico,
                                                                tempo,
                                                                modoTela,
                                                            });
                                                        }}
                                                        disabled={this.desabilitarBotaoAvancarData()}
                                                    >
                                                        {">"}
                                                    </button>
                                                </Grid>
                                                <div
                                                    id="graficoTodasIntegracoesAgrupadasPorHora"
                                                    style={{
                                                        width: "100%",
                                                        backgroundColor: "#1e3e49",
                                                        height: "275px",
                                                        borderRadius: "0 0 8px 8px",
                                                        overflow: "hidden",
                                                    }}
                                                ></div>
                                            </Row>
                                        </Grid>
                                        <Grid cols="12 12 12 6">
                                            <Row>
                                                {cardsTotalizadores}
                                                {cardsStatusProducao}
                                            </Row>
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <Grid cols="12 12 12 5">
                                            <Row>{cardsTotalizadores}</Row>
                                        </Grid>

                                        <Grid cols="12 12 12 6">
                                            <Row>{cardsStatusProducao}</Row>
                                        </Grid>
                                    </>
                                )}
                            </Row>
                        </>
                    )}
                    <Row>
                        {this.props.filtro.id_agrupador ? null : (
                            <>
                                {this.props.usuarioLogado && !this.props.usuarioLogado.acesso_cliente ? (
                                    <Select
                                        name="id_cliente"
                                        options={this.props.listaCliente.filter((item) =>
                                            this.props.listaUnidadeConsumidora.find(
                                                (uc) => uc.id_cliente == item.id && uc.geracao_integracao,
                                            ),
                                        )}
                                        label="Cliente"
                                        cols="12 6 6 6"
                                        placeholder="Selecione o Cliente"
                                        value={this.props.filtro.id_cliente}
                                        onChange={(value) => {
                                            this.props.setFiltro({
                                                ...this.props.filtro,
                                                id_cliente: value,
                                                id_unidade_consumidora: null,
                                            });
                                            this.buscarDadosTela();
                                        }}
                                    />
                                ) : null}
                                <Select
                                    name="id_unidade_consumidora"
                                    options={this.props.listaUnidadeConsumidora.filter((item) => {
                                        if (this.props.usuarioLogado && this.props.usuarioLogado.acesso_cliente) {
                                            return (
                                                this.props.usuarioLogado.id_cliente == item.id_cliente &&
                                                item.geracao_integracao
                                            );
                                        } else {
                                            return (
                                                (!this.props.filtro.id_cliente ||
                                                    this.props.filtro.id_cliente == item.id_cliente) &&
                                                item.geracao_integracao
                                            );
                                        }
                                    })}
                                    label="Unidade Consumidora"
                                    cols={
                                        this.props.usuarioLogado && this.props.usuarioLogado.acesso_cliente
                                            ? "12 12 12 12"
                                            : "12 6 6 6"
                                    }
                                    placeholder="Selecione a UC"
                                    value={this.props.filtro.id_unidade_consumidora}
                                    onChange={(value) => {
                                        this.props.setFiltro({
                                            ...this.props.filtro,
                                            id_cliente:
                                                this.props.usuarioLogado && this.props.usuarioLogado.acesso_cliente
                                                    ? this.props.usuarioLogado.id_cliente
                                                    : this.props.filtro.id_cliente,
                                            id_unidade_consumidora: value,
                                        });
                                        this.buscarDadosTela();
                                    }}
                                />
                                <Grid
                                    cols="12 4 4 4"
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        paddingTop: 11,
                                    }}
                                >
                                    <label
                                        style={this.getStyleSlimCard("#17a2b8", "#ffffff")}
                                        htmlFor="filtro-agrupar-por-uc"
                                    >
                                        <LabelAndCheckbox
                                            style={{ justifyContent: "center" }}
                                            readOnly={this.props.aguardando}
                                            divMarginBottom={0}
                                            id="filtro-agrupar-por-uc"
                                            label="Agrupar por UC"
                                            onChange={(e) => {
                                                this.props.setFiltro({
                                                    ...this.props.filtro,
                                                    agrupar_por_uc: !this.props.filtro.agrupar_por_uc,
                                                });
                                                this.buscarDadosTela();
                                            }}
                                            value={!!this.props.filtro.agrupar_por_uc}
                                        />
                                    </label>
                                </Grid>
                                <Grid
                                    cols="12 1 1 1"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        // fontSize: "1.7rem",
                                        paddingLeft: window.innerWidth < 992 ? 0 : null,
                                    }}
                                >
                                    <Button
                                        solid={true}
                                        style={{
                                            marginTop: 13,
                                            color: "#fff",

                                            transform: "scaleX(-1)",
                                        }}
                                        text={
                                            this.props.usuarioLogado.emitir_alerta_sonoro ? (
                                                <i style={{ fontSize: "1.2rem" }} className="far fa-bell" />
                                            ) : (
                                                <i style={{ fontSize: "1.2rem" }} className="far fa-bell-slash" />
                                            )
                                        }
                                        type={"warning"}
                                        event={this.props.setEmitirAlertaSonoro}
                                    />
                                </Grid>
                                <Grid
                                    cols="12 4 4 4"
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        paddingTop: 11,
                                    }}
                                >
                                    <label
                                        style={this.getStyleSlimCard("#f56969", "#ffffff")}
                                        htmlFor="filtro-apenas-indisponiveis"
                                    >
                                        <LabelAndCheckbox
                                            style={{ justifyContent: "center" }}
                                            readOnly={this.props.aguardando}
                                            divMarginBottom={0}
                                            id="filtro-apenas-indisponiveis"
                                            label="Apenas Indisponíveis"
                                            onChange={(e) =>
                                                this.setState({
                                                    ...this.state,
                                                    apenasIndisponiveis: !this.state.apenasIndisponiveis,
                                                })
                                            }
                                            value={!!this.state.apenasIndisponiveis}
                                        />
                                    </label>
                                </Grid>
                                <Grid
                                    cols={"12 3 3 3"}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        paddingBottom: 8,
                                    }}
                                >
                                    <span style={{ textAlign: "right", width: "100%", color: "#777" }}>
                                        <i>
                                            {window.innerWidth > 990 && window.innerWidth < 1600
                                                ? "At. "
                                                : "Atualizada em "}
                                            {this.state.ultima_atualizacao_lista}
                                        </i>
                                    </span>
                                    <Button
                                        text="Recarregar"
                                        type={"primary"}
                                        event={() => {
                                            this.buscarDadosTela();
                                        }}
                                    />
                                </Grid>
                            </>
                        )}
                    </Row>
                    {telaDetalhesAtiva ? (
                        <Row alignRight>
                            <Grid
                                cols="12 6 4"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "15px",
                                }}
                            >
                                <Button
                                    text="Voltar"
                                    type={"warning"}
                                    icon={"fa fa-chevron-left"}
                                    event={() => {
                                        this.props.setModoTela("lista", {});
                                        this.props.setFiltro({
                                            ...this.props.filtro,
                                            id_agrupador: null,
                                        });
                                        this.buscarDadosTela();
                                    }}
                                />
                            </Grid>
                        </Row>
                    ) : null}

                    <Table responsive>
                        <THead>
                            <Tr>
                                <Th sticky alignCenter verticalAlign="top">
                                    Unidade Consumidora
                                </Th>
                                <Th sticky alignCenter verticalAlign="top" maxWidth={70}>
                                    Status
                                </Th>
                                <Th sticky alignCenter verticalAlign="top">
                                    Data e Hora
                                </Th>
                                <Th sticky alignCenter verticalAlign="top">
                                    Tipo
                                </Th>
                                {this.props.filtro.id_agrupador ? null : (
                                    <Th colSpan="6" sticky alignCenter verticalAlign="top">
                                        Dados Meteorológicos
                                    </Th>
                                )}
                                <Th colSpan="2" sticky alignCenter verticalAlign="top">
                                    Potência Instalada
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <div style={{ display: "flex", justifyContent: "center", width: "49%" }}>
                                            Módulos
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "center", width: "2%" }}>|</div>
                                        <div style={{ display: "flex", justifyContent: "center", width: "49%" }}>
                                            Inversores
                                        </div>
                                    </div>
                                </Th>
                                <Th sticky alignCenter verticalAlign="top">
                                    Potência Instantânea
                                </Th>
                                <Th sticky alignCenter verticalAlign="top">
                                    Resumo Equipamentos
                                </Th>
                                {this.props.filtro.id_agrupador ? (
                                    <Th sticky alignCenter verticalAlign="top">
                                        Energia Instantânea
                                    </Th>
                                ) : (
                                    <>
                                        <Th sticky alignCenter verticalAlign="top">
                                            Energia do Dia
                                        </Th>
                                        <Th sticky alignCenter verticalAlign="top">
                                            Energia do Mês
                                        </Th>
                                        <Th sticky alignCenter verticalAlign="top">
                                            Energia do Ano
                                        </Th>
                                        <Th sticky alignCenter verticalAlign="top">
                                            Detalhes das últimas 24h
                                        </Th>
                                    </>
                                )}
                            </Tr>
                            <Tr></Tr>
                        </THead>
                        <TBody>
                            {this.props.lista
                                .filter((item) => {
                                    const usuarioLogadoAcessoCliente =
                                        this.props.usuarioLogado && this.props.usuarioLogado.acesso_cliente;
                                    const filtroClienteValidado =
                                        !usuarioLogadoAcessoCliente ||
                                        item.id_cliente == this.props.usuarioLogado.id_cliente;
                                    const filtroUcValidado =
                                        !this.props.filtro.id_unidade_consumidora ||
                                        item.id_unidade_consumidora == this.props.filtro.id_unidade_consumidora;
                                    return (
                                        (filtroClienteValidado && filtroUcValidado) || this.props.filtro.id_agrupador
                                    );
                                })
                                .filter((item) => {
                                    if (!this.state.apenasIndisponiveis) return true;
                                    return !item.statusVerificado;
                                })
                                .map((item, i, lista) => {
                                    return (
                                        <Tr key={item.id}>
                                            <Td minWidth={120}>{item.nome_unidade_consumidora}</Td>
                                            <Td alignCenter minWidth={60}>
                                                <div
                                                    style={{
                                                        height: 20,
                                                        width: 20,
                                                        borderRadius: "100%",
                                                        boxShadow: "inset 0 0 3px #777",
                                                        backgroundColor:
                                                            item.corStatus === this.props.STATUS_COR_AMARELA
                                                                ? "#FFCC22"
                                                                : item.corStatus === this.props.STATUS_COR_VERMELHA
                                                                ? "#FF1040"
                                                                : item.corStatus === this.props.STATUS_COR_VERDE
                                                                ? "#4AE30E"
                                                                : "",
                                                    }}
                                                    className={
                                                        this.props.filtro.id_agrupador
                                                            ? null
                                                            : item.corStatus === this.props.STATUS_COR_AMARELA &&
                                                              !item.status_visualizado
                                                            ? "geracao-leitura-status-alerta-amarela"
                                                            : item.corStatus === this.props.STATUS_COR_VERMELHA &&
                                                              !item.status_visualizado
                                                            ? "geracao-leitura-status-alerta-vermelha"
                                                            : null
                                                    }
                                                    title={
                                                        item.corStatus === this.props.STATUS_COR_AMARELA
                                                            ? "Alguns inversores estão indisponíveis"
                                                            : item.corStatus === this.props.STATUS_COR_VERMELHA
                                                            ? "Indisponível"
                                                            : item.corStatus === this.props.STATUS_COR_VERDE
                                                            ? "Funcionando"
                                                            : ""
                                                    }
                                                    onClick={() => {
                                                        if (
                                                            !item.status_visualizado &&
                                                            !this.props.filtro.id_agrupador &&
                                                            [
                                                                this.props.STATUS_COR_AMARELA,
                                                                this.props.STATUS_COR_VERMELHA,
                                                            ].includes(item.corStatus)
                                                        ) {
                                                            const idOuListaIds = this.props.filtro.agrupar_por_uc
                                                                ? item.ids_agrupados
                                                                : item.id;
                                                            this.props.setStatusVisualizado(idOuListaIds);
                                                        }
                                                    }}
                                                />
                                            </Td>
                                            <Td alignCenter>
                                                {new Date(item.data_hora)
                                                    .toLocaleString({
                                                        dateStyle: "short",
                                                        timeStyle: "medium",
                                                    })
                                                    .replace(",", "")}
                                            </Td>
                                            <Td alignCenter>
                                                {item.tipo instanceof Array
                                                    ? item.tipo.map((tipo, i) => (
                                                          <p key={i} style={{ margin: 0 }}>
                                                              {tipo}
                                                          </p>
                                                      ))
                                                    : item.tipo}
                                            </Td>
                                            {this.props.filtro.id_agrupador ? null : (
                                                <>
                                                    <Td alignCenter>
                                                        <DivBox>
                                                            {item.nome_icone ? (
                                                                <img
                                                                    src={weatherIcons[item.nome_icone]}
                                                                    width={40}
                                                                    height={40}
                                                                ></img>
                                                            ) : (
                                                                <i
                                                                    className="fas fa-question"
                                                                    style={{
                                                                        color: "#17a2b8",
                                                                        fontSize: "1.5rem",
                                                                    }}
                                                                ></i>
                                                            )}
                                                        </DivBox>
                                                    </Td>
                                                    <Td alignCenter>
                                                        <DivBox>
                                                            <i
                                                                className="fas fa-cloud"
                                                                style={{ fontSize: "1.1rem", padding: 10 }}
                                                            ></i>
                                                            {parseInt(item.nebulosidade) >= 0
                                                                ? parseInt(item.nebulosidade) + " %"
                                                                : "-"}
                                                        </DivBox>
                                                    </Td>
                                                    <Td alignCenter>
                                                        <DivBox>
                                                            <i
                                                                className="fas fa-cloud-showers-heavy"
                                                                style={{ fontSize: "1.1rem", padding: 10 }}
                                                            ></i>
                                                            {parseInt(item.quantidade_precipitacao) >= 0
                                                                ? parseInt(item.quantidade_precipitacao) + " mm"
                                                                : "-"}
                                                        </DivBox>
                                                    </Td>
                                                    <Td alignCenter>
                                                        <DivBox>
                                                            <i
                                                                className="fas fa-wind"
                                                                style={{ fontSize: "1.1rem", padding: 10 }}
                                                            ></i>
                                                            {parseInt(item.velocidade_vento) >= 0
                                                                ? parseInt(item.velocidade_vento * 3.6) + " km/h"
                                                                : "-"}
                                                        </DivBox>
                                                    </Td>
                                                    <Td alignCenter>
                                                        <DivBox>
                                                            <i
                                                                className="fas fa-tint"
                                                                style={{ fontSize: "1.1rem", padding: 10 }}
                                                            ></i>
                                                            {parseInt(item.umidade_relativa_ar) >= 0
                                                                ? parseInt(item.umidade_relativa_ar) + " %"
                                                                : "-"}
                                                        </DivBox>
                                                    </Td>
                                                    <Td alignCenter>
                                                        <DivBox>
                                                            <i
                                                                className="fas fa-temperature-low"
                                                                style={{ fontSize: "1.1rem", padding: 10 }}
                                                            ></i>
                                                            {item.temperatura_ar != null
                                                                ? parseInt(item.temperatura_ar) + " °C"
                                                                : "-"}
                                                        </DivBox>
                                                    </Td>
                                                </>
                                            )}
                                            <Td alignCenter>
                                                <DivBox>
                                                    <i
                                                        className="fas fa-solar-panel"
                                                        style={{ fontSize: "1.1rem", padding: 10 }}
                                                    ></i>

                                                    {item.modulos_potencia_total &&
                                                    parseFloat(item.modulos_potencia_total) >= 0
                                                        ? parseFloat(item.modulos_potencia_total)
                                                              .toFixed(1)
                                                              .replace(".", ",") + " kW"
                                                        : "-"}
                                                </DivBox>
                                            </Td>
                                            <Td alignCenter>
                                                <DivBox>
                                                    <i
                                                        className="fas fa-charging-station"
                                                        style={{ fontSize: "1.1rem", padding: 10 }}
                                                    ></i>

                                                    {item.inversor_potencia_total &&
                                                    parseFloat(item.inversor_potencia_total) >= 0
                                                        ? parseFloat(item.inversor_potencia_total)
                                                              .toFixed(1)
                                                              .replace(".", ",") + " kW"
                                                        : "-"}
                                                </DivBox>
                                            </Td>
                                            <Td alignCenter>
                                                {[this.props.STATUS_COR_VERDE, this.props.STATUS_COR_AMARELA].includes(
                                                    item.corStatus,
                                                ) ? (
                                                    <div
                                                        style={{
                                                            width: 85,
                                                            height: 85,
                                                            padding: "5px",
                                                            fontSize: "1rem",
                                                        }}
                                                    >
                                                        <CircularProgressbarWithChildren
                                                            styles={buildStyles({
                                                                pathColor:
                                                                    item.percentual_producao <= 50
                                                                        ? "#FF3838"
                                                                        : item.percentual_producao >= 85
                                                                        ? "#4AE30E"
                                                                        : "#FFE53C",
                                                                textColor: "#000",
                                                            })}
                                                            value={item.percentual_producao || 0}
                                                            // text={`${Number(item.potencia_instantanea).toFixed(1).replace(".", ",")} kW\n
                                                            // 		${item.percentual_producao} %`}
                                                        >
                                                            <div style={{ zIndex: 0 }}>
                                                                <p
                                                                    style={{
                                                                        margin: "11px 0 0 0",
                                                                        fontSize: "0.88rem",
                                                                    }}
                                                                >{`${Number(item.potencia_instantanea)
                                                                    .toFixed(1)
                                                                    .replace(".", ",")} kW`}</p>
                                                                <p
                                                                    style={{ margin: "-3px 0 0 0", fontSize: "0.7rem" }}
                                                                >{`${Math.round(item.percentual_producao)} %`}</p>
                                                            </div>
                                                        </CircularProgressbarWithChildren>
                                                    </div>
                                                ) : (
                                                    <div
                                                        title="Sem Geração de Energia"
                                                        style={{
                                                            borderRadius: "50%",
                                                            border: "5px solid #e3e3e3",
                                                            height: 72,
                                                            width: 73,
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <img src={noEnergy} width={30} height={30} />
                                                    </div>
                                                )}
                                            </Td>

                                            <Td alignCenter>
                                                <Button
                                                    title={"Visualizar dispositivos"}
                                                    style={{
                                                        width: 80,
                                                        height: 80,
                                                        padding: 0,
                                                    }}
                                                    text={
                                                        <i
                                                            style={{
                                                                fontSize: "1.1rem",
                                                                verticalAlign: "middle",
                                                            }}
                                                            className="fa fa-eye"
                                                        />
                                                    }
                                                    type={"info"}
                                                    event={() => {
                                                        this.props.setModoTela("dispositivos", item);
                                                    }}
                                                />
                                            </Td>
                                            {this.props.filtro.id_agrupador ? (
                                                <Td alignCenter minWidth={80}>
                                                    <span style={{ fontSize: "0.9rem" }}>
                                                        {parseFloat(item.energia_atual).toFixed(1).replace(".", ",")}{" "}
                                                        kWh
                                                    </span>
                                                </Td>
                                            ) : (
                                                <>
                                                    <Td alignCenter minWidth={80}>
                                                        <Button
                                                            style={{ width: 80, height: 80 }}
                                                            title="Ver o gráfico do dia"
                                                            text={
                                                                <span style={{ fontSize: "0.9rem" }}>
                                                                    {parseFloat(item.energia_dia)
                                                                        .toFixed(1)
                                                                        .replace(".", ",")}{" "}
                                                                    kWh
                                                                </span>
                                                            }
                                                            type={"info"}
                                                            event={() => {
                                                                this.props.getListaGraficoEnergiaDia({
                                                                    id_agrupador: this.props.filtro.agrupar_por_uc
                                                                        ? item.id_unidade_consumidora
                                                                        : item.id_geracao_integracao,
                                                                    data: this.props.dataGrafico,
                                                                });
                                                                this.props.setModoTela("graficoEnergiaDia", item);
                                                            }}
                                                        />
                                                    </Td>
                                                    <Td alignCenter minWidth={80}>
                                                        <Button
                                                            style={{ width: 80, height: 80 }}
                                                            title="Ver o gráfico do mês"
                                                            text={
                                                                <span style={{ fontSize: "0.9rem" }}>
                                                                    {parseFloat(item.energia_mes)
                                                                        .toFixed(1)
                                                                        .replace(".", ",")}{" "}
                                                                    kWh
                                                                </span>
                                                            }
                                                            type={"info"}
                                                            event={() => {
                                                                this.props.getListaGraficoEnergiaDiasMes({
                                                                    id_agrupador: this.props.filtro.agrupar_por_uc
                                                                        ? item.id_unidade_consumidora
                                                                        : item.id_geracao_integracao,
                                                                    data: this.props.dataGrafico,
                                                                });
                                                                this.props.setModoTela("graficoEnergiaDiasMes", item);
                                                            }}
                                                        />
                                                    </Td>
                                                    <Td alignCenter minWidth={80}>
                                                        <Button
                                                            style={{ width: 80, height: 80 }}
                                                            title="Ver o gráfico do ano"
                                                            text={
                                                                <span style={{ fontSize: "0.9rem" }}>
                                                                    {(parseFloat(item.energia_ano)
                                                                        ? parseFloat(item.energia_ano) / 1000
                                                                        : 0
                                                                    )
                                                                        .toFixed(1)
                                                                        .replace(".", ",")}{" "}
                                                                    MWh
                                                                </span>
                                                            }
                                                            type={"info"}
                                                            event={() => {
                                                                this.props.getListaGraficoEnergiaMesesAno({
                                                                    id_agrupador: this.props.filtro.agrupar_por_uc
                                                                        ? item.id_unidade_consumidora
                                                                        : item.id_geracao_integracao,
                                                                    data: this.props.dataGrafico,
                                                                });
                                                                this.props.setModoTela("graficoEnergiaMesesAno", item);
                                                            }}
                                                        />
                                                    </Td>
                                                    <Td alignCenter>
                                                        {item.energia_zerada_ultimos_2_dias ? (
                                                            <p
                                                                style={{
                                                                    width: 200,
                                                                    backgroundColor: "#ffc107",
                                                                    borderRadius: "0.3rem",
                                                                    padding: "0.2rem 1rem",
                                                                    lineHeight: "1rem",
                                                                    fontSize: "0.8rem",
                                                                    marginBottom: 2,
                                                                    cursor: "default",
                                                                }}
                                                            >
                                                                Há mais de 2 dias sem produção!
                                                            </p>
                                                        ) : null}

                                                        <Button
                                                            style={{
                                                                height: item.energia_zerada_ultimos_2_dias ? "" : 80,
                                                                width: 200,
                                                                marginLeft: 0,
                                                            }}
                                                            text={<span style={{ fontSize: "0.9rem" }}>Detalhes</span>}
                                                            type={"info"}
                                                            event={() => {
                                                                this.props.setModoTela("lista", {});
                                                                this.props.setFiltro({
                                                                    ...this.props.filtro,
                                                                    id_agrupador: this.props.filtro.agrupar_por_uc
                                                                        ? item.id_unidade_consumidora
                                                                        : item.id_geracao_integracao,
                                                                });
                                                                this.buscarDadosTela();
                                                            }}
                                                        />
                                                    </Td>
                                                </>
                                            )}
                                        </Tr>
                                    );
                                })}
                        </TBody>
                    </Table>
                </ContentCardBody>
            </ContentCard>
        );
    }
    atualizarDadosCada5Min() {
        const cincoMinutosMs = 1000 * 60 * 5;
        if (!this.props.lista.length) {
            this.buscarDadosTela();
        }

        const idIntervaloBusca = setInterval(() => this.buscarDadosTela(), cincoMinutosMs);
        this.setState({ ...this.state, idIntervaloBusca });
    }

    buscarDadosTela() {
        this.setState({
            ...this.state,
            ultima_atualizacao_lista: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()),
        });
        this.props.setDataGrafico(DateFormat.getDataAtual().replace(/\//g, "-"));
        this.props.getListaGraficoTodasIntegracoesAgrupadasPorHora();
        this.props.getLista();
    }

    emitirAlertaSonoro() {
        const listaFiltrada = this.props.lista
            .filter((item) => {
                const usuarioLogadoAcessoCliente = this.props.usuarioLogado && this.props.usuarioLogado.acesso_cliente;
                const filtroClienteValidado =
                    !usuarioLogadoAcessoCliente || item.id_cliente == this.props.usuarioLogado.id_cliente;
                const filtroUcValidado =
                    !this.props.filtro.id_unidade_consumidora ||
                    item.id_unidade_consumidora == this.props.filtro.id_unidade_consumidora;
                return (filtroClienteValidado && filtroUcValidado) || this.props.filtro.id_agrupador;
            })
            .filter((item) => {
                if (!this.state.apenasIndisponiveis) return true;
                return !item.statusVerificado;
            });
        // Emite o som de notificação para leituras com status vermelho até que clique para setar como verificado
        const existeLeiturasComErro = listaFiltrada.find(
            (item) =>
                !item.status_visualizado &&
                !this.props.filtro.id_agrupador &&
                [this.props.STATUS_COR_AMARELA, this.props.STATUS_COR_VERMELHA].includes(item.corStatus),
        );

        clearInterval(this.state.idIntervaloNotificacao);
        if (existeLeiturasComErro && this.props.usuarioLogado.emitir_alerta_sonoro) {
            let idIntervaloNotificacao = setInterval(async () => {
                try {
                    const somNotificacao = new Audio(somNotificacaoArquivo);

                    await somNotificacao.play();
                } catch (erro) {
                    if (!this.state.idIntervaloNotificacao) setError("Erro ao emitir alerta sonoro");
                }
            }, 3000);
            this.setState({ ...this.state, idIntervaloNotificacao });
        }
    }
    atualizarListasGraficos(data) {
        const filtro = {
            id_agrupador: this.props.filtro.agrupar_por_uc
                ? this.props.registro.id_unidade_consumidora
                : this.props.registro.id_geracao_integracao,
            data,
        };
        if (this.props.modoTela === "graficoEnergiaDia") {
            this.props.getListaGraficoEnergiaDia(filtro);
        }
        if (this.props.modoTela === "graficoEnergiaDiasMes") {
            this.props.getListaGraficoEnergiaDiasMes(filtro);
        }
        if (this.props.modoTela === "graficoEnergiaMesesAno") {
            this.props.getListaGraficoEnergiaMesesAno(filtro);
        }
    }

    voltarAvancarDataGrafico({ dataGrafico, tempo, modoTela }) {
        const novaData = new Date(dataGrafico);

        switch (modoTela) {
            case "lista": //usado no gráfico admin que é diário também
                novaData.setUTCDate(novaData.getUTCDate() + tempo);
                break;
            case "graficoEnergiaDia":
                novaData.setUTCDate(novaData.getUTCDate() + tempo);
                break;
            case "graficoEnergiaDiasMes":
                novaData.setUTCMonth(novaData.getUTCMonth() + tempo);
                break;
            case "graficoEnergiaMesesAno":
                novaData.setUTCFullYear(novaData.getUTCFullYear() + tempo);
                break;
        }

        this.props.setDataGrafico(novaData.toISOString().substring(0, 10));
        if (modoTela === "lista") {
            this.props.getListaGraficoTodasIntegracoesAgrupadasPorHora();
        } else {
            this.atualizarListasGraficos(novaData.toISOString().substring(0, 10));
        }
    }

    //deixa botoes da barra de rolagem do gráfico quadrados
    customizeChartGrip(grip) {
        // Remove default grip image
        grip.icon.disabled = true;

        // Disable background
        grip.background.disabled = true;

        // Add rotated rectangle as bi-di arrow
        const img = grip.createChild(am4core.Rectangle);
        img.width = 15;
        img.height = 15;
        img.fill = am4core.color("#0070cc");
        img.stroke = am4core.color("#0070cc");
        img.rotation = 45;
        img.align = "center";
        img.valign = "middle";

        // Add vertical bar
        const line = grip.createChild(am4core.Rectangle);
        line.height = 60;
        line.width = 3;
        line.fill = am4core.color("#0070cc");
        line.stroke = am4core.color("#0070cc");
        line.align = "center";
        line.valign = "middle";
    }
    montarGraficoLineTodasIntegracoesAgrupadasPorHora(listaGraficoTodasIntegracoesAgrupadasPorHora) {
        if (this.chart) {
            this.chart.dispose();
        }
        am4core.useTheme(am4themes_animated);

        let chart = am4core.create("graficoTodasIntegracoesAgrupadasPorHora", am4charts.XYChart);
        const title = chart.titles.create();
        title.text = "Energia Gerada no Dia";
        title.fontWeight = "bold";
        title.fontSize = "0.9rem";
        // title.fill = am4core.color("#000");
        title.fill = am4core.color("#fff");
        title.marginBottom = 10;

        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.filePrefix = "Gráfico";
        chart.exporting.menu.align = "right";
        chart.exporting.menu.verticalAlign = "top";
        chart.exporting.menu.items = [
            {
                label: "...",
                menu: [
                    {
                        type: "png",
                        label: "PNG",
                    },
                ],
            },
        ];
        chart.language.locale = locale;
        chart.language.locale["_decimalSeparator"] = ",";
        chart.language.locale["_thousandSeparator"] = ".";
        chart.numberFormatter.numberFormat = "###,###,###,###.00";
        chart.background.fill = "#1e3e49";
        chart.background.opacity = 1;

        // Create axes
        const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.startLocation = 0.5;
        dateAxis.endLocation = 0.5;
        dateAxis.dateFormats.setKey("minute", "HH:mm");
        // dateAxis.periodChangeDateFormats.setKey("minute", "hh:mm");
        dateAxis.baseInterval = {
            timeUnit: "minute",
            count: 5,
        };
        // dateAxis.renderer.labels.template.fill = am4core.color("#000"); //cor do texto eixo x
        dateAxis.renderer.labels.template.fill = am4core.color("#fff"); //cor do texto eixo x
        dateAxis.renderer.maxLabelPosition = 0.95;
        dateAxis.renderer.minGridDistance = 40;
        dateAxis.renderer.minLabelPosition = 0.05;

        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        if (chart.yAxes.indexOf(valueAxis) != 0) {
            valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
        }
        valueAxis.min = 0;
        // valueAxis.renderer.labels.template.fill = am4core.color("#000"); //cor do texto eixo y
        valueAxis.renderer.labels.template.fill = am4core.color("#fff"); //cor do texto eixo y
        // Create series
        const series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = "potencia_instantanea_media_hora";
        series.dataFields.dateX = "data_hora";
        series.yAxis = valueAxis;
        series.name = "Energia gerada ao longo do dia";
        series.tooltipText = `Potência Instantânea às {dateX.formatDate("HH")}h: [bold]{valueY.formatNumber("###,###,###,###.00")} kW[/]`;
        series.strokeWidth = 2;
        series.tensionX = 1;
        series.stacked = true;
        series.stroke = am4core.color("#017acd");
        series.fill = am4core.color("#017acd");
        series.fillOpacity = 0.2;

        //Adiciona cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.xAxis = dateAxis;
        chart.cursor.snapToSeries = series;

        //configura a cor do tooltip x
        const dateAxisTooltip = dateAxis.tooltip;
        dateAxisTooltip.background.fill = am4core.color("#0070cc");
        dateAxisTooltip.stroke = am4core.color("#fff");
        dateAxisTooltip.background.strokeWidth = 0;
        dateAxisTooltip.background.cornerRadius = 3;
        dateAxisTooltip.background.pointerLength = 0;
        dateAxisTooltip.dy = 5;

        //configura a cor do tooltip y
        const valueAxisTooltip = valueAxis.tooltip;
        valueAxisTooltip.background.fill = am4core.color("#0070cc");
        valueAxisTooltip.stroke = am4core.color("#fff");
        valueAxisTooltip.background.strokeWidth = 0;
        valueAxisTooltip.background.cornerRadius = 3;
        valueAxisTooltip.background.pointerLength = 0;
        valueAxisTooltip.dy = 5;

        valueAxis.renderer.line.strokeOpacity = 1;
        valueAxis.renderer.line.strokeWidth = 2;

        chart.data = listaGraficoTodasIntegracoesAgrupadasPorHora.map((leitura) => {
            const { potencia_instantanea_media_hora, data_hora, ...rest } = leitura;
            return { potencia_instantanea_media_hora, data_hora: new Date(data_hora) };
        });

        this.chart = chart;
    }
    montarGraficoLineEnergiaDia(nome, listaGraficoEnergiaDia) {
        am4core.useTheme(am4themes_animated);

        let chart = am4core.create(nome, am4charts.XYChart);
        const subtitle = chart.titles.create();
        subtitle.text = this.props.registro.nome_unidade_consumidora;
        subtitle.fontWeight = "bold";
        subtitle.fontSize = window.innerWidth < 1150 ? "0.7rem" : "1rem";
        subtitle.fill = am4core.color("#000");
        subtitle.marginBottom = 30;

        const title = chart.titles.create();
        title.text = "Energia Gerada no Dia";
        title.fontWeight = "bold";
        title.fontSize = window.innerWidth < 1150 ? "0.8rem" : "1.2rem";
        title.fill = am4core.color("#000");
        title.marginBottom = 30;

        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.filePrefix = "Gráfico";
        chart.exporting.menu.align = "right";
        chart.exporting.menu.verticalAlign = "top";
        chart.exporting.menu.items = [
            {
                label: "...",
                menu: [
                    {
                        type: "png",
                        label: "PNG",
                    },
                ],
            },
        ];
        chart.language.locale = locale;
        chart.language.locale["_decimalSeparator"] = ",";
        chart.language.locale["_thousandSeparator"] = ".";
        chart.numberFormatter.numberFormat = "###,###,###,###.00";

        // Create axes
        const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.startLocation = 0.5;
        dateAxis.endLocation = 0.5;
        dateAxis.dateFormats.setKey("minute", "HH:mm");
        // dateAxis.periodChangeDateFormats.setKey("minute", "hh:mm");
        dateAxis.baseInterval = {
            timeUnit: "minute",
            count: 5,
        };
        dateAxis.renderer.labels.template.fill = am4core.color("#000"); //cor do texto eixo x
        dateAxis.renderer.maxLabelPosition = 0.95;
        dateAxis.renderer.minGridDistance = 40;
        dateAxis.renderer.minLabelPosition = 0.05;

        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        if (chart.yAxes.indexOf(valueAxis) != 0) {
            valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
        }
        valueAxis.min = 0;
        valueAxis.renderer.labels.template.fill = am4core.color("#000"); //cor do texto eixo y
        // Create series
        const series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = "potencia_instantanea";
        series.dataFields.dateX = "data_hora";
        series.yAxis = valueAxis;
        series.name = "Energia gerada ao longo do dia";
        series.tooltipText = `Potência Instantânea às {dateX.formatDate("HH:mm")}h: [bold]{valueY.formatNumber("###,###,###,###.00")} kW[/]`;
        series.strokeWidth = 2;
        series.tensionX = 1;
        series.stacked = true;
        series.stroke = am4core.color("#017acd");
        series.fill = am4core.color("#017acd");
        series.fillOpacity = 0.2;

        //Estilo da scrollbar
        chart.scrollbarX = new am4charts.XYChartScrollbar();
        chart.scrollbarX.series.push(series);
        chart.scrollbarX.unselectedOverlay.fill = am4core.color("#fff");
        chart.scrollbarX.unselectedOverlay.fillOpacity = 0.7;
        chart.scrollbarX.unselectedOverlay.stroke = am4core.color("#fff");

        chart.scrollbarX.background.fill = am4core.color("#fff");
        chart.scrollbarX.thumb.background.fill = am4core.color("#2190ff"); //cor do trecho selecionado
        chart.scrollbarX.thumb.background.fillOpacity = 0.3; //opacidade da cor do trecho selecionado
        chart.scrollbarX.stroke = am4core.color("#003040"); // cor do texto dentro do trecho seleionado
        chart.scrollbarX.strokeWidth = 0;

        //Cor da scrollbar ao passar o mouse
        chart.scrollbarX.startGrip.background.states.getKey("hover").properties.fill = am4core.color("#2190ff");
        chart.scrollbarX.startGrip.background.states.getKey("hover").properties.fillOpacity = 0.3;

        chart.scrollbarX.endGrip.background.states.getKey("hover").properties.fill = am4core.color("#2190ff");
        chart.scrollbarX.endGrip.background.states.getKey("hover").properties.fillOpacity = 0.3;

        chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill = am4core.color("#2190ff");
        chart.scrollbarX.thumb.background.states.getKey("hover").properties.fillOpacity = 0.3;

        //Adiciona legenda
        chart.legend = new am4charts.Legend();
        chart.legend.labels.template.fill = am4core.color("#000");

        //Adiciona cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.xAxis = dateAxis;
        chart.cursor.snapToSeries = series;

        //configura a cor do tooltip x
        const dateAxisTooltip = dateAxis.tooltip;
        dateAxisTooltip.background.fill = am4core.color("#0070cc");
        dateAxisTooltip.stroke = am4core.color("#fff");
        dateAxisTooltip.background.strokeWidth = 0;
        dateAxisTooltip.background.cornerRadius = 3;
        dateAxisTooltip.background.pointerLength = 0;
        dateAxisTooltip.dy = 5;

        //configura a cor do tooltip y
        const valueAxisTooltip = valueAxis.tooltip;
        valueAxisTooltip.background.fill = am4core.color("#0070cc");
        valueAxisTooltip.stroke = am4core.color("#fff");
        valueAxisTooltip.background.strokeWidth = 0;
        valueAxisTooltip.background.cornerRadius = 3;
        valueAxisTooltip.background.pointerLength = 0;
        valueAxisTooltip.dy = 5;

        valueAxis.renderer.line.strokeOpacity = 1;
        valueAxis.renderer.line.strokeWidth = 2;

        chart.data = listaGraficoEnergiaDia.map((leitura) => {
            const { potencia_instantanea, data_hora, ...rest } = leitura;
            return { potencia_instantanea, data_hora: new Date(data_hora) };
        });

        //deixa botoes da barra de rolagem quadrados
        this.customizeChartGrip(chart.scrollbarX.startGrip);
        this.customizeChartGrip(chart.scrollbarX.endGrip);

        this.chart = chart;
    }

    montarGraficoColunasEnergiaDiasMes(nome, listaGraficoEnergiaDiasMes) {
        am4core.useTheme(am4themes_animated);
        listaGraficoEnergiaDiasMes.map((item) => (item.icone = weatherIcons[item.nome_icone]));
        let chart = am4core.create(nome, am4charts.XYChart);
        const subtitle = chart.titles.create();
        subtitle.text = this.props.registro.nome_unidade_consumidora;
        subtitle.fontWeight = "bold";
        subtitle.fontSize = window.innerWidth < 1150 ? "0.7rem" : "1rem";
        subtitle.fill = am4core.color("#000");
        subtitle.marginBottom = 30;

        const title = chart.titles.create();
        title.text = "Energia Gerada no Mês";
        title.fontWeight = "bold";
        title.fontSize = window.innerWidth < 1150 ? "0.8rem" : "1.2rem";
        title.fill = am4core.color("#000");
        title.marginBottom = 30;

        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.filePrefix = "Gráfico";
        chart.exporting.menu.align = "right";
        chart.exporting.menu.verticalAlign = "top";
        chart.exporting.menu.items = [
            {
                label: "...",
                menu: [
                    {
                        type: "png",
                        label: "PNG",
                    },
                ],
            },
        ];
        chart.language.locale = locale;
        chart.language.locale["_decimalSeparator"] = ",";
        chart.language.locale["_thousandSeparator"] = ".";
        chart.numberFormatter.numberFormat = "###,###,###,###.00";

        // Create axes
        const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.renderer.minGridDistance = 30;
        dateAxis.startLocation = 0;
        dateAxis.endLocation = 1;

        dateAxis.dateFormats.setKey("day", "dd/MM\nEEE");

        dateAxis.markUnitChange = false;
        dateAxis.baseInterval = {
            timeUnit: "day",
            count: 1,
        };

        dateAxis.renderer.labels.template.fontSize = 12;
        dateAxis.renderer.labels.template.textAlign = "middle";
        dateAxis.renderer.labels.template.fill = am4core.color("#000"); //cor do texto eixo x

        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        if (chart.yAxes.indexOf(valueAxis) != 0) {
            valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
        }

        valueAxis.renderer.labels.template.fill = am4core.color("#000"); //cor do texto eixo y
        valueAxis.min = 0;

        const serieProducaoEsperadaDia = chart.series.push(new am4charts.LineSeries());
        serieProducaoEsperadaDia.dataFields.valueY = "producao_esperada_dia";
        serieProducaoEsperadaDia.dataFields.dateX = "data";
        serieProducaoEsperadaDia.yAxis = valueAxis;
        serieProducaoEsperadaDia.name = "Produção esperada do dia";
        serieProducaoEsperadaDia.tooltipText = `Produção esperada: [bold]{valueY.formatNumber("###,###,###,###.00")} kWh[/]`;
        serieProducaoEsperadaDia.stroke = am4core.color("#cd2236");
        serieProducaoEsperadaDia.strokeWidth = 2;
        // const bulletProducaoEsperadaDia = serieProducaoEsperadaDia.bullets.push(new am4charts.CircleBullet());
        // bulletProducaoEsperadaDia.fill = am4core.color("#ff5454");

        // Create series
        const serieEnergiaDia = chart.series.push(new am4charts.ColumnSeries());
        serieEnergiaDia.dataFields.valueY = "energia_dia";
        serieEnergiaDia.dataFields.dateX = "data";
        serieEnergiaDia.yAxis = valueAxis;
        serieEnergiaDia.name = "Energia gerada por dia";
        serieEnergiaDia.tooltipText = `Energia gerada em {dateX.formatDate("dd/MM/yyyy, EEEE")}: [bold]{valueY.formatNumber("###,###,###,###.00")} kWh[/]`;
        serieEnergiaDia.stroke = am4core.color("#017acd");
        serieEnergiaDia.fill = am4core.color("#017acd");
        serieEnergiaDia.fillOpacity = 0.2;

        // Do not crop bullets
        chart.maskBullets = false;

        // Remove padding
        chart.paddingBottom = 0;

        // Adiciona imagens acima das colunas
        const bullet = serieEnergiaDia.bullets.push(new am4charts.Bullet());
        const image = bullet.createChild(am4core.Image);
        image.horizontalCenter = "middle";
        image.dy = -50;
        image.width = 40;
        image.y = am4core.percent(100);
        image.propertyFields.href = "icone";
        image.tooltipText = serieEnergiaDia.columns.template.tooltipText;
        image.propertyFields.fill = "color";
        image.filters.push(new am4core.DropShadowFilter());

        //Estilo da scrollbar
        chart.scrollbarX = new am4charts.XYChartScrollbar();
        chart.scrollbarX.series.push(serieProducaoEsperadaDia);
        chart.scrollbarX.series.push(serieEnergiaDia);
        chart.scrollbarX.unselectedOverlay.fill = am4core.color("#fff");
        chart.scrollbarX.unselectedOverlay.fillOpacity = 0.7;
        chart.scrollbarX.unselectedOverlay.stroke = am4core.color("#fff");

        chart.scrollbarX.background.fill = am4core.color("#fff");
        chart.scrollbarX.thumb.background.fill = am4core.color("#2190ff"); //cor do trecho selecionado
        chart.scrollbarX.thumb.background.fillOpacity = 0.3; //opacidade da cor do trecho selecionado
        chart.scrollbarX.stroke = am4core.color("#003040"); // cor do texto dentro do trecho seleionado
        chart.scrollbarX.strokeWidth = 0;

        //Cor da scrollbar ao passar o mouse
        chart.scrollbarX.startGrip.background.states.getKey("hover").properties.fill = am4core.color("#2190ff");
        chart.scrollbarX.startGrip.background.states.getKey("hover").properties.fillOpacity = 0.3;

        chart.scrollbarX.endGrip.background.states.getKey("hover").properties.fill = am4core.color("#2190ff");
        chart.scrollbarX.endGrip.background.states.getKey("hover").properties.fillOpacity = 0.3;

        chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill = am4core.color("#2190ff");
        chart.scrollbarX.thumb.background.states.getKey("hover").properties.fillOpacity = 0.3;

        //Adiciona legenda
        chart.legend = new am4charts.Legend();
        chart.legend.labels.template.fill = am4core.color("#000");

        //Adiciona cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.xAxis = dateAxis;
        chart.cursor.snapToSeries = [serieProducaoEsperadaDia, serieEnergiaDia];

        //configura a cor do tooltip x
        const dateAxisTooltip = dateAxis.tooltip;
        dateAxisTooltip.background.fill = am4core.color("#0070cc");
        dateAxisTooltip.stroke = am4core.color("#fff");
        dateAxisTooltip.background.strokeWidth = 0;
        dateAxisTooltip.background.cornerRadius = 3;
        dateAxisTooltip.background.pointerLength = 0;
        dateAxisTooltip.dy = 5;
        dateAxisTooltip.label.textAlign = "middle";

        //configura a cor do tooltip y
        const valueAxisTooltip = valueAxis.tooltip;
        valueAxisTooltip.background.fill = am4core.color("#0070cc");
        valueAxisTooltip.stroke = am4core.color("#fff");
        valueAxisTooltip.background.strokeWidth = 0;
        valueAxisTooltip.background.cornerRadius = 3;
        valueAxisTooltip.background.pointerLength = 0;
        valueAxisTooltip.dy = 5;
        valueAxis.extraMax = 0.3;

        valueAxis.renderer.line.strokeOpacity = 1;
        valueAxis.renderer.line.strokeWidth = 2;

        chart.data = listaGraficoEnergiaDiasMes;

        this.customizeChartGrip(chart.scrollbarX.startGrip);
        this.customizeChartGrip(chart.scrollbarX.endGrip);

        this.chart = chart;
    }

    montarGraficoColunasEnergiaMesesAno(nome, listaGraficoEnergiaMesesAno) {
        am4core.useTheme(am4themes_animated);

        let chart = am4core.create(nome, am4charts.XYChart);
        const subtitle = chart.titles.create();
        subtitle.text = this.props.registro.nome_unidade_consumidora;
        subtitle.fontWeight = "bold";
        subtitle.fontSize = window.innerWidth < 1150 ? "0.7rem" : "1rem";
        subtitle.fill = am4core.color("#000");
        subtitle.marginBottom = 30;

        const title = chart.titles.create();
        title.text = "Energia Gerada no Ano";
        title.fontWeight = "bold";
        title.fontSize = window.innerWidth < 1150 ? "0.8rem" : "1.2rem";
        title.fill = am4core.color("#000");
        title.marginBottom = 30;

        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.filePrefix = "Gráfico";
        chart.exporting.menu.align = "right";
        chart.exporting.menu.verticalAlign = "top";
        chart.exporting.menu.items = [
            {
                label: "...",
                menu: [
                    {
                        type: "png",
                        label: "PNG",
                    },
                ],
            },
        ];
        chart.language.locale = locale;
        chart.language.locale["_decimalSeparator"] = ",";
        chart.language.locale["_thousandSeparator"] = ".";
        chart.language.locale["_date_month"] = "MMM";
        chart.numberFormatter.numberFormat = "###,###,###,###.00";

        // Create axes
        const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.renderer.minGridDistance = 30;
        dateAxis.startLocation = 0;
        dateAxis.endLocation = 1;
        dateAxis.dateFormats.setKey("month", "MMM");
        dateAxis.periodChangeDateFormats.setKey("month", "MMM");
        dateAxis.baseInterval = {
            timeUnit: "month",
            count: 1,
        };
        // dateAxis.renderer.labels.template.fontSize = 20;
        dateAxis.renderer.labels.template.fill = am4core.color("#000"); //cor do texto eixo x

        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        if (chart.yAxes.indexOf(valueAxis) != 0) {
            valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
        }

        valueAxis.renderer.labels.template.fill = am4core.color("#000"); //cor do texto eixo y
        valueAxis.min = 0;
        // Create series
        const series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "energia_mes";
        series.dataFields.dateX = "data";
        series.yAxis = valueAxis;
        series.name = "Energia gerada por mês";
        series.tooltipText = `Energia gerada em {dateX.formatDate("MMM yyyy")}: [bold]{valueY.formatNumber("###,###,###,###.00")} kWh[/]`;
        series.stroke = am4core.color("#017acd");
        series.fill = am4core.color("#017acd");
        series.fillOpacity = 0.2;

        //Estilo da scrollbar
        chart.scrollbarX = new am4charts.XYChartScrollbar();
        chart.scrollbarX.series.push(series);
        chart.scrollbarX.unselectedOverlay.fill = am4core.color("#fff");
        chart.scrollbarX.unselectedOverlay.fillOpacity = 0.7;
        chart.scrollbarX.unselectedOverlay.stroke = am4core.color("#fff");

        chart.scrollbarX.background.fill = am4core.color("#fff");
        chart.scrollbarX.thumb.background.fill = am4core.color("#2190ff"); //cor do trecho selecionado
        chart.scrollbarX.thumb.background.fillOpacity = 0.3; //opacidade da cor do trecho selecionado
        chart.scrollbarX.stroke = am4core.color("#003040"); // cor do texto dentro do trecho seleionado
        chart.scrollbarX.strokeWidth = 0;

        //Cor da scrollbar ao passar o mouse
        chart.scrollbarX.startGrip.background.states.getKey("hover").properties.fill = am4core.color("#2190ff");
        chart.scrollbarX.startGrip.background.states.getKey("hover").properties.fillOpacity = 0.3;

        chart.scrollbarX.endGrip.background.states.getKey("hover").properties.fill = am4core.color("#2190ff");
        chart.scrollbarX.endGrip.background.states.getKey("hover").properties.fillOpacity = 0.3;

        chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill = am4core.color("#2190ff");
        chart.scrollbarX.thumb.background.states.getKey("hover").properties.fillOpacity = 0.3;

        //Adiciona legenda
        chart.legend = new am4charts.Legend();
        chart.legend.labels.template.fill = am4core.color("#000");

        //Adiciona cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.xAxis = dateAxis;
        chart.cursor.snapToSeries = series;

        //configura a cor do tooltip x
        const dateAxisTooltip = dateAxis.tooltip;
        dateAxisTooltip.background.fill = am4core.color("#0070cc");
        dateAxisTooltip.stroke = am4core.color("#fff");
        dateAxisTooltip.background.strokeWidth = 0;
        dateAxisTooltip.background.cornerRadius = 3;
        dateAxisTooltip.background.pointerLength = 0;
        dateAxisTooltip.dy = 5;

        //configura a cor do tooltip y
        const valueAxisTooltip = valueAxis.tooltip;
        valueAxisTooltip.background.fill = am4core.color("#0070cc");
        valueAxisTooltip.stroke = am4core.color("#fff");
        valueAxisTooltip.background.strokeWidth = 0;
        valueAxisTooltip.background.cornerRadius = 3;
        valueAxisTooltip.background.pointerLength = 0;
        valueAxisTooltip.dy = 5;

        valueAxis.renderer.line.strokeOpacity = 1;
        valueAxis.renderer.line.strokeWidth = 2;

        chart.data = listaGraficoEnergiaMesesAno;

        this.customizeChartGrip(chart.scrollbarX.startGrip);
        this.customizeChartGrip(chart.scrollbarX.endGrip);

        this.chart = chart;
    }

    exibirPercentualProducaoAcumulada(listaGraficoEnergiaDiasMes) {
        if (!listaGraficoEnergiaDiasMes.length) return 0; //se não tiver itens na lista ainda retorna zero
        const producaoEsperadaDia = (listaGraficoEnergiaDiasMes[0] || {}).producao_esperada_dia;
        const diaAtual = new Date().getDate();
        const mesAtual = new Date().getMonth() + 1;

        //se estiver no mes atual considera todos registros menores que a data atual
        //senão considera todos registros, pois é de um mes anterior

        const listaSemDiaAtual = listaGraficoEnergiaDiasMes.filter(
            (item) => parseInt(item.data.substring(5, 7)) != mesAtual || item.data.substring(8) < diaAtual,
        );
        //soma todas energia_dia que a data for menor que a atual
        const somaEnergiaDia = listaSemDiaAtual.reduce((total, atual) => {
            total += parseFloat(atual.energia_dia || 0);
            return total;
        }, 0);
        const ultimoDiaMesFiltro = new Date(
            new Date(this.props.dataGrafico).getFullYear(),
            new Date(this.props.dataGrafico).getMonth() + 1,
            0,
        ).getDate();

        //se for um mes diferente do atual no filtro, considera o ultimo dia do mes filtrado para calcular o total
        //se for o mes atual, considera o total dias até a data atual (hoje - 1)
        //considera só dias completos, ex: hj é 10/04, considera 9 dias
        const quantidadeDias =
            parseInt(this.props.dataGrafico.substring(5, 7)) != mesAtual ? ultimoDiaMesFiltro : diaAtual - 1;

        //se a lista de dias a serem avaliados for vazia mostra 0% atingido
        if (!quantidadeDias) return 0;

        //se não tiver produção esperada cadastrada exibe msg mandando cadastrar
        if (isNaN(parseFloat(producaoEsperadaDia))) return null;

        const somaProducaoEsperadaDia = producaoEsperadaDia * quantidadeDias;

        const resultado = (somaEnergiaDia / somaProducaoEsperadaDia) * 100;
        return resultado;
    }

    exibirGrafico(nomeGrafico) {
        if (this.chart) {
            this.chart.dispose();
        }
        //usado no gráfico energia dias do mês
        const percentualProcucaoAcumulada = this.exibirPercentualProducaoAcumulada(
            this.props.listaGraficoEnergiaDiasMes,
        );

        const graficoAtivo = {
            graficoEnergiaDia: null,
            graficoEnergiaDiasMes: null,
            graficoEnergiaMesesAno: null,
        };

        graficoAtivo[nomeGrafico] = true;

        if (this.state.graficoEnergiaDia) {
            this.montarGraficoLineEnergiaDia("graficoEnergiaDia", this.props.listaGraficoEnergiaDia);
        }
        if (this.state.graficoEnergiaDiasMes) {
            this.montarGraficoColunasEnergiaDiasMes("graficoEnergiaDiasMes", this.props.listaGraficoEnergiaDiasMes);
        }
        if (this.state.graficoEnergiaMesesAno) {
            this.montarGraficoColunasEnergiaMesesAno("graficoEnergiaMesesAno", this.props.listaGraficoEnergiaMesesAno);
        }

        return (
            <ContentCard style={{ backgroundColor: "#f0f1f5", paddingLeft: 0 }}>
                <ContentCardBody padding="0px">
                    <Row
                        style={{
                            // overflow: "scroll",
                            height: "fit-content",
                            paddingLeft: 15,
                            paddingRight: 7,
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexWrap: "no-wrap",
                                // minWidth: "1150px",
                                width: "100%",
                                paddingTop: 15,
                                marginBottom: -1,
                                boxShadow: "inset 0 0 2px #ddd",
                                backgroundColor: "##fff",
                            }}
                        >
                            <Grid
                                cols="3 3 3 3"
                                style={{
                                    padding: 0,
                                    backgroundColor: "#fff",
                                    margin: "0px 5px 5px 0px",
                                    borderRadius: "0.3rem",
                                }}
                            >
                                <Button
                                    style={{ margin: 0 }}
                                    text="Voltar"
                                    type={"warning"}
                                    icon={"fa fa-chevron-left"}
                                    event={() => {
                                        this.props.setModoTela("lista", {});
                                        this.setState({
                                            ...this.state,
                                            [nomeGrafico]: null,
                                        });
                                        this.props.setDataGrafico(DateFormat.getDataAtual().replace(/\//g, "-"));
                                        // this.buscarDadosTela();
                                    }}
                                />
                            </Grid>
                            <Grid
                                cols="3 3 3 3"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: this.props.modoTela === "graficoEnergiaDia" ? "#fff" : "#f5f5f5",
                                    cursor: "pointer",
                                    boxShadow:
                                        this.props.modoTela === "graficoEnergiaDia"
                                            ? "inset 0 2px 2px 1px #e7e7e7"
                                            : "#e9e4e4 0px 2px 5px 1px inset",
                                    marginTop: this.props.modoTela === "graficoEnergiaDia" ? -4 : null,
                                    borderRadius: "15px 15px 0 0",
                                    borderBottom: `1px solid ${
                                        this.props.modoTela === "graficoEnergiaDia" ? "#fff" : "#ddd"
                                    } `,
                                }}
                                title="Ver o gráfico do dia"
                                onClick={() => {
                                    this.props.getListaGraficoEnergiaDia({
                                        id_agrupador: this.props.filtro.agrupar_por_uc
                                            ? this.props.registro.id_unidade_consumidora
                                            : this.props.registro.id_geracao_integracao,
                                        data: this.props.dataGrafico,
                                    });
                                    this.props.setModoTela("graficoEnergiaDia", this.props.registro);
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: "0.9rem",
                                    }}
                                >
                                    Dia
                                </span>
                            </Grid>

                            <Grid
                                cols="3 3 3 3"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor:
                                        this.props.modoTela === "graficoEnergiaDiasMes" ? "#fff" : "#f5f5f5",
                                    cursor: "pointer",
                                    boxShadow:
                                        this.props.modoTela === "graficoEnergiaDiasMes"
                                            ? "inset 0 2px 2px 1px #e7e7e7"
                                            : "#e9e4e4 0px 2px 5px 1px inset",
                                    marginTop: this.props.modoTela === "graficoEnergiaDiasMes" ? -4 : null,
                                    borderRadius: "15px 15px 0 0",
                                    borderBottom: `1px solid ${
                                        this.props.modoTela === "graficoEnergiaDiasMes" ? "#fff" : "#ddd"
                                    } `,
                                }}
                                title="Ver o gráfico do mês"
                                onClick={() => {
                                    this.props.getListaGraficoEnergiaDiasMes({
                                        id_agrupador: this.props.filtro.agrupar_por_uc
                                            ? this.props.registro.id_unidade_consumidora
                                            : this.props.registro.id_geracao_integracao,
                                        data: this.props.dataGrafico,
                                    });
                                    this.props.setModoTela("graficoEnergiaDiasMes", this.props.registro);
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: "0.9rem",
                                    }}
                                >
                                    Mês
                                </span>
                            </Grid>

                            <Grid
                                cols="3 3 3 3"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor:
                                        this.props.modoTela === "graficoEnergiaMesesAno" ? "#fff" : "#f5f5f5",
                                    cursor: "pointer",
                                    boxShadow:
                                        this.props.modoTela === "graficoEnergiaMesesAno"
                                            ? "inset 0 2px 2px 1px #e7e7e7"
                                            : "#e9e4e4 0px 2px 5px 1px inset",
                                    marginTop: this.props.modoTela === "graficoEnergiaMesesAno" ? -4 : null,
                                    borderRadius: "15px 15px 0 0",
                                    borderBottom: `1px solid ${
                                        this.props.modoTela === "graficoEnergiaMesesAno" ? "#fff" : "#ddd"
                                    } `,
                                }}
                                title="Ver o gráfico do ano"
                                onClick={() => {
                                    this.props.getListaGraficoEnergiaMesesAno({
                                        id_agrupador: this.props.filtro.agrupar_por_uc
                                            ? this.props.registro.id_unidade_consumidora
                                            : this.props.registro.id_geracao_integracao,
                                        data: this.props.dataGrafico,
                                    });
                                    this.props.setModoTela("graficoEnergiaMesesAno", this.props.registro);
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: "0.9rem",
                                    }}
                                >
                                    Ano
                                </span>
                            </Grid>
                        </div>
                        <Row
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                                width: "100%",
                                backgroundColor: "#fff",
                                borderTop: "1px solid #ddd",
                                paddingTop: 25,
                                alignItems: "center",
                                justifyContent: window.innerWidth < 1340 ? "center" : "start",
                            }}
                        >
                            {this.props.modoTela === "graficoEnergiaDiasMes" ? (
                                <Grid
                                    cols="12 6 4 4"
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        minWidth: "fit-content",
                                        paddingTop: 28,
                                    }}
                                >
                                    <div
                                        style={{
                                            color: "#ffffff",
                                            backgroundColor: "#1e3e49",
                                            borderRadius: 15,
                                            boxShadow: "0 0 10px 1px #b9b9b9",
                                            padding: "20px 20px",
                                            marginBlock: 5,
                                        }}
                                    >
                                        <p style={{ fontSize: window.innerWidth < 450 ? "0.7rem" : "1rem" }}>
                                            <b>Indicador parcial, rendimento mensal até a data de hoje.</b>
                                        </p>
                                        <Row style={{ display: "flex", flexWrap: "nowrap", justifyContent: "center" }}>
                                            {isNaN(parseFloat(percentualProcucaoAcumulada)) ? (
                                                <p
                                                    style={{
                                                        fontSize: window.innerWidth < 450 ? "0.7rem" : "1rem",
                                                        margin: 0,
                                                    }}
                                                >
                                                    Cadastre a produção esperada para visualizar.
                                                </p>
                                            ) : (
                                                <>
                                                    <ProgressBar valor={percentualProcucaoAcumulada} />
                                                    <div
                                                        style={{
                                                            fontSize: window.innerWidth < 450 ? "0.7rem" : "1rem",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            paddingTop: 3,
                                                            marginLeft: 5,
                                                        }}
                                                    >
                                                        {Math.round(percentualProcucaoAcumulada)}%
                                                    </div>
                                                </>
                                            )}
                                        </Row>
                                    </div>
                                </Grid>
                            ) : null}
                            <Grid
                                cols={this.props.modoTela === "graficoEnergiaDiasMes" ? "12 6 4 4" : "12 12 12 12"}
                                style={{
                                    display: "flex",
                                    minWidth: "fit-content",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <button
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontSize: "1.5rem",
                                        fontWeight: 500,
                                        boxShadow: this.desabilitarBotaoVoltarData()
                                            ? "inset 0 0 16px 1px #ccc"
                                            : "0 0 10px 1px #ccc",
                                        color: "#0070cc",
                                        width: 40,
                                        height: 40,
                                        borderRadius: "100%",
                                        backgroundColor: "#fff",
                                        border: "none",
                                        marginInline: 20,
                                    }}
                                    onFocus={(e) => {
                                        e.target.style.outline = "none";
                                    }}
                                    onClick={() => {
                                        const dataGrafico = this.props.dataGrafico;
                                        const modoTela = this.props.modoTela;
                                        const tempo = -1;
                                        this.voltarAvancarDataGrafico({
                                            dataGrafico,
                                            tempo,
                                            modoTela,
                                        });
                                    }}
                                    disabled={this.desabilitarBotaoVoltarData()}
                                >
                                    {"<"}
                                </button>
                                {this.state.graficoEnergiaDia && (
                                    <input
                                        style={{
                                            fontSize: "1.2rem",
                                            padding: 7,
                                            border: "1px solid #999",
                                            borderRadius: 10,
                                        }}
                                        required
                                        type="date"
                                        value={this.props.dataGrafico}
                                        max={DateFormat.getDataAtual().replace(/\//g, "-")}
                                        onFocus={(e) => (e.target.style.outline = "none")}
                                        onChange={(e) => {
                                            const valorData =
                                                e.target.value || DateFormat.getDataAtual().replace(/\//g, "-");

                                            this.props.setDataGrafico(valorData);
                                            this.atualizarListasGraficos(valorData);
                                        }}
                                    ></input>
                                )}
                                {this.state.graficoEnergiaDiasMes && (
                                    <select
                                        style={{
                                            fontSize: "1.2rem",
                                            padding: 7,
                                            border: "1px solid #999",
                                            backgroundColor: "#fff",
                                            borderRadius: 10,
                                        }}
                                        value={this.props.dataGrafico.substring(5, 7)}
                                        onFocus={(e) => (e.target.style.outline = "none")}
                                        onChange={(e) => {
                                            const dataAtual = this.props.dataGrafico;
                                            const novaData = dataAtual.substring(0, 5) + e.target.value + "-01";

                                            const dataMaiorQueAtual =
                                                new Date(novaData).getTime() > new Date().getTime();
                                            if (!dataMaiorQueAtual) {
                                                this.props.setDataGrafico(novaData);
                                                this.atualizarListasGraficos(novaData);
                                            }
                                        }}
                                    >
                                        <option value="01">Janeiro</option>
                                        <option value="02">Fevereiro</option>
                                        <option value="03">Março</option>
                                        <option value="04">Abril</option>
                                        <option value="05">Maio</option>
                                        <option value="06">Junho</option>
                                        <option value="07">Julho</option>
                                        <option value="08">Agosto</option>
                                        <option value="09">Setembro</option>
                                        <option value="10">Outubro</option>
                                        <option value="11">Novembro</option>
                                        <option value="12">Dezembro</option>
                                    </select>
                                )}
                                {(this.state.graficoEnergiaDiasMes || this.state.graficoEnergiaMesesAno) && (
                                    <select
                                        style={{
                                            marginLeft: this.state.graficoEnergiaDiasMes ? 5 : 0,
                                            fontSize: "1.2rem",
                                            padding: 7,
                                            border: "1px solid #999",
                                            backgroundColor: "#fff",
                                            borderRadius: 10,
                                        }}
                                        value={this.props.dataGrafico.substring(0, 4)}
                                        onFocus={(e) => (e.target.style.outline = "none")}
                                        onChange={(e) => {
                                            const dataAtual = this.props.dataGrafico;
                                            const novaData = e.target.value + "-01-01";
                                            this.props.setDataGrafico(novaData);
                                            this.atualizarListasGraficos(novaData);
                                        }}
                                    >
                                        {(() => {
                                            let options = [];
                                            let anoInicioLeituras = 2023;
                                            for (let i = anoInicioLeituras; i <= new Date().getFullYear(); i++) {
                                                options.push(
                                                    <option key={i} value={String(i)}>
                                                        {i}
                                                    </option>,
                                                );
                                            }
                                            return options;
                                        })()}
                                    </select>
                                )}
                                <button
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontSize: "1.5rem",
                                        fontWeight: 500,
                                        boxShadow: this.desabilitarBotaoAvancarData()
                                            ? "inset 0 0 16px 1px #ccc"
                                            : "0 0 10px 1px #ccc",
                                        color: "#0070cc",
                                        width: 40,
                                        height: 40,
                                        borderRadius: "100%",
                                        backgroundColor: "#fff",
                                        border: "none",
                                        marginInline: 20,
                                    }}
                                    onFocus={(e) => {
                                        e.target.style.outline = "none";
                                    }}
                                    onClick={() => {
                                        const dataGrafico = this.props.dataGrafico;
                                        const modoTela = this.props.modoTela;
                                        const tempo = 1;
                                        this.voltarAvancarDataGrafico({
                                            dataGrafico,
                                            tempo,
                                            modoTela,
                                        });
                                    }}
                                    disabled={this.desabilitarBotaoAvancarData()}
                                >
                                    {">"}
                                </button>
                            </Grid>
                        </Row>

                        <div
                            id={`${nomeGrafico}`}
                            style={{
                                width: "100%",
                                paddingTop: 15,
                                backgroundColor: "#fff",
                                height: "500px",
                            }}
                        ></div>

                        {this.state[nomeGrafico] ? null : this.setState({ ...this.state, ...graficoAtivo })}
                    </Row>
                </ContentCardBody>
            </ContentCard>
        );
    }

    desabilitarBotaoVoltarData() {
        if (
            ["graficoEnergiaDiasMes", "lista"].includes(this.props.modoTela) &&
            this.props.dataGrafico.substring(0, 7) === "2023-01"
        )
            return true;
        if (this.props.modoTela === "graficoEnergiaMesesAno" && this.props.dataGrafico.substring(0, 4) === "2023")
            return true;
        return false;
    }

    desabilitarBotaoAvancarData() {
        if (
            ["graficoEnergiaDia", "lista"].includes(this.props.modoTela) &&
            this.props.dataGrafico === new Date().toISOString().substring(0, 10)
        )
            return true;
        if (
            this.props.modoTela === "graficoEnergiaDiasMes" &&
            this.props.dataGrafico.substring(0, 7) === new Date().toISOString().substring(0, 7)
        )
            return true;
        if (
            this.props.modoTela === "graficoEnergiaMesesAno" &&
            this.props.dataGrafico.substring(0, 4) === new Date().toISOString().substring(0, 4)
        )
            return true;
        return false;
    }

    listaDispositivos(item) {
        const { resumo_equipamento } = item;
        return (
            <ContentCard>
                <ContentCardBody>
                    <Row alignRight>
                        <Grid
                            cols="12 6 4"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "15px",
                            }}
                        >
                            <Button
                                text="Voltar"
                                type={"warning"}
                                icon={"fa fa-chevron-left"}
                                event={() => {
                                    this.props.setModoTela("lista", {});
                                    // this.buscarDadosTela();
                                }}
                            />
                        </Grid>
                    </Row>
                    <Row alignCenter>
                        <h4 style={{ marginBottom: 20 }}>Lista de Inversores</h4>
                    </Row>

                    {resumo_equipamento.map((inversor, i) => {
                        const valor = JSON.parse(inversor);
                        return (
                            <Row key={i}>
                                <ContentCard
                                    style={{
                                        width: "100%",
                                        marginInline: 20,
                                        marginBottom: 20,
                                    }}
                                >
                                    <ContentCardBody padding={"15px 10px 0"}>
                                        {valor.tipo ? (
                                            <p>
                                                <strong>Tipo: </strong>
                                                {valor.tipo}
                                            </p>
                                        ) : (
                                            ""
                                        )}
                                        {valor.codigo_unidade || valor.id_usina ? (
                                            <p>
                                                <strong>Código da Unidade: </strong>
                                                {valor.codigo_unidade || valor.id_usina}
                                            </p>
                                        ) : (
                                            ""
                                        )}

                                        {valor.nome_sistema_fotovoltaico && (
                                            <p>
                                                <strong>Nome do Sistema Fotovoltaico: </strong>
                                                {valor.nome_sistema_fotovoltaico}
                                            </p>
                                        )}
                                        {valor.id_sistema_fotovoltaico && (
                                            <p>
                                                <strong>ID Sistema Fotovoltaico: </strong>
                                                {valor.id_sistema_fotovoltaico}
                                            </p>
                                        )}
                                        {valor.id_dispositivo || valor.id_inversor ? (
                                            <p>
                                                <strong>ID do Dispositivo: </strong>
                                                {valor.id_dispositivo || valor.id_inversor}
                                            </p>
                                        ) : (
                                            ""
                                        )}
                                        {valor.nome_dispositivo || valor.nome_inversor ? (
                                            <p>
                                                <strong>Nome do Dispositivo: </strong>
                                                {valor.nome_dispositivo || valor.nome_inversor}
                                            </p>
                                        ) : (
                                            ""
                                        )}
                                        {valor.descricao_inversor && (
                                            <p>
                                                <strong>Descrição do Inversor: </strong>
                                                {valor.descricao_inversor}
                                            </p>
                                        )}
                                        {valor.modelo_inversor && (
                                            <p>
                                                <strong>Modelo do Inversor: </strong>
                                                {valor.modelo_inversor}
                                            </p>
                                        )}
                                        {valor.numero_serie && (
                                            <p>
                                                <strong>Número de Série: </strong>
                                                {valor.numero_serie}
                                            </p>
                                        )}
                                        {valor.codigo_planta && (
                                            <p>
                                                <strong>Código da Planta: </strong>
                                                {valor.codigo_planta}
                                            </p>
                                        )}
                                        {valor.codigo_inversor && (
                                            <p>
                                                <strong>Código do Inversor: </strong>
                                                {valor.codigo_inversor}
                                            </p>
                                        )}

                                        {valor.modelo && (
                                            <p>
                                                <strong>Modelo do Inversor: </strong>
                                                {valor.modelo}
                                            </p>
                                        )}

                                        <p>
                                            <strong>Status de Conexão do Inversor: </strong>
                                            {valor.status ? "Conectado" : "Desconectado"}
                                        </p>
                                        <p>
                                            <strong>Status de Geração do Inversor: </strong>
                                            {valor.status && parseFloat(valor.potencia_instantanea || 0) > 0
                                                ? "Está gerando energia"
                                                : "Não está gerando energia"}
                                        </p>
                                        {!isNaN(parseFloat(valor.potencia_instalada)) && (
                                            <p>
                                                <strong>Potência Instalada: </strong>
                                                {parseFloat(valor.potencia_instalada).toFixed(2).replace(".", ",") +
                                                    " kW"}
                                            </p>
                                        )}
                                        {!isNaN(parseFloat(valor.potencia_instantanea)) && (
                                            <p>
                                                <strong>Potência Instantânea: </strong>
                                                {parseFloat(
                                                    valor.status && parseFloat(valor.potencia_instantanea) > 0
                                                        ? valor.potencia_instantanea
                                                        : 0,
                                                )
                                                    .toFixed(1)
                                                    .replace(".", ",") + " kW"}
                                            </p>
                                        )}
                                    </ContentCardBody>
                                </ContentCard>
                            </Row>
                        );
                    })}
                </ContentCardBody>
            </ContentCard>
        );
    }

    getStyleSlimCard(bgColor, textColor) {
        return {
            borderRadius: 8,
            cursor: "pointer",
            backgroundColor: bgColor,
            color: textColor,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px 10px 0px 10px",
            width: "100%",
            minHeight: "40px",
            margin: 5,
            cursor: "pointer",
            boxShadow: "#9b9b9b 0px 1px 2px 1px",
        };
    }
}

const mapStateToProps = (state) => {
    return {
        sessao: state.auth.usuario,
        usuarioLogado: state.auth.usuarioLogado,
        modoTela: state.geracaoLeitura.modoTela,
        aguardando: state.geracaoLeitura.aguardando,
        dataGrafico: state.geracaoLeitura.dataGrafico,
        filtro: state.geracaoLeitura.filtro,
        registro: state.geracaoLeitura.registro,
        lista: state.geracaoLeitura.lista,
        listaCliente: state.geracaoLeitura.listaCliente,
        listaUnidadeConsumidora: state.geracaoLeitura.listaUnidadeConsumidora,
        listaGraficoTodasIntegracoesAgrupadasPorHora: state.geracaoLeitura.listaGraficoTodasIntegracoesAgrupadasPorHora,
        listaGraficoEnergiaDia: state.geracaoLeitura.listaGraficoEnergiaDia,
        listaGraficoEnergiaDiasMes: state.geracaoLeitura.listaGraficoEnergiaDiasMes,
        listaGraficoEnergiaMesesAno: state.geracaoLeitura.listaGraficoEnergiaMesesAno,
        STATUS_COR_AMARELA: state.geracaoLeitura.AMARELA,
        STATUS_COR_VERMELHA: state.geracaoLeitura.VERMELHA,
        STATUS_COR_VERDE: state.geracaoLeitura.VERDE,
    };
};
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setEmitirAlertaSonoro,
            setDataGrafico,
            setModoTela,
            setFiltro,
            getLista,
            getListaCliente,
            getListaUnidadeConsumidora,
            getListaGraficoTodasIntegracoesAgrupadasPorHora,
            getListaGraficoEnergiaDia,
            getListaGraficoEnergiaDiasMes,
            getListaGraficoEnergiaMesesAno,
            setStatusVisualizado,
            setAguardando,
        },
        dispatch,
    );
export default connect(mapStateToProps, mapDispatchToProps)(GeracaoLeitura);
