import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, getFormValues } from "redux-form";

import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardFooter from "../common/template/contentCardFooter";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import Tr from "../common/table/tr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import ButtonTable from "../common/table/buttonTable";
import Button from "../common/button/button";
import Form from "./documentoForm";
import Select from "../common/form/select";
import imagemSoLogo from "../assets/images/logo_aguardando.gif";
import FormatUtils from "../common/formatUtils/FormatUtils";

import { getUcValidarUsuarioImplantacao } from "../cliente/clienteActions";
import { adicionarBloqueioUsuarioImplantacao } from "../utils/adicionarBloqueioUsuarioImplantacao";
import {
	setModoTela,
	initForm,
	setFiltro,
	salvar,
	excluir,
	getLista,
	getListaCliente,
	getListaUnidadeConsumidora,
	getListaDocumentoTipo,
} from "./documentoActions";
import { Loading } from "../common/loading/loading";

class Documento extends Component {
	state = {
		linhaSelecionada: null,
	};

	componentWillMount() {
		this.props.getUcValidarUsuarioImplantacao();
		this.props.getLista();
		this.props.getListaCliente();
		this.props.getListaUnidadeConsumidora();
		this.props.getListaDocumentoTipo();

		let idInterval = setInterval(() => {
			if (this.props.usuarioLogado && this.props.usuarioLogado.administrador) {
				this.props.getLista();
				clearInterval(idInterval);
			} else if (this.props.usuarioLogado && this.props.usuarioLogado.acesso_cliente) {
				this.props.setFiltro({
					...this.props.filtro,
					id_cliente: this.props.usuarioLogado.id_cliente,
				});
				this.props.getLista();
				clearInterval(idInterval);
			}
		}, 2000);
	}

	render() {
		//Sem acesso
		if (!this.props.usuarioLogado) {
			return <div />;
		}

		return (
			<div>
				{this.props.aguardando ? <Loading /> : null}

				<Content>
					{this.props.modoTela == "visualizacao" ? <Form onSubmit={() => {}} visualizacao /> : null}
					{this.props.modoTela == "cadastro" ? <Form onSubmit={this.props.salvar} /> : null}
					{this.props.modoTela == "exclusao" ? <Form excluir onSubmit={this.props.excluir} /> : null}
					{this.props.modoTela == "lista" ? this.lista() : null}
				</Content>
			</div>
		);
	}

	lista() {
		let acessoCliente = !!this.props.usuarioLogado.acesso_cliente;
		let usuarioEdicao = !!this.props.usuarioLogado.acesso_documento_editar;

		return (
			<ContentCard>
				<ContentCardHeader>
					{usuarioEdicao ? (
						<Row>
							<Grid cols="6 6 4 2">
								<Button
									text="Adicionar"
									type={"success"}
									icon={"fa fa-plus"}
									disabled={!usuarioEdicao}
									event={() => {
										this.props.setModoTela("cadastro", {});
										this.props.initForm({ anexos: [] });
									}}
								/>
							</Grid>
						</Row>
					) : null}
				</ContentCardHeader>
				<ContentCardBody>
					<Row>
						{!acessoCliente ? (
							<Select
								name="id_cliente"
								options={this.props.listaCliente}
								label="Cliente"
								cols="12 6 4"
								placeholder="Selecione o Cliente"
								value={this.props.filtro.id_cliente}
								onChange={(value) => {
									this.props.setFiltro({
										...this.props.filtro,
										id_cliente: value,
									});
									this.props.getLista();
								}}
							/>
						) : null}

						<Select
							name="id_unidade_consumidora"
							options={this.props.listaUnidadeConsumidora.filter((item) =>
								acessoCliente
									? item.id_cliente == this.props.usuarioLogado.id_cliente
									: this.props.filtro.id_cliente
									? item.id_cliente == this.props.filtro.id_cliente
									: true
							)}
							label="Unidade Consumidora"
							cols="12 6 4"
							placeholder="Selecione a UC"
							value={this.props.filtro.id_unidade_consumidora}
							onChange={(value) => {
								this.props.setFiltro({
									...this.props.filtro,
									id_unidade_consumidora: value,
								});
								this.props.getLista();
							}}
						/>
						<Select
							name="id_documento_tipo"
							options={this.props.listaDocumentoTipo}
							label="Tipo"
							cols="12 6 4"
							placeholder="Selecione o Tipo de Documento"
							value={this.props.filtro.id_documento_tipo}
							onChange={(value) => {
								this.props.setFiltro({
									...this.props.filtro,
									id_documento_tipo: value,
								});
								this.props.getLista();
							}}
						/>
					</Row>

					<Table responsive>
						<THead>
							<Tr>
								<Th sticky>Cliente</Th>
								<Th sticky>Unidade Consumidora</Th>
								<Th sticky>Tipo</Th>
								<Th sticky>Documento</Th>
								<Th sticky minWidth={70}>
									Data
								</Th>
								<Th sticky></Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.lista.map((item) => (
								<Tr
									key={item.id}
									className={`${this.state.linhaSelecionada == item.id ? "hover" : ""}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
								>
									<Td>{item.nome_cliente}</Td>
									<Td>{item.nome_unidade_consumidora}</Td>
									<Td>{item.nome_documento_tipo}</Td>
									<Td>{item.nome}</Td>
									<Td>
										{item.vigencia_inicio ? `${String(item.vigencia_inicio).substring(4, 6)}/${String(item.vigencia_inicio).substring(0, 4)}` : null}
									</Td>
									<Td alignRight minWidth={140}>
										<ButtonTable
											type={"primary"}
											icon={"fas fa-eye"}
											visible={this.state.linhaSelecionada == item.id}
											disabled={!usuarioEdicao}
											event={() => {
												let registro = {
													...item,
													vigencia_inicio: `${String(item.vigencia_inicio).substring(4, 6)}/${String(item.vigencia_inicio).substring(0, 4)}`,
													vigencia_fim: `${String(item.vigencia_fim).substring(4, 6)}/${String(item.vigencia_fim).substring(0, 4)}`,
												};
												this.props.setModoTela("visualizacao", registro);
												this.props.initForm(registro);
											}}
										/>
										{!acessoCliente ? (
											<ButtonTable
												type={"warning"}
												icon={"fas fa-pencil-alt"}
												visible={this.state.linhaSelecionada == item.id}
												disabled={!usuarioEdicao || item.bloquearEdicaoUsuarioImplantacao}
												event={() => {
													let registro = {
														...item,
														vigencia_inicio: `${String(item.vigencia_inicio).substring(4, 6)}/${String(item.vigencia_inicio).substring(0, 4)}`,
														vigencia_fim: `${String(item.vigencia_fim).substring(4, 6)}/${String(item.vigencia_fim).substring(0, 4)}`,
													};
													this.props.setModoTela("cadastro", registro);
													this.props.initForm(registro);
												}}
											/>
										) : null}
										{!acessoCliente ? (
											<ButtonTable
												type={"danger"}
												icon={"fas fa-trash-alt"}
												visible={this.state.linhaSelecionada == item.id}
												disabled={!usuarioEdicao || item.bloquearEdicaoUsuarioImplantacao}
												event={() => {
													let registro = {
														...item,
														vigencia_inicio: `${String(item.vigencia_inicio).substring(4, 6)}/${String(item.vigencia_inicio).substring(0, 4)}`,
														vigencia_fim: `${String(item.vigencia_fim).substring(4, 6)}/${String(item.vigencia_fim).substring(0, 4)}`,
													};
													this.props.setModoTela("exclusao", registro);
													this.props.initForm(registro);
												}}
											/>
										) : null}
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}
}

const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	modoTela: state.documento.modoTela,
	formularioValues: getFormValues("documentoForm")(state),
	aguardando: state.documento.aguardando,
	filtro: state.documento.filtro,
	lista: adicionarBloqueioUsuarioImplantacao(state.auth.usuarioLogado, state.cliente.listaUcValidarUsuarioImplantacao, state.documento.lista),
	listaCliente: state.documento.listaCliente,
	listaUnidadeConsumidora: state.documento.listaUnidadeConsumidora,
	listaDocumentoTipo: state.documento.listaDocumentoTipo,
	listaMeses: state.documento.listaMeses,
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setModoTela,
			initForm,
			setFiltro,
			salvar,
			excluir,
			getLista,
			getListaCliente,
			getListaUnidadeConsumidora,
			getListaDocumentoTipo,
			getUcValidarUsuarioImplantacao,
		},
		dispatch
	);
export default connect(mapStateToProps, mapDispatchToProps)(Documento);
