import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, getFormValues } from "redux-form";

import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import Tr from "../common/table/tr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import ButtonTable from "../common/table/buttonTable";
import Button from "../common/button/button";
import Form from "./geracaoForm";
import AmpliacaoForm from "./geracaoAmpliacaoForm";
import Select from "../common/form/select";
import FormatUtils from "../common/formatUtils/FormatUtils";
import { getUcValidarUsuarioImplantacao } from "../cliente/clienteActions";
import { adicionarBloqueioUsuarioImplantacao } from "../utils/adicionarBloqueioUsuarioImplantacao";
import { Loading } from "../common/loading/loading";
import {
	setModoTela,
	initForm,
	setFiltro,
	salvar,
	excluir,
	getLista,
	getListaCliente,
	getListaUnidadeConsumidora,
	salvarAmpliacao,
	excluirAmpliacao,
} from "./geracaoActions";

class Geracao extends Component {
	state = {
		linhaSelecionada: null,
	};

	componentWillMount() {
		this.props.getUcValidarUsuarioImplantacao();
		this.props.getLista();
		this.props.getListaCliente();
		this.props.getListaUnidadeConsumidora();
	}

	render() {
		//Sem acesso
		if (!this.props.usuarioLogado || this.props.usuarioLogado.acesso_cliente) {
			return <div />;
		}

		return (
			<div>
				{this.props.aguardando ? <Loading /> : null}

				<Content>
					{this.props.modoTela == "visualizacao" ? <Form onSubmit={() => {}} visualizacao /> : null}
					{this.props.modoTela == "cadastro" ? <Form onSubmit={this.props.salvar} /> : null}
					{this.props.modoTela == "exclusao" ? <Form excluir onSubmit={this.props.excluir} /> : null}
					{this.props.modoTela == "cadastroAmpliacao" ? <AmpliacaoForm onSubmit={this.props.salvarAmpliacao} /> : null}
					{this.props.modoTela == "exclusaoAmpliacao" ? <AmpliacaoForm excluir onSubmit={this.props.excluirAmpliacao} /> : null}
					{this.props.modoTela == "lista" ? this.lista() : null}
				</Content>
			</div>
		);
	}

	lista() {
		const listaMeses = ["janeiro", "fevereiro", "marco", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"];
		function carregarDadosRegistro(item) {
			const valoresMesesAnteriores = {};
			const valoresMesesAtuais = {};
			listaMeses.forEach((mes) => {
				valoresMesesAnteriores[`anterior_${mes}`] = FormatUtils.formatarValorTela(item[`anterior_${mes}`], 2);
				valoresMesesAtuais[mes] = FormatUtils.formatarValorTela(item[mes], 2);
			});
			const registro = {
				...item,
				competencia_inicio: `${String(item.competencia_inicio).substring(4, 6)}/${String(item.competencia_inicio).substring(0, 4)}`,
				...valoresMesesAnteriores,
				...valoresMesesAtuais,
				inversor_quantidade: FormatUtils.formatarValorTela(item.inversor_quantidade, 0),
				inversor_potencia_individual: FormatUtils.formatarValorTela(item.inversor_potencia_individual, 2),
				inversor_potencia_total: FormatUtils.formatarValorTela(item.inversor_potencia_total, 2),
				modulos_quantidade: FormatUtils.formatarValorTela(item.modulos_quantidade, 0),
				modulos_potencia_individual: FormatUtils.formatarValorTela(item.modulos_potencia_individual, 2),
				modulos_potencia_total: FormatUtils.formatarValorTela(item.modulos_potencia_total, 2),
				valor_investido: FormatUtils.formatarValorTela(item.valor_investido, 2),
			};
			return registro;
		}
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols="6 6 4 2">
							<Button
								text="Adicionar"
								type={"success"}
								icon={"fa fa-plus"}
								disabled={!this.props.usuarioLogado.acesso_cadastro_geracao_adicionar}
								event={() => {
									this.props.setModoTela("cadastro", {});
									this.props.initForm({ itens: [] });
								}}
							/>
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>
						<Select
							name="id_cliente"
							options={this.props.listaCliente}
							label="Cliente"
							cols="12 6 4"
							placeholder="Selecione o Cliente"
							value={this.props.filtro.id_cliente}
							onChange={(value) => {
								this.props.setFiltro({
									...this.props.filtro,
									id_cliente: value,
								});
								this.props.getLista();
							}}
						/>

						<Select
							name="id_unidade_consumidora"
							options={this.props.listaUnidadeConsumidora.filter((item) =>
								this.props.filtro.id_cliente ? item.id_cliente == this.props.filtro.id_cliente : true
							)}
							label="Unidade Consumidora"
							cols="12 6 4"
							placeholder="Selecione a UC"
							value={this.props.filtro.id_unidade_consumidora}
							onChange={(value) => {
								this.props.setFiltro({
									...this.props.filtro,
									id_unidade_consumidora: value,
								});
								this.props.getLista();
							}}
						/>

						<Select
							name="ano"
							options={Array.from({ length: 20 }, (v, i) => ({
								id: new Date().getFullYear() - 5 + i,
								valor: new Date().getFullYear() - 5 + i,
							}))}
							label="Ano"
							cols="12 6 4"
							placeholder="Selecione o ano"
							value={this.props.filtro.ano}
							onChange={(value) => {
								this.props.setFiltro({
									...this.props.filtro,
									ano: value,
								});
								this.props.getLista();
							}}
						/>
					</Row>

					<Table responsive>
						<THead>
							<Tr>
								<Th sticky>Ano</Th>
								<Th sticky>Cliente</Th>
								<Th sticky>UC</Th>
								<Th sticky>Janeiro</Th>
								<Th sticky>Fevereiro</Th>
								<Th sticky>Março</Th>
								<Th sticky>Abril</Th>
								<Th sticky>Maio</Th>
								<Th sticky>Junho</Th>
								<Th sticky>Julho</Th>
								<Th sticky>Agosto</Th>
								<Th sticky>Setembro</Th>
								<Th sticky>Outubro</Th>
								<Th sticky>Novembro</Th>
								<Th sticky>Dezembro</Th>
								<Th sticky></Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.lista.map((item) => (
								<Tr
									key={item.id}
									className={`${this.state.linhaSelecionada == item.id ? "hover" : ""}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									style={{
										backgroundColor: item.historico || this.state.linhaSelecionada == item.id ? null : "#eceff1",
									}}
								>
									<Td>{item.ano}</Td>
									<Td minWidth={100} noWrap>
										{item.nome_cliente}
									</Td>
									<Td minWidth={150} noWrap>
										{item.nome_unidade_consumidora}
									</Td>
									{listaMeses.map((mes) => (
										<Td>{FormatUtils.formatarValorTela(item[mes], 2)}</Td>
									))}
									<Td alignRight minWidth={150}>
										<ButtonTable
											type={"primary"}
											icon={"fas fa-eye"}
											disabled={item.historico || !this.props.usuarioLogado.acesso_cadastro_geracao_visualizar}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												const registro = carregarDadosRegistro(item);
												this.props.setModoTela("visualizacao", registro);
												this.props.initForm(registro);
											}}
										/>

										<ButtonTable
											type={"warning"}
											icon={"fas fa-pencil-alt"}
											disabled={item.historico || !this.props.usuarioLogado.acesso_cadastro_geracao_editar || item.bloquearEdicaoUsuarioImplantacao}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												const registro = carregarDadosRegistro(item);
												this.props.setModoTela("cadastro", registro);
												this.props.initForm(registro);
											}}
										/>

										<ButtonTable
											type={"danger"}
											icon={"fas fa-trash-alt"}
											visible={this.state.linhaSelecionada == item.id}
											disabled={item.historico || !this.props.usuarioLogado.acesso_cadastro_geracao_excluir || item.bloquearEdicaoUsuarioImplantacao}
											event={() => {
												const registro = carregarDadosRegistro(item);
												this.props.setModoTela("exclusao", registro);
												this.props.initForm(registro);
											}}
										/>
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}
}

const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	modoTela: state.geracao.modoTela,
	formularioValues: getFormValues("geracaoForm")(state),
	aguardando: state.geracao.aguardando,
	filtro: state.geracao.filtro,
	lista: adicionarBloqueioUsuarioImplantacao(state.auth.usuarioLogado, state.cliente.listaUcValidarUsuarioImplantacao, state.geracao.lista),
	listaCliente: state.geracao.listaCliente,
	listaUnidadeConsumidora: state.geracao.listaUnidadeConsumidora.filter((uc) => uc.geracao || uc.remota),
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setModoTela,
			initForm,
			setFiltro,
			salvar,
			excluir,
			getLista,
			getListaCliente,
			getListaUnidadeConsumidora,
			salvarAmpliacao,
			excluirAmpliacao,
			getUcValidarUsuarioImplantacao,
		},
		dispatch
	);
export default connect(mapStateToProps, mapDispatchToProps)(Geracao);
