import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import NewTr from "../common/table/newTr";
import Tr from "../common/table/tr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import ButtonTable from "../common/table/buttonTable";
import Button from "../common/button/button";
import Select from "../common/form/select";
import { Loading } from "../common/loading/loading";
import { DisplayPDF } from "../displayPDF/displayPDF";
import {
	setModoTela,
	initForm,
	setFiltro,
	salvar,
	salvarMultiplos,
	excluir,
	getLista,
	getListaCliente,
	getListaUnidadeConsumidora,
	buscaPdf,
} from "./faturaActions";
import { reprocessar, reprocessarTodas, setAguardando } from "./faturaItem/faturaItemActions";
import { setArquivoPDF } from "../displayPDF/displayPDFActions";
import LabelAndCheckbox from "../common/form/labelAndCheckbox";
import { htmlToPdf } from "../htmlToPdf/htmlToPdf";

import TabelaFaturaVerificadorIcmsPDF from "../htmlToPdf/TabelaFaturaVerificadorIcmsPDF";

class FaturaEngenharia extends Component {
	state = {
		apenasIcmsDivergente: false,
	};

	async componentWillMount() {
		this.props.getListaCliente();
		this.props.getListaUnidadeConsumidora();

		if (this.props.usuarioLogado && this.props.usuarioLogado.administrador) {
			await this.props.getLista();
		} else if (this.props.usuarioLogado && this.props.usuarioLogado.acesso_cliente) {
			this.props.setFiltro({
				...this.props.filtro,
				id_cliente: this.props.usuarioLogado.id_cliente,
			});
			await this.props.getLista();
		}
	}

	render() {
		if (this.props.pdf) {
			return (
				<DisplayPDF
					closePdf={this.props.setArquivoPDF}
					pdfBase64={this.props.pdf}
					nomePdf={this.props.nomePdf}
					orientation={this.props.orientation}
					zoomParametro={1.5}
				/>
			);
		}
		return (
			<div>
				{this.props.aguardando ? <Loading /> : null}
				<Content>{this.props.modoTela == "lista" ? this.lista() : null}</Content>
			</div>
		);
	}

	getStyleSlimCard(bgColor, textColor) {
		return {
			borderRadius: 8,
			cursor: "pointer",
			backgroundColor: bgColor,
			color: textColor || "#000000",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			padding: "10px 10px 0px 10px",
			width: "100%",
			minHeight: "40px",
			cursor: "pointer",
			boxShadow: "#9b9b9b 0px 1px 2px 1px",
		};
	}

	lista() {
		let totalValorImportado = 0;
		let totalValorFatura = 0;
		let totalenergia = 0;
		let totalEnergiaPonta = 0;
		let totalEnergiaForaPonta = 0;
		let totalEnergiaReservado = 0;
		let totalDemanda = 0;
		let totalEnergiaReativo = 0;
		let totalGastoReativo = 0;
		let totalGastoDemandaComplementar = 0;
		let totalGastoDemandaUltrapassagem = 0;

		//DADOS PARA IMPRESSÃO DO RELATÓRIO ---------------
		const cabecalho = (
			<Tr>
				<Th style={{ fontSize: "0.8rem" }} sticky minWidth={50}>
					Status
				</Th>
				<Th style={{ fontSize: "0.8rem" }} sticky minWidth={90}>
					Competência
				</Th>
				<Th style={{ fontSize: "0.8rem" }} sticky minWidth={50}>
					Cliente
				</Th>
				<Th style={{ fontSize: "0.8rem" }} sticky minWidth={50}>
					UC
				</Th>
				<Th style={{ fontSize: "0.7rem" }} sticky minWidth={50} alignCenter>
					Total Fatura
				</Th>
				<Th style={{ fontSize: "0.7rem" }} sticky minWidth={50} alignCenter>
					Energia (kWh)
				</Th>
				<Th style={{ fontSize: "0.7rem" }} sticky minWidth={50} alignCenter>
					E. Ponta (kWh)
				</Th>
				<Th style={{ fontSize: "0.7rem" }} sticky minWidth={70} alignCenter>
					E. Fora Ponta (kWh)
				</Th>
				<Th style={{ fontSize: "0.7rem" }} sticky minWidth={80} alignCenter>
					E. Reservado (kWh)
				</Th>
				<Th style={{ fontSize: "0.7rem" }} sticky minWidth={50} alignCenter>
					Demanda (kW)
				</Th>
				<Th style={{ fontSize: "0.7rem" }} sticky minWidth={70} alignCenter backgroundColor="#AAAAAA">
					ICMS Esperado (%)
				</Th>
				<Th style={{ fontSize: "0.7rem" }} sticky minWidth={70} alignCenter backgroundColor="#AAAAAA">
					ICMS Importado (%)
				</Th>
				<Th style={{ fontSize: "0.7rem" }} sticky minWidth={70} alignCenter>
					Multa Reativo (R$)
				</Th>
				<Th style={{ fontSize: "0.7rem" }} sticky minWidth={105} alignCenter>
					Multa Demanda Complementar (R$)
				</Th>
				<Th style={{ fontSize: "0.7rem" }} sticky minWidth={110} alignCenter>
					Multa Demanda Ultrapassagem (R$)
				</Th>
				<Th style={{ fontSize: "0.7rem" }} sticky minWidth={110} alignCenter>
					Multa Total (R$)
				</Th>
				<Th style={{ fontSize: "0.7rem" }} sticky minWidth={50} alignCenter>
					PDF
				</Th>
				<Th style={{ fontSize: "0.8rem" }} sticky minWidth={50}></Th>
			</Tr>
		);
		const totalCelulas = cabecalho.props.children.length;
		const cabecalhoSemColunasBotoes = (
			<Tr>
				{Array.from(cabecalho.props.children)
					.splice(0, totalCelulas - 2)
					.map((el) => {
						const copia = React.cloneElement(el, { sticky: false, style: { fontSize: "0.8rem", paddingTop: 30 } }, [...el.props.children]);
						return copia;
					})}
			</Tr>
		);

		let rodape = null;
		const linhas = [];
		//-------------------------------------------------
		return (
			<>
				<ContentCard>
					<ContentCardBody>
						<Row>
							{this.props.usuarioLogado && !this.props.usuarioLogado.acesso_cliente ? (
								<Select
									name="id_cliente"
									options={this.props.listaCliente}
									label="Cliente"
									cols="12 6 4"
									placeholder="Selecione o Cliente"
									value={this.props.filtro.id_cliente}
									onChange={(value) => {
										this.props.setFiltro({
											...this.props.filtro,
											id_cliente: value,
										});
										this.props.getLista();
									}}
								/>
							) : null}

							<Select
								name="id_unidade_consumidora"
								options={this.props.listaUnidadeConsumidora.filter((item) =>
									this.props.filtro.id_cliente ? item.id_cliente == this.props.filtro.id_cliente : true
								)}
								label="Unidade Consumidora"
								cols="12 6 4"
								placeholder="Selecione a UC"
								value={this.props.filtro.id_unidade_consumidora}
								onChange={(value) => {
									this.props.setFiltro({
										...this.props.filtro,
										id_unidade_consumidora: value,
									});
									this.props.getLista();
								}}
							/>

							<Select
								name="ano"
								options={Array.from({ length: 20 }, (v, i) => ({
									id: new Date().getFullYear() - 5 + i,
									valor: new Date().getFullYear() - 5 + i,
								}))}
								label="Ano"
								cols="12 6 2"
								placeholder="Selecione o ano"
								value={this.props.filtro.ano}
								onChange={(value) => {
									this.props.setFiltro({
										...this.props.filtro,
										ano: value,
									});
									this.props.getLista();
								}}
							/>

							<Select
								name="mes"
								options={this.props.listaMeses}
								label="Mês"
								cols="12 6 2"
								placeholder="Selecione o mês"
								value={this.props.filtro.mes}
								onChange={(value) => {
									this.props.setFiltro({
										...this.props.filtro,
										mes: value,
									});
									this.props.getLista();
								}}
							/>
						</Row>
					</ContentCardBody>
				</ContentCard>

				<ContentCard>
					<ContentCardHeader>
						<Row>
							<Grid cols="12 3 3 3">
								<Button
									text="Imprimir Relatório"
									style={{ height: 45 }}
									type={"primary"}
									event={async () => {
										await this.gerarRelatorio({ cabecalhoSemColunasBotoes, rodape, linhas });
									}}
								/>
							</Grid>
							<Grid
								cols="12 4 4 4"
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<label style={this.getStyleSlimCard("#f56969", "#ffffff")} htmlFor="filtro-apenas-icms-divergente">
									<LabelAndCheckbox
										style={{ justifyContent: "center" }}
										readOnly={this.props.aguardando}
										divMarginBottom={0}
										id="filtro-apenas-icms-divergente"
										label="Apenas ICMS Divergente"
										onChange={(e) =>
											this.setState({
												...this.state,
												apenasIcmsDivergente: !this.state.apenasIcmsDivergente,
											})
										}
										value={!!this.state.apenasIcmsDivergente}
									/>
								</label>
							</Grid>
						</Row>
					</ContentCardHeader>
					<ContentCardBody>
						<Table responsive>
							<THead>{cabecalho}</THead>
							<TBody>
								{this.props.lista
									.filter((item) =>
										this.state.apenasIcmsDivergente ? parseFloat(item.icms_importado) && item.icms_esperado != item.icms_importado : item
									)
									.map((item, i, array) => {
										totalValorImportado += parseFloat(item.total > 0 ? item.total : 0);
										totalValorFatura += parseFloat(item.valor_fatura > 0 ? item.valor_fatura : 0);
										totalenergia += parseFloat(item.energia || 0);
										totalEnergiaPonta += parseFloat(item.energia_ponta || 0);
										totalEnergiaForaPonta += parseFloat(item.energia_fora_ponta || 0);
										totalEnergiaReservado += parseFloat(item.energia_reservado || 0);
										totalDemanda += parseFloat(item.demanda || 0);
										totalEnergiaReativo += parseFloat(item.energia_reativo || 0);
										totalGastoReativo += parseFloat(item.gasto_reativo || 0);
										totalGastoDemandaComplementar += parseFloat(item.gasto_demanda_complementar || 0);
										totalGastoDemandaUltrapassagem += parseFloat(item.gasto_demanda_ultrapassagem || 0);
										let mensagemTooltip = "";
										let corFonte = "#000000";

										let corFundoEscuroIcms = parseFloat(item.icms_importado) && item.icms_esperado != item.icms_importado ? "#d23a3a" : "#DDDDDD";
										let corFundo = parseFloat(item.icms_importado) && item.icms_esperado != item.icms_importado ? "#f56969" : null;
										const msgErroIcms = item.icms_esperado != item.icms_importado ? "Verifique ICMS" : "ICMS Importado";
										const linha = (
											<NewTr
												title={mensagemTooltip}
												key={item.id}
												style={{
													height: 40,
													filter: (isHovered) => (isHovered ? "brightness(0.95)" : ""),
													backgroundColor: corFundo,
													color: corFonte,
												}}
												celulas={
													<>
														<Td
															style={{
																fontSize: "0.8rem",
																backgroundColor: corFundo,
																color: corFonte,
															}}
														>
															{msgErroIcms}
														</Td>
														<Td style={{ fontSize: "0.8rem" }}>{`${String(item.competencia).substring(4, 6)}/${String(item.competencia).substring(
															0,
															4
														)}`}</Td>
														<Td style={{ fontSize: "0.8rem" }} minWidth={100}>
															{item.nome_cliente}
														</Td>
														<Td style={{ fontSize: "0.8rem" }} minWidth={150}>
															{item.nome_unidade_consumidora}
														</Td>
														<Td style={{ fontSize: "0.8rem" }} alignCenter>
															{parseFloat(item.valor_fatura || 0)
																.toFixed(2)
																.replace(".", ",")}
														</Td>
														<Td style={{ fontSize: "0.8rem" }} alignCenter>
															{parseFloat(item.energia || 0)
																.toFixed(2)
																.replace(".", ",")}
														</Td>
														<Td style={{ fontSize: "0.8rem" }} alignCenter>
															{parseFloat(item.energia_ponta || 0)
																.toFixed(2)
																.replace(".", ",")}
														</Td>
														<Td style={{ fontSize: "0.8rem" }} alignCenter>
															{parseFloat(item.energia_fora_ponta || 0)
																.toFixed(2)
																.replace(".", ",")}
														</Td>
														<Td style={{ fontSize: "0.8rem" }} alignCenter>
															{parseFloat(item.energia_reservado || 0)
																.toFixed(2)
																.replace(".", ",")}
														</Td>
														<Td style={{ fontSize: "0.8rem" }} alignCenter>
															{parseFloat(item.demanda || 0)
																.toFixed(2)
																.replace(".", ",")}
														</Td>
														<Td style={{ fontSize: "0.8rem" }} alignCenter backgroundColor={corFundoEscuroIcms}>
															{item.icms_esperado ? parseFloat(item.icms_esperado).toFixed(2).replace(".", ",") : null}
														</Td>
														<Td style={{ fontSize: "0.8rem" }} alignCenter backgroundColor={corFundoEscuroIcms}>
															{item.icms_importado ? parseFloat(item.icms_importado).toFixed(2).replace(".", ",") : null}
														</Td>
														<Td style={{ fontSize: "0.8rem" }} alignCenter>
															{parseFloat(item.gasto_reativo || 0)
																.toFixed(2)
																.replace(".", ",")}
														</Td>
														<Td style={{ fontSize: "0.8rem" }} alignCenter>
															{parseFloat(item.gasto_demanda_complementar || 0)
																.toFixed(2)
																.replace(".", ",")}
														</Td>
														<Td style={{ fontSize: "0.8rem" }} alignCenter>
															{parseFloat(item.gasto_demanda_ultrapassagem || 0)
																.toFixed(2)
																.replace(".", ",")}
														</Td>
														<Td style={{ fontSize: "0.8rem" }} alignCenter>
															{(
																parseFloat(item.gasto_reativo || 0) +
																parseFloat(item.gasto_demanda_complementar || 0) +
																parseFloat(item.gasto_demanda_ultrapassagem || 0)
															)
																.toFixed(2)
																.replace(".", ",")}
														</Td>
														<Td style={{ fontSize: "0.8rem" }} alignCenter minWidth={50}>
															{item.arquivo_pdf ? (
																<ButtonTable
																	type={"primary"}
																	icon={"fas fa-file-pdf"}
																	visible={true}
																	event={() => {
																		this.props.buscaPdf(
																			item.id,
																			`UC-${item.nome_unidade_consumidora.split("-")[0].trim()}_${String(item.competencia).substring(4, 6)}-${String(
																				item.competencia
																			).substring(0, 4)}`
																		);
																		this.props.setArquivoPDF();
																	}}
																/>
															) : null}
														</Td>
													</>
												}
												botoes={[]}
											></NewTr>
										);

										const totalCelulas = linha.props.celulas.props.children.length;
										let linhaModificada = Array.from(linha.props.celulas.props.children).splice(0, totalCelulas - 1);
										linhaModificada = React.Children.map(linhaModificada, (child, index) => {
											// 0 = Msg Erro Importação
											if (index == 0) {
												return React.cloneElement(child, {
													children: <div>{msgErroIcms.length > 32 ? msgErroIcms.substring(0, 32) + "..." : msgErroIcms}</div>,
												});
											}

											// 2 = cliente
											if (index == 2) {
												return React.cloneElement(child, {
													children: <div>{item.nome_cliente.length > 30 ? item.nome_cliente.substring(0, 30) + "..." : item.nome_cliente}</div>,
												});
											}

											// 3 = UC
											if (index == 3) {
												const nome_unidade_consumidora = item.nome_unidade_consumidora;
												return React.cloneElement(child, {
													children: (
														<div>
															{nome_unidade_consumidora.length > 40 ? `${nome_unidade_consumidora.substring(0, 40)}...` : nome_unidade_consumidora}
														</div>
													),
												});
											}
											return child;
										});

										const linhaSemColunaBotoes = React.cloneElement(linha, {
											celulas: linhaModificada,
											botoes: [],
										});

										linhas.push(linhaSemColunaBotoes);
										//ao chegar na última iteração constrói o rodapé
										if (i === array.length - 1) {
											rodape = this.renderizarRodape({
												totalValorImportado,
												totalValorFatura,
												totalenergia,
												totalEnergiaPonta,
												totalEnergiaForaPonta,
												totalEnergiaReservado,
												totalDemanda,
												totalEnergiaReativo,
												totalGastoReativo,
												totalGastoDemandaComplementar,
												totalGastoDemandaUltrapassagem,
											});
										}

										return linha;
									})}
								{rodape}
							</TBody>
						</Table>
					</ContentCardBody>
				</ContentCard>
			</>
		);
	}

	async gerarRelatorio({ cabecalhoSemColunasBotoes, rodape, linhas }) {
		this.props.setAguardando(true);
		const filtro = (
			<b style={{ fontSize: "0.8rem" }}>
				Cliente:
				{" " + ((this.props.listaCliente.find((cliente) => cliente.id == this.props.filtro.id_cliente) || {}).valor || "Todos") + " - "}
				UC:
				{" " + ((this.props.listaUnidadeConsumidora.find((uc) => uc.id == this.props.filtro.id_unidade_consumidora) || {}).valor || "Todas") + " - "}
				Ano:{" " + (this.props.filtro.ano || "") + " - "}
				Mês:
				{` ${(this.props.listaMeses.find((mes) => mes.id == this.props.filtro.mes) || {}).valor || "Todos"} - `}
				Filtro por Status: {this.state.apenasIcmsDivergente ? "Verificar ICMS" : "Todos"}
			</b>
		);
		const pdfBase64 = await htmlToPdf(
			<TabelaFaturaVerificadorIcmsPDF
				dadosImpressao={{
					filtro,
					cabecalhoSemColunasBotoes,
					rodape,
					linhas,
				}}
			/>,
			this.props.setAguardando
		);
		this.props.setArquivoPDF(pdfBase64, "Relatório verificador de ICMS.pdf", "landscape");
	}

	renderizarRodape({
		totalValorImportado,
		totalValorFatura,
		totalenergia,
		totalEnergiaPonta,
		totalEnergiaForaPonta,
		totalEnergiaReservado,
		totalDemanda,
		totalEnergiaReativo,
		totalGastoReativo,
		totalGastoDemandaComplementar,
		totalGastoDemandaUltrapassagem,
	}) {
		const rodape = (
			<Tr>
				<Td style={{ fontSize: "0.8rem" }} colSpan={4} fontWeight="bold">
					Total
				</Td>
				<Td style={{ fontSize: "0.8rem" }} alignCenter fontWeight="bold">
					{parseFloat(totalValorFatura || 0)
						.toFixed(0)
						.replace(".", ",")}
				</Td>
				<Td style={{ fontSize: "0.8rem" }} alignCenter fontWeight="bold">
					{parseFloat(totalenergia || 0)
						.toFixed(0)
						.replace(".", ",")}
				</Td>
				<Td style={{ fontSize: "0.8rem" }} alignCenter fontWeight="bold">
					{parseFloat(totalEnergiaPonta || 0)
						.toFixed(0)
						.replace(".", ",")}
				</Td>
				<Td style={{ fontSize: "0.8rem" }} alignCenter fontWeight="bold">
					{parseFloat(totalEnergiaForaPonta || 0)
						.toFixed(0)
						.replace(".", ",")}
				</Td>
				<Td style={{ fontSize: "0.8rem" }} alignCenter fontWeight="bold">
					{parseFloat(totalEnergiaReservado || 0)
						.toFixed(0)
						.replace(".", ",")}
				</Td>
				<Td style={{ fontSize: "0.8rem" }} alignCenter fontWeight="bold">
					{parseFloat(totalDemanda || 0)
						.toFixed(0)
						.replace(".", ",")}
				</Td>
				<Td style={{ fontSize: "0.8rem" }} alignCenter fontWeight="bold">
					{/*rodapé do icms esperado*/}
				</Td>
				<Td style={{ fontSize: "0.8rem" }} alignCenter fontWeight="bold">
					{/*rodapé do icms importado*/}
				</Td>
				<Td style={{ fontSize: "0.8rem" }} alignCenter fontWeight="bold">
					{parseFloat(totalGastoReativo || 0)
						.toFixed(0)
						.replace(".", ",")}
				</Td>
				<Td style={{ fontSize: "0.8rem" }} alignCenter fontWeight="bold">
					{parseFloat(totalGastoDemandaComplementar || 0)
						.toFixed(0)
						.replace(".", ",")}
				</Td>
				<Td style={{ fontSize: "0.8rem" }} alignCenter fontWeight="bold">
					{parseFloat(totalGastoDemandaUltrapassagem || 0)
						.toFixed(0)
						.replace(".", ",")}
				</Td>
				<Td style={{ fontSize: "0.8rem" }} alignCenter fontWeight="bold">
					{parseFloat(totalGastoReativo + totalGastoDemandaComplementar + totalGastoDemandaUltrapassagem)
						.toFixed(0)
						.replace(".", ",")}
				</Td>
				<Td></Td>
				<Td></Td>
			</Tr>
		);
		return rodape;
	}
}

const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	modoTela: state.fatura.modoTela,
	aguardando: state.fatura.aguardando,
	filtro: state.fatura.filtro,
	lista: state.fatura.lista,
	listaCliente: state.fatura.listaCliente,
	listaUnidadeConsumidora: state.fatura.listaUnidadeConsumidora,
	listaMeses: state.fatura.listaMeses,
	pdf: state.displayPDF.pdf,
	nomePdf: state.displayPDF.nomePdf,
	orientation: state.displayPDF.orientation,
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setModoTela,
			initForm,
			setFiltro,
			salvar,
			salvarMultiplos,
			excluir,
			getLista,
			getListaCliente,
			getListaUnidadeConsumidora,
			reprocessar,
			reprocessarTodas,
			buscaPdf,
			setArquivoPDF,
			setAguardando,
		},
		dispatch
	);
export default connect(mapStateToProps, mapDispatchToProps)(FaturaEngenharia);
