import React, { useLayoutEffect, useState, forwardRef } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import imagemCabecalho from "../assets/images/cabecalho_relatorio.png";
import imagemRodape from "../assets/images/rodape_relatorio.png";
import { montarGraficoColumn } from "../common/graficos/montarGraficoColumn.js";
import { montarGraficoGroupColumn } from "../common/graficos/montarGraficoGroupColumn.js";
import { montarGraficoPizza } from "../common/graficos/montarGraficoPizza.js";
import { adicionarPontosMilhar } from "../utils/adicionarPontosMilhar.js";
import { setError } from "../common/toast/toast.jsx";
import { montarGraficoColumnLine } from "../common/graficos/montarGraficoColumnLine.js";
const COR_PRINCIPAL = "#5599EE";
const COR_SECUNDARIA = "#FFDD22";
const COR_TERCIARIA = "#FF7722";
const COR_QUATERNARIA = "#55DD77";

const RelatorioSafraPDF = forwardRef((props, ref) => {
	{
		const [graficoHistoricoCustoSafra, setGraficoHistoricoCustoSafra] = useState(null);
		const [graficoComparativoEntreSafrasMensal, setGraficoComparativoEntreSafrasMensal] = useState(null);
		const [graficoHistoricosIndicesMultas, setGraficoHistoricosIndicesMultas] = useState(null);
		const [graficoDiagnosticoEnergeticoTiposMultas, setGraficoDiagnosticoEnergeticoTiposMultas] = useState(null);
		const [graficoDevolucoesSafra, setGraficoDevolucoesSafra] = useState(null);
		const [graficoDevolucoesTipo, setGraficoDevolucoesTipo] = useState(null);

		useLayoutEffect(() => {
			montarTodosGraficos(props);
			props.setAguardando(true);
			setTimeout(props.renderizarRelatorio, 5000);
			return desmontarTodosGraficos;
		}, []);

		function montarTodosGraficos(props) {
			const {
				listaGraficoCustoTotalPorSafra,
				listaGraficoComparativoCustoMensalEntreSafras,
				listaGraficoDiagnosticoEnergeticoTiposMultas,
				listaGraficoHistoricoIndicesMultasPorSafra,
				listaGraficoTotalDevolucoesPorSafra,
				listaGraficoDevolucoesRessarcimentosSafra,
			} = props.dadosImpressao;
			montarGraficoHistoricoCustoSafra(listaGraficoCustoTotalPorSafra);
			montarGraficoComparativoEntreSafrasMensal(listaGraficoComparativoCustoMensalEntreSafras);
			montarGraficoDiagnosticoEnergeticoTiposMultas(listaGraficoDiagnosticoEnergeticoTiposMultas);
			montarGraficoHistoricosIndicesMultas(listaGraficoHistoricoIndicesMultasPorSafra);
			montarGraficoDevolucoesSafra(listaGraficoTotalDevolucoesPorSafra);
			montarGraficoDevolucoesTipo(listaGraficoDevolucoesRessarcimentosSafra);
		}
		function desmontarTodosGraficos() {
			if (graficoHistoricoCustoSafra) graficoHistoricoCustoSafra.dispose();
			if (graficoComparativoEntreSafrasMensal) graficoComparativoEntreSafrasMensal.dispose();
			if (graficoHistoricosIndicesMultas) graficoHistoricosIndicesMultas.dispose();
			if (graficoDiagnosticoEnergeticoTiposMultas) graficoDiagnosticoEnergeticoTiposMultas.dispose();
			if (graficoDevolucoesSafra) graficoDevolucoesSafra.dispose();
			if (graficoDevolucoesTipo) graficoDevolucoesTipo.dispose();
		}

		function montarGraficoHistoricoCustoSafra(listaGraficoCustoTotalPorSafra) {
			const graficoHistoricoCustoSafraMontado = montarGraficoColumn({
				state: { cores: [am4core.color(COR_QUATERNARIA)] },
				component: graficoHistoricoCustoSafra,
				nome: "grafico-historico-custo-safra-colunas",
				dados: listaGraficoCustoTotalPorSafra,
				category: "ano_inicio_fim_safra",
				value: "valor",
				name: "Custo Geral",
				titulo: "Histórico Custo Safra (R$)",
				prefixo: "R$",
				sufixo: "",
				fontColor: "#000000",
				positionBullet: -15,
				dimensions: "2D",
				fontSizeTitle: 30,
				fontWeightTitle: "bold",
				mostrarLegenda: true,
				chartBackground: "#ffffff",
			});
			setGraficoHistoricoCustoSafra(graficoHistoricoCustoSafraMontado);
		}

		function montarListagemCustoAcumuladoPorUC(listaGraficoCustoAcumuladoPorUnidadeAnoSafra) {
			return (
				<table>
					<tbody>
						{listaGraficoCustoAcumuladoPorUnidadeAnoSafra.map((item) => (
							<React.Fragment key={item.id}>
								<tr>
									<td rowSpan={2}>
										<div
											style={{
												display: "flex",
												justifyContent: "left",
												alignItems: "center",
												width: 140,
												height: 35,
												color: "#FFF",
												backgroundColor: COR_PRINCIPAL,
											}}
										>
											<b style={{ margin: 0, paddingLeft: 50 }}>{`${item.percentual}%`}</b>
										</div>
									</td>
									<td>
										<div style={{ display: "flex", height: 25 }}>
											<div
												style={{
													textAlign: "left",
													whiteSpace: "nowrap",
													overflow: "hidden",
													textOverflow: "ellipsis",
													width: 820,
												}}
											>
												{item.nome_unidade_consumidora}
											</div>
											<div style={{ textAlign: "right", width: 240 }}>
												<b style={{ margin: 0, paddingLeft: 50 }}>{"R$ " + adicionarPontosMilhar(item.valor)}</b>
											</div>
										</div>
									</td>
								</tr>
								<tr>
									<td colSpan={2}>
										<div style={{ width: 1060, height: 10, marginTop: -5, border: "1px solid #444" }}>
											<div style={{ width: `${item.percentual}%`, height: 10, backgroundColor: COR_PRINCIPAL }}></div>
										</div>
									</td>
								</tr>
							</React.Fragment>
						))}
					</tbody>
				</table>
			);
		}
		function montarGraficoComparativoEntreSafrasMensal(listaGraficoComparativoCustoMensalEntreSafras) {
			let anoInicioFimSafraAnterior = "";
			let anoInicioFimSafraAtual = "";
			let listaMapeada = [];
			try {
				//pega o periodo de cada safra
				anoInicioFimSafraAnterior = listaGraficoComparativoCustoMensalEntreSafras[0].safra_anterior.ano_inicio_fim_safra;
				anoInicioFimSafraAtual = listaGraficoComparativoCustoMensalEntreSafras[0].safra_atual.ano_inicio_fim_safra;
				listaMapeada = listaGraficoComparativoCustoMensalEntreSafras.map((item) => {
					return {
						mes: item.safra_atual.mes,
						safra_anterior: item.safra_anterior.valor,
						safra_atual: item.safra_atual.valor,
					};
				});
			} catch (erro) {
				return setError("Não foi possível montar o gráfico comparativo entre safras");
			}

			const graficoComparativoEntreSafrasMensalMontado = montarGraficoGroupColumn({
				state: { cores: [am4core.color(COR_SECUNDARIA), am4core.color(COR_PRINCIPAL)] },
				component: graficoComparativoEntreSafrasMensal,
				nome: "grafico-comparativo-entre-safras-2colunas",
				dados: listaMapeada,
				category: "mes",
				valuesAndNames: [
					{ value: "safra_anterior", name: anoInicioFimSafraAnterior },
					{ value: "safra_atual", name: anoInicioFimSafraAtual },
				],
				titulo: "Comparativo entre Safras - Custos Mensais (R$)",
				prefixo: "R$",
				sufixo: "",
				fontColor: "#000000",
				positionBullet: -15,
				dimensions: "2D",
				fontSizeTitle: 30,
				fontWeightTitle: "bold",
				mostrarLegenda: true,
				chartBackground: "#ffffff",
			});
			setGraficoComparativoEntreSafrasMensal(graficoComparativoEntreSafrasMensalMontado);
		}

		function montarGraficoHistoricosIndicesMultas(listaGraficoHistoricoIndicesMultasPorSafra) {
			const graficoHistoricosIndicesMultasMontado = montarGraficoColumnLine({
				state: { cores: [am4core.color(COR_PRINCIPAL)] },
				component: graficoHistoricosIndicesMultas,
				nome: "grafico-multas-linha",
				dados: listaGraficoHistoricoIndicesMultasPorSafra,
				category: "ano_inicio_fim_safra",
				valueLine: "indice_total_linha",
				valueColumn: "indice_total_coluna",
				nameLine: "Índice de Multas",
				nameColumn: "Pior Índice de Multas",
				titulo: "Histórico de Índices de Multas em Unidades Consumidoras (%)",
				prefixo: "",
				sufixo: "%",
				fontColor: "#000000",
				positionBullet: -15,
				dimensions: "2D",
				fontSizeTitle: 30,
				fontWeightTitle: "bold",
				mostrarLegenda: true,
				chartBackground: "#ffffff",
			});
			setGraficoHistoricosIndicesMultas(graficoHistoricosIndicesMultasMontado);
		}
		function montarListagemTotalMultasPorUcSafra({ listaGraficoHistoricoIndicesMultasPorSafra, listaGraficoTotalMultasPorUcSafra }) {
			const listaAnoInicioFimSafra = listaGraficoHistoricoIndicesMultasPorSafra.map((item) => ({
				ano_inicio_fim_safra: item.ano_inicio_fim_safra,
				indice_total_coluna: item.indice_total_coluna,
			}));
			const quantidadeUCs = listaGraficoTotalMultasPorUcSafra.length;
			return (
                <table style={{ border: "2px solid #222", width: 1210 }}>
                    <thead>
                        <tr>
                            <th
                                colSpan={quantidadeUCs > 5 ? 2 : 1}
                                style={{ paddingLeft: 10, fontWeight: "bold", fontSize: 16 }}
                            >
                                Unidade Consumidora
                            </th>

                            {listaAnoInicioFimSafra.map((item) => (
                                <th
                                    key={item.ano_inicio_fim_safra}
                                    style={{
                                        fontWeight: "bold",
                                        width: 175,
                                        paddingLeft: 30,
                                        fontSize: 16,
                                        backgroundColor: item.indice_total_coluna != null ? "#ffc3cf" : null,
                                    }}
                                >
                                    {item.indice_total_coluna ? (
                                        <>
                                            <div>({item.ano_inicio_fim_safra})</div>
                                            <div>Referência de</div>
                                            <div>Pior Índice</div>
                                        </>
                                    ) : (
                                        item.ano_inicio_fim_safra
                                    )}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td
                                colSpan={quantidadeUCs > 5 ? 2 : 1}
                                style={{ paddingLeft: 10, backgroundColor: "#333", color: "#fff" }}
                            >
                                Índice Total Anual (%)
                            </td>
                            {listaGraficoHistoricoIndicesMultasPorSafra.map((item) => (
                                <td
                                    key={item.ano_inicio_fim_safra}
                                    style={{
                                        paddingLeft: 30,
                                        backgroundColor: item.indice_total_coluna != null ? "#ffc3cf" : null,
                                    }}
                                >
                                    {adicionarPontosMilhar(item.indice_total)}%
                                </td>
                            ))}
                        </tr>

                        <tr>
                            <td
                                colSpan={quantidadeUCs > 5 ? 2 : 1}
                                style={{ paddingLeft: 10, backgroundColor: "#333", color: "#fff" }}
                            >
                                Valor Total de Multas Anual (R$)
                            </td>
                            {listaGraficoHistoricoIndicesMultasPorSafra.map((item) => (
                                <td
                                    key={item.ano_inicio_fim_safra}
                                    style={{
                                        paddingLeft: 30,
                                        backgroundColor: item.indice_total_coluna != null ? "#ffc3cf" : null,
                                    }}
                                >
                                    R$ {adicionarPontosMilhar(item.total_multas)}
                                </td>
                            ))}
                        </tr>
                        <tr>
                            <td
                                colSpan={quantidadeUCs > 5 ? 2 : 1}
                                style={{ paddingLeft: 10, backgroundColor: "#333", color: "#fff" }}
                            >
                                Valor Total de Faturas de Energia Anual (R$)
                            </td>
                            {listaGraficoHistoricoIndicesMultasPorSafra.map((item) => (
                                <td
                                    key={item.ano_inicio_fim_safra}
                                    style={{
                                        paddingLeft: 30,
                                        backgroundColor: item.indice_total_coluna != null ? "#ffc3cf" : null,
                                    }}
                                >
                                    R$ {adicionarPontosMilhar(item.total_faturas)}
                                </td>
                            ))}
                        </tr>
                        {listaGraficoTotalMultasPorUcSafra.slice(0, 5).map((item, i) => (
                            <tr key={item.id_unidade_consumidora}>
                                {i == 0 && quantidadeUCs > 5 ? (
                                    <td
                                        rowSpan={listaGraficoTotalMultasPorUcSafra.length}
                                        style={{ backgroundColor: "#FE8" }}
                                    >
                                        <div
                                            style={{
                                                transform: "rotate(-90deg)",
                                                minWidth: "fit-content",
                                                textWrap: "nowrap",
                                                color: "#F07",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Top 5
                                        </div>
                                    </td>
                                ) : null}
                                <td
                                    style={{
                                        backgroundColor: `#55${4 + i}9EE`,
                                        color: "#fff",
                                        width: 500,
                                        paddingLeft: 10,
                                    }}
                                >
                                    {String(item.nome_unidade_consumidora).length < 35
                                        ? item.nome_unidade_consumidora + " (R$)"
                                        : String(item.nome_unidade_consumidora).substring(0, 30) + "... (R$)"}
                                </td>
                                {listaAnoInicioFimSafra.map((safra, indice) => (
                                    <td
                                        key={indice}
                                        style={{
                                            paddingLeft: 30,
                                            backgroundColor: safra.indice_total_coluna != null ? "#ffc3cf" : null,
                                        }}
                                    >
                                        {item.valores[indice]
                                            ? `R$ ${adicionarPontosMilhar(item.valores[indice].total_multas)}`
                                            : " - "}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
		}

		function montarGraficoDiagnosticoEnergeticoTiposMultas(listaGraficoDiagnosticoEnergeticoTiposMultas) {
			const graficoDiagnosticoEnergeticoTiposMultasMontado = montarGraficoPizza({
				state: { cores: [am4core.color(COR_PRINCIPAL), am4core.color(COR_SECUNDARIA), am4core.color(COR_TERCIARIA)] },
				component: graficoDiagnosticoEnergeticoTiposMultas,
				nome: "grafico-multas-pizza",
				dados: listaGraficoDiagnosticoEnergeticoTiposMultas,
				category: "tipo",
				value: "valor",
				titulo: "Diagnóstico Energético de Tipos de Multas no Último Ano Safra (%)",
				prefixo: "R$",
				sufixo: "",
				fontSizeTitle: 30,
				fontWeightTitle: "bold",
				chartBackground: "#ffffff",
			});
			setGraficoDiagnosticoEnergeticoTiposMultas(graficoDiagnosticoEnergeticoTiposMultasMontado);
		}

		function montarGraficoDevolucoesSafra(listaGraficoTotalDevolucoesPorSafra) {
			const graficoDevolucoesSafraMontado = montarGraficoColumn({
				state: { cores: [am4core.color(COR_PRINCIPAL)] },
				component: graficoDevolucoesSafra,
				nome: "grafico-devolucoes-colunas",
				dados: listaGraficoTotalDevolucoesPorSafra,
				category: "ano_inicio_fim_safra",
				value: "valor",
				name: "Custo Geral",
				titulo: "",
				prefixo: "R$",
				sufixo: "",
				fontColor: "#000000",
				positionBullet: -15,
				dimensions: "2D",
				fontSizeTitle: 30,
				fontWeightTitle: "bold",
				mostrarLegenda: true,
				chartBackground: "#ffffff",
			});
			setGraficoDevolucoesSafra(graficoDevolucoesSafraMontado);
		}

		function montarGraficoDevolucoesTipo(listaGraficoDevolucoesRessarcimentosSafra) {
			const graficoDevolucoesTipoMontado = montarGraficoPizza({
				state: { cores: [am4core.color(COR_PRINCIPAL), am4core.color(COR_SECUNDARIA)] },
				component: graficoDevolucoesTipo,
				nome: "grafico-devolucoes-pizza",
				dados: listaGraficoDevolucoesRessarcimentosSafra,
				category: "tipo",
				value: "valor",
				titulo: "",
				prefixo: "R$",
				sufixo: "",
				fontSizeTitle: 30,
				fontWeightTitle: "bold",
				chartBackground: "#ffffff",
			});
			setGraficoDevolucoesTipo(graficoDevolucoesTipoMontado);
		}

		return (
			<div
				ref={ref}
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					width: 2560,
					paddingInline: 40,
					paddingBlock: 80,
					backgroundColor: "#fff",
				}}
			>
				<img src={imagemCabecalho} width="2476px" style={{ border: "2px solid #222" }}></img>
				<div style={{ display: "flex", flexWrap: "nowrap", width: 2476, color: "#fff", marginTop: -60, fontSize: "40px" }}>
					<div style={{ width: "80%" }}>
						<b style={{ margin: 0, paddingLeft: 20 }}>{props.dadosImpressao.nomeClienteRelatorio}</b>
					</div>
					<div style={{ width: "20%", display: "flex", justifyContent: "flex-end", paddingRight: 20, margin: -10 }}>
						<b>{props.dadosImpressao.competenciaRelatorio}</b>
					</div>
				</div>

				<div style={{ display: "flex", justifyContent: "center", borderInline: "2px solid #222", width: 2476, height: 750 }}>
					<div style={{ borderRight: "2px solid #222", width: 1238, height: 750, overflow: "hidden" }}>
						<div id="grafico-historico-custo-safra-colunas" style={{ width: 1240, height: 750 }}></div>
					</div>
					<div style={{ width: "50%", height: 750, textAlign: "center" }}>
						<div>
							<b style={{ fontSize: "30px", margin: 0, textAlign: "center" }}>Acumulado por Unidade Consumidora (R$)</b>
						</div>
						<div>
							<b style={{ fontSize: "25px", margin: "-10px 0px 0px 0px" }}>
								Ano Safra: {(props.dadosImpressao.listaGraficoCustoAcumuladoPorUnidadeAnoSafra[0] || {}).ano_inicio_fim_safra}
							</b>
						</div>
						{(props.dadosImpressao.listaGraficoCustoAcumuladoPorUnidadeAnoSafra || []).length > 15 ? (
							<b style={{ fontSize: "20px", margin: "-5px 0px 0px 0px", color: "#F35" }}>
								**Ilustrado as 15 primeiras unidades consumidoras que representam os maiores valores do período.
							</b>
						) : null}
						{montarListagemCustoAcumuladoPorUC(props.dadosImpressao.listaGraficoCustoAcumuladoPorUnidadeAnoSafra.slice(0, 15))}
					</div>
				</div>
				<div style={{ display: "flex", justifyContent: "center", border: "2px solid #222", width: 2476, height: 696, overflow: "hidden" }}>
					<div id="grafico-comparativo-entre-safras-2colunas" style={{ width: 2470, height: 700 }}></div>
				</div>

				<div style={{ display: "flex", justifyContent: "center", borderInline: "2px solid #222", width: 2476, height: 750 }}>
					<div style={{ borderRight: "2px solid #222", width: 1238, height: 750, overflow: "hidden" }}>
						<div id="grafico-multas-linha" style={{ width: 1240, height: 400 }}></div>
						<div style={{ padding: 10, width: 1210, height: 350 }}>{montarListagemTotalMultasPorUcSafra(props.dadosImpressao)}</div>
					</div>
					<div style={{ width: 1240, height: 750, overflow: "hidden" }}>
						<div id="grafico-multas-pizza" style={{ width: 1240, height: 750 }}></div>
					</div>
				</div>
				<div
					style={{
						display: "flex",
						borderTop: "2px solid #222",
						borderInline: "2px solid #222",
						width: 2476,
						height: 108,
					}}
				>
					<div style={{ width: "100%", height: 108, textAlign: "center" }}>
						<b style={{ fontSize: "30px", margin: "10px 0 0 0", textAlign: "center" }}>
							<div>Devoluções Financeiras da Concessionaria para o Cliente</div>
							<div>Cobranças Indevidas e ressarcimentos gerais (R$)</div>
						</b>
					</div>
				</div>
				<div style={{ display: "flex", justifyContent: "center", borderInline: "2px solid #222", width: 2476, height: 650 }}>
					<div style={{ width: 1238, height: 650, overflow: "hidden" }}>
						<div id="grafico-devolucoes-colunas" style={{ width: 1240, height: 650 }}></div>
					</div>
					<div style={{ width: 1238, height: 650, overflow: "hidden" }}>
						<div id="grafico-devolucoes-pizza" style={{ width: 1240, height: 650 }}></div>
					</div>
				</div>

				<img src={imagemRodape} width="2476px" style={{ border: "2px solid #222" }}></img>
			</div>
		);
	}
});

export default RelatorioSafraPDF;
