import React, { useState, Children, cloneElement } from "react";
import "./modalForm.css";
import Button from "../button/button";
import Row from "../layout/row";
import Grid from "../layout/grid";

export function ModalForm({ handleSubmit, handleClose, titulo, children = [], item = {}, contentStyle = {} }) {
	const [state, setState] = useState(item);
	const handleChange = (target) => {
		setState((prevState) => ({ ...prevState, [target.name]: target.value }));
	};

	const handleSave = () => {
		handleSubmit(state);
	};

	return (
		<div>
			<div className="modal">
				<div className="modal-content">
					<div style={{ display: "flex", justifyContent: "flex-end" }}>
						<button className="close-button" type="button" onClick={handleClose}>
							<i className="fas fa-times"></i>
						</button>
					</div>
					<h5 style={{ textAlign: "center" }}>{titulo}</h5>
					<div style={contentStyle}>
						{Children.map(children, (child, index) => {
							return cloneElement(child, {
								key: child.props.name,
								onChange: (e) => handleChange(e),
								value: state[child.props.name] || "",
								getTarget: true,
							});
						})}
					</div>
					{handleSubmit ? (
						<Row>
							<Grid cols="6 6 6 6">
								<Button text="Salvar" type={"success"} event={handleSave} />
							</Grid>
							<Grid cols="6 6 6 6">
								<Button text="Voltar" type={"warning"} event={handleClose} />
							</Grid>
						</Row>
					) : null}
				</div>
			</div>
		</div>
	);
}
