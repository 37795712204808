import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, getFormValues } from "redux-form";

import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import ContentCardFooter from "../common/template/contentCardFooter";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import Form from "../common/form/form";
import LabelAndInput from "../common/form/labelAndInput";
import LabelAndInputNumber from "../common/form/labelAndInputNumber";
import LabelAndInputMask from "../common/form/labelAndInputMask";
import LabelAndInputDateTime from "../common/form/labelAndInputDateTime";
import Select from "../common/form/select";
import SelectAlteracao from "../common/form/selectAlteracao";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import Tr from "../common/table/tr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import ButtonTable from "../common/table/buttonTable";
import FormatUtils from "../common/formatUtils/FormatUtils";
import DateFormat from "../common/dateFormat/DateFormat";
import { setError } from "../common/toast/toast";
import arquivoAdd from "../assets/images/file_circle_plus_solid.png";
import arquivoAdicionado from "../assets/images/file_solid.png";
import { filtrarUcPorDataDesligamento } from "../utils/filtrarUcPorDataDesligamento";

import { setModoTela, initForm } from "./documentoActions";

class DocumentoForm extends Component {
	state = {};

	componentDidMount() {
		let initSeletorAnexo = () => {
			const fileSelectorAnexo = document.createElement("input");
			fileSelectorAnexo.setAttribute("type", "file");
			fileSelectorAnexo.addEventListener("change", (data) => {
				let reader = new FileReader();
				let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
				if (!file) {
					file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
				}

				if (file) {
					if (
						!file.type.includes("application/pdf") &&
						!file.type.includes("image/png") &&
						!file.type.includes("image/jpg") &&
						!file.type.includes("image/jpeg")
					) {
						setError("Selecione apenas (.PDF, .PNG, .JPG e .JPEG)");
						return;
					}

					reader.onloadend = () => {
						let lista = (this.props.formularioValues.anexos || []).map((item) => item);
						lista.push({
							anexo_base64: reader.result,
							legenda: "",
						});

						this.props.initForm({
							...this.props.formularioValues,
							anexos: lista,
						});

						initSeletorAnexo();
					};

					reader.readAsDataURL(file);
				}
			});

			this.fileSelectorAnexo = fileSelectorAnexo;
		};

		initSeletorAnexo();
	}

	render() {
		let readOnly = this.props.excluir || this.props.visualizacao ? "readOnly" : "";

		return (
			<>
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<ContentCardBody>
							<Row>
								<Field
									name="id_cliente"
									component={Select}
									//se usuario implantacao estiver logado
									//filtra para poder incluir apenas itens na UC
									//que ele é o implantador
									options={
										this.props.usuarioLogado.usuario_implantacao
											? this.props.listaCliente.filter((cliente) =>
													cliente.itens.find((uc) => uc.id_usuario_implantacao == this.props.usuarioLogado.id)
											  )
											: this.props.listaCliente
									}
									label="Cliente"
									cols="12 12 12 12"
									placeholder="Selecione o cliente"
									readOnly={this.props.formularioValues.id ? "readOnly" : ""}
								/>

								<Field
									name="id_unidade_consumidora"
									component={Select}
									//se usuario implantacao estiver logado
									//filtra para poder incluir apenas itens na UC
									//que ele é o implantador
									options={this.props.listaUnidadeConsumidora.filter((item) => {
										const implantacaoLogado = this.props.usuarioLogado.usuario_implantacao;
										const ucClienteSelecionado = item.id_cliente == this.props.formularioValues.id_cliente;
										if (implantacaoLogado) {
											const usuarioImplantacaoVinculadoUc = item.id_usuario_implantacao == this.props.usuarioLogado.id;
											return usuarioImplantacaoVinculadoUc && ucClienteSelecionado;
										}
										return ucClienteSelecionado;
									})}
									label="Unidade Consumidora"
									cols="12 12 12 12"
									placeholder="Selecione a unidade consumidora"
									readOnly={this.props.formularioValues.id ? "readOnly" : ""}
								/>

								<Field
									name="id_documento_tipo"
									component={Select}
									options={this.props.listaDocumentoTipo}
									label="Tipo"
									cols="12 12 12 12"
									placeholder="Selecione o tipo"
									readOnly={readOnly}
								/>

								<Field name="nome" component={LabelAndInput} label="Nome" placeholder="Informe o nome" cols="12 12 12 12" readOnly={readOnly} />

								<Field
									name="vigencia_inicio"
									component={LabelAndInputMask}
									label="Início de Vigência"
									placeholder="Informe a competência"
									cols="12 6 4 4"
									mask="99/9999"
									readOnly={readOnly}
								/>

								<Field
									name="vigencia_fim"
									component={LabelAndInputMask}
									label="Fim de Vigência"
									placeholder="Informe a competência"
									cols="12 6 4 4"
									mask="99/9999"
									readOnly={readOnly}
								/>

								<Field
									name="observacao"
									component={LabelAndInput}
									label="Observação"
									placeholder="Informe a observação"
									cols="12 12 12 12"
									readOnly={readOnly}
								/>
							</Row>
							<Row>
								<Grid cols="12 12 12 12">
									<label>Anexos</label>
								</Grid>
								{(this.props.formularioValues.anexos || []).map((arquivo, i) => (
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											padding: 4,
										}}
									>
										<span className="btn btn-outline-primary fileinput-button dz-clickable">
											<img src={arquivo.anexo_base64 ? arquivoAdicionado : arquivo.anexo ? arquivoAdicionado : arquivoAdd} style={{ height: 30 }} />
										</span>
										<input
											className="form-control"
											placeholder="Legenda"
											style={{ marginTop: 4 }}
											readOnly={readOnly}
											type="text"
											value={arquivo.legenda}
											onChange={(event) => {
												this.props.initForm({
													...this.props.formularioValues,
													anexos: (this.props.formularioValues.anexos || []).map((arquivoAux, j) => {
														if (i == j) {
															return {
																...arquivoAux,
																legenda: event.target.value,
															};
														} else {
															return arquivoAux;
														}
													}),
												});
											}}
										/>
										<div
											style={{
												display: "flex",
												flexDirection: "row",
												justifyContent: "space-between",
												paddingRight: 0,
												marginTop: 4,
											}}
										>
											<button
												type="button"
												className="btn btn-flat btn-outline-primary"
												style={{ width: "48%", marginLeft: 0 }}
												disabled={arquivo.anexo_base64 ? true : false}
												onClick={() => {
													window.open(`https://api-eficience.infinitum-ti.com/${arquivo.anexo}`, "_blank");
												}}
											>
												<i className="fas fa-download"></i>
											</button>

											<button
												type="button"
												className="btn btn-flat btn-outline-danger"
												style={{ width: "48%" }}
												disabled={readOnly}
												onClick={() => {
													this.props.initForm({
														...this.props.formularioValues,
														anexos: this.props.formularioValues.anexos.filter((item) => item != arquivo),
													});
												}}
											>
												<i className="fas fa-trash-alt"></i>
											</button>
										</div>
									</div>
								))}
								{!readOnly ? (
									<span
										className="btn btn-outline-primary fileinput-button dz-clickable"
										style={{ marginLeft: 8 }}
										onClick={() => this.fileSelectorAnexo.click()}
									>
										<img src={arquivoAdd} style={{ height: 30 }} />
									</span>
								) : null}
							</Row>
						</ContentCardBody>
						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols="6 6 4 3">
									{this.props.excluir ? (
										<Button text="Excluir" submit type={"danger"} icon={"fa fa-trash"} disabled={this.props.visualizacao} />
									) : (
										<Button text={"Salvar"} submit type={"success"} icon={"fa fa-check"} disabled={this.props.visualizacao} />
									)}
								</Grid>
								<Grid cols="6 6 4 3">
									<Button text="Voltar" type={"warning"} icon={"fa fa-chevron-left"} event={() => this.props.setModoTela("lista")} />
								</Grid>
							</Row>
						</ContentCardFooter>
					</Form>
				</ContentCard>
			</>
		);
	}
}

DocumentoForm = reduxForm({ form: "documentoForm", destroyOnUnmount: false })(DocumentoForm);
const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	formularioValues: getFormValues("documentoForm")(state),
	registro: state.documento.registro,
	lista: state.documento.lista,
	listaCliente: state.documento.listaCliente,
	listaUnidadeConsumidora: filtrarUcPorDataDesligamento(state.documento.listaUnidadeConsumidora),
	listaDocumentoTipo: state.documento.listaDocumentoTipo,
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setModoTela,
			initForm,
		},
		dispatch
	);
export default connect(mapStateToProps, mapDispatchToProps)(DocumentoForm);
