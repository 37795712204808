import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ContentCard from "../../common/template/contentCard";
import ContentCardHeader from "../../common/template/contentCardHeader";
import ContentCardBody from "../../common/template/contentCardBody";
import Row from "../../common/layout/row";
import Grid from "../../common/layout/grid";
import Button from "../../common/button/button";
import Select from "../../common/form/select";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
	getListaGraficoValorDevolvidoAnual,
	getListaGraficoValorDevolvidoMensalAno,
	getListaGraficoValorDevolvidoRessarcidoPeriodo,
	getListaGraficoValorDevolvidoPorUC,
	setFiltro,
} from "../analiseActions";
import { montarGraficoColumn } from "../../common/graficos/montarGraficoColumn.js";
import { montarGraficoPizza } from "../../common/graficos/montarGraficoPizza.js";
import { montarGraficoHorizontalBar } from "../../common/graficos/montarGraficoHorizontalBar.js";

function analiseDevolucaoRessarcimento(props) {
	const {
		state,
		listaGraficoValorDevolvidoAnual,
		listaGraficoValorDevolvidoMensalAno,
		listaGraficoValorDevolvidoRessarcidoPeriodo,
		listaGraficoValorDevolvidoPorUC,
		filtro,
		getListaGraficoValorDevolvidoAnual,
		getListaGraficoValorDevolvidoMensalAno,
		getListaGraficoValorDevolvidoRessarcidoPeriodo,
		getListaGraficoValorDevolvidoPorUC,
		setAguardando,
		setFiltro,
	} = props;

	const [graficoValorDevolvidoAnual, setGraficoValorDevolvidoAnual] = useState(null);
	const [graficoValorDevolvidoMensalAno, setGraficoValorDevolvidoMensalAno] = useState(null);
	const [graficoValorDevolvidoRessarcidoPeriodo, setGraficoValorDevolvidoRessarcidoPeriodo] = useState(null);
	const [graficoValorDevolvidoPorUC, setGraficoValorDevolvidoPorUC] = useState(null);

	useEffect(() => {
		atualizarTodosGraficos();
		return desmontarTodosGraficos;
	}, []);

	useEffect(() => {
		const buscarDados = async () => {
			if (!state.aguardando) return;

			await Promise.all([
				getListaGraficoValorDevolvidoAnual(),
				getListaGraficoValorDevolvidoMensalAno(),
				getListaGraficoValorDevolvidoRessarcidoPeriodo(),
				getListaGraficoValorDevolvidoPorUC(),
			]);

			setAguardando(false);
		};

		buscarDados();
		atualizarTodosGraficos();
	}, [state.aguardando]);

	function atualizarTodosGraficos() {
		atualizarListaGraficoValorDevolvidoAnual();
		atualizarListaGraficoValorDevolvidoMensalAno();
		atualizarListaGraficoValorDevolvidoRessarcidoPeriodo();
		atualizarListaGraficoValorDevolvidoPorUC();
	}
	function desmontarTodosGraficos() {
		if (graficoValorDevolvidoAnual) graficoValorDevolvidoAnual.dispose();
		if (graficoValorDevolvidoMensalAno) graficoValorDevolvidoMensalAno.dispose();
		if (graficoValorDevolvidoRessarcidoPeriodo) graficoValorDevolvidoRessarcidoPeriodo.dispose();
		if (graficoValorDevolvidoPorUC) graficoValorDevolvidoPorUC.dispose();
	}

	function atualizarListaGraficoValorDevolvidoAnual() {
		const graficoValorDevolvidoAnualAtualizado = montarGraficoColumn({
			state,
			component: graficoValorDevolvidoAnual,
			nome: "grafico-valor-devolvido-anual",
			dados: listaGraficoValorDevolvidoAnual,
			category: filtro.devolucao_ano_safra ? "ano_inicio_fim_safra" : "ano",
			value: "valor",
			titulo: `Valor Devolvido por ${filtro.devolucao_ano_safra ? "Safra" : "Ano"} (R$)`,
			prefixo: "R$",
			sufixo: "",
		});
		setGraficoValorDevolvidoAnual(graficoValorDevolvidoAnualAtualizado);
	}

	function atualizarListaGraficoValorDevolvidoMensalAno() {
		const graficoValorDevolvidoMensalAnoAtualizado = montarGraficoColumn({
			state,
			component: graficoValorDevolvidoMensalAno,
			nome: "grafico-valor-devolvido-mensal-ano",
			dados: listaGraficoValorDevolvidoMensalAno,
			category: "mes",
			value: "valor",
			titulo: "Valor Devolvido por Mês (R$)",
			prefixo: "R$",
			sufixo: "",
		});
		setGraficoValorDevolvidoMensalAno(graficoValorDevolvidoMensalAnoAtualizado);
	}

	function atualizarListaGraficoValorDevolvidoRessarcidoPeriodo() {
		const graficoValorDevolvidoRessarcidoPeriodoAtualizado = montarGraficoPizza({
			state,
			component: graficoValorDevolvidoRessarcidoPeriodo,
			nome: "grafico-valor-devolvido-ressarcido-periodo",
			dados: listaGraficoValorDevolvidoRessarcidoPeriodo,
			category: "tipo",
			value: "valor",
			titulo: "Devoluções x Ressarcimentos por Período (R$)",
			prefixo: "R$",
			sufixo: "",
		});
		setGraficoValorDevolvidoRessarcidoPeriodo(graficoValorDevolvidoRessarcidoPeriodoAtualizado);
	}

	function atualizarListaGraficoValorDevolvidoPorUC() {
		const graficoValorDevolvidoPorUCAtualizado = montarGraficoHorizontalBar({
			component: graficoValorDevolvidoPorUC,
			chartDivId: "grafico-valor-devolvido-por-uc",
			category: "nome_unidade_consumidora",
			value: "valor",
			bullet: "valor",
			bulletPosition: "out",
			bulletColor: "#fff",
			lista: listaGraficoValorDevolvidoPorUC,
			titulo: "Valor Devolvido por UC no Período (R$)",
			prefixo: "R$",
			sufixo: "",
		});
		setGraficoValorDevolvidoPorUC(graficoValorDevolvidoPorUCAtualizado);
	}

	//atualiza graficos individualmente quando sua lista for atualizada --------------------------------------

	useEffect(() => {
		atualizarListaGraficoValorDevolvidoMensalAno();
	}, [listaGraficoValorDevolvidoMensalAno.length]);

	useEffect(() => {
		atualizarListaGraficoValorDevolvidoRessarcidoPeriodo();
	}, [listaGraficoValorDevolvidoRessarcidoPeriodo.length]);

	useEffect(() => {
		atualizarListaGraficoValorDevolvidoPorUC();
	}, [listaGraficoValorDevolvidoPorUC.length]);

	//--------------------------------------------------------------------------------------------------------
	return (
        <div>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody style={{ backgroundColor: "#30303d", overflowX: "scroll" }}>
                    <div
                        id="grafico-valor-devolvido-anual"
                        style={{
                            width: "100%",
                            minWidth: 900,
                            height: 400,
                        }}
                    ></div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody style={{ backgroundColor: "#30303d", overflowX: "scroll" }}>
                    <div
                        id="grafico-valor-devolvido-mensal-ano"
                        style={{
                            width: "100%",
                            minWidth: 900,
                            height: 400,
                        }}
                    ></div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardHeader>
                    <Grid cols="12 12 12 12">
                        <Row>
                            <Grid cols="12 12 2 2">
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                    <DatePicker
                                        variant="inline"
                                        openTo="year"
                                        views={["year", "month"]}
                                        label="De"
                                        autoOk
                                        value={
                                            filtro.competencia_inicial_comparativo_devolucao_ressarcimento
                                                ? `${String(
                                                      filtro.competencia_inicial_comparativo_devolucao_ressarcimento
                                                  ).substring(0, 4)}/${String(
                                                      filtro.competencia_inicial_comparativo_devolucao_ressarcimento
                                                  ).substring(4, 6)}/01 12:00:00`
                                                : null
                                        }
                                        onChange={(value) => {
                                            setFiltro({
                                                ...filtro,
                                                competencia_inicial_comparativo_devolucao_ressarcimento: value
                                                    ? parseInt(
                                                          `${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${
                                                              value.getMonth() + 1
                                                          }`
                                                      )
                                                    : null,
                                            });
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid cols="12 12 2 2">
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                    <DatePicker
                                        variant="inline"
                                        openTo="year"
                                        views={["year", "month"]}
                                        label="Até"
                                        autoOk
                                        value={
                                            filtro.competencia_final_comparativo_devolucao_ressarcimento
                                                ? `${String(
                                                      filtro.competencia_final_comparativo_devolucao_ressarcimento
                                                  ).substring(0, 4)}/${String(
                                                      filtro.competencia_final_comparativo_devolucao_ressarcimento
                                                  ).substring(4, 6)}/01 12:00:00`
                                                : null
                                        }
                                        onChange={(value) => {
                                            setFiltro({
                                                ...filtro,
                                                competencia_final_comparativo_devolucao_ressarcimento: value
                                                    ? parseInt(
                                                          `${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${
                                                              value.getMonth() + 1
                                                          }`
                                                      )
                                                    : null,
                                            });
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid cols="12 12 2 2" style={{ paddingTop: 10 }}>
                                <Button
                                    text="Atualizar"
                                    type={"primary"}
                                    icon={"fa fa-sync"}
                                    event={getListaGraficoValorDevolvidoRessarcidoPeriodo}
                                />
                            </Grid>
                        </Row>
                    </Grid>
                </ContentCardHeader>
                <ContentCardBody style={{ backgroundColor: "#30303d", overflowX: "scroll" }}>
                    <div
                        id="grafico-valor-devolvido-ressarcido-periodo"
                        style={{
                            width: "100%",
                            minWidth: 900,
                            height: 400,
                        }}
                    ></div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardHeader>
                    <Grid cols="12 12 12 12">
                        <Row>
                            <Grid cols="12 12 2 2">
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                    <DatePicker
                                        variant="inline"
                                        openTo="year"
                                        views={["year", "month"]}
                                        label="De"
                                        autoOk
                                        value={
                                            filtro.competencia_inicial_devolucao_por_uc
                                                ? `${String(filtro.competencia_inicial_devolucao_por_uc).substring(
                                                      0,
                                                      4
                                                  )}/${String(filtro.competencia_inicial_devolucao_por_uc).substring(
                                                      4,
                                                      6
                                                  )}/01 12:00:00`
                                                : null
                                        }
                                        onChange={(value) => {
                                            setFiltro({
                                                ...filtro,
                                                competencia_inicial_devolucao_por_uc: value
                                                    ? parseInt(
                                                          `${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${
                                                              value.getMonth() + 1
                                                          }`
                                                      )
                                                    : null,
                                            });
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid cols="12 12 2 2">
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                    <DatePicker
                                        variant="inline"
                                        openTo="year"
                                        views={["year", "month"]}
                                        label="Até"
                                        autoOk
                                        value={
                                            filtro.competencia_final_devolucao_por_uc
                                                ? `${String(filtro.competencia_final_devolucao_por_uc).substring(
                                                      0,
                                                      4
                                                  )}/${String(filtro.competencia_final_devolucao_por_uc).substring(
                                                      4,
                                                      6
                                                  )}/01 12:00:00`
                                                : null
                                        }
                                        onChange={(value) => {
                                            setFiltro({
                                                ...filtro,
                                                competencia_final_devolucao_por_uc: value
                                                    ? parseInt(
                                                          `${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${
                                                              value.getMonth() + 1
                                                          }`
                                                      )
                                                    : null,
                                            });
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid cols="12 12 2 2" style={{ paddingTop: 10 }}>
                                <Button
                                    text="Atualizar"
                                    type={"primary"}
                                    icon={"fa fa-sync"}
                                    event={getListaGraficoValorDevolvidoPorUC}
                                />
                            </Grid>
                        </Row>
                    </Grid>
                </ContentCardHeader>
                <ContentCardBody style={{ backgroundColor: "#30303d", overflowX: "scroll", maxHeight: 400 }}>
                    <div
                        id="grafico-valor-devolvido-por-uc"
                        style={{
                            width: "100%",
                            minWidth: 900,
                            height:
                                (listaGraficoValorDevolvidoPorUC || []).length * 50 < 400
                                    ? 400
                                    : listaGraficoValorDevolvidoPorUC.length * 50,
                        }}
                    ></div>
                </ContentCardBody>
            </ContentCard>
        </div>
    );
}

const mapStateToProps = (state) => ({
	filtro: state.analise.filtro,
	listaGraficoValorDevolvidoAnual: state.analise.listaGraficoValorDevolvidoAnual,
	listaGraficoValorDevolvidoMensalAno: state.analise.listaGraficoValorDevolvidoMensalAno,
	listaGraficoValorDevolvidoRessarcidoPeriodo: state.analise.listaGraficoValorDevolvidoRessarcidoPeriodo,
	listaGraficoValorDevolvidoPorUC: state.analise.listaGraficoValorDevolvidoPorUC,
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getListaGraficoValorDevolvidoAnual,
			getListaGraficoValorDevolvidoMensalAno,
			getListaGraficoValorDevolvidoRessarcidoPeriodo,
			getListaGraficoValorDevolvidoPorUC,
			setFiltro,
		},
		dispatch
	);
const AnaliseDevolucaoRessarcimento = connect(mapStateToProps, mapDispatchToProps)(analiseDevolucaoRessarcimento);
export { AnaliseDevolucaoRessarcimento };
