import React from "react";
import Grid from "../layout/grid";

export default (props) => {
	const titleMarcado = props.title && (props.title.marcado || "");
	const titleDesmarcado = props.title && (props.title.desmarcado || "");
	return (
		<Grid cols={props.cols} style={{ display: props.gridDisplay, justifyContent: props.gridJustifyContent, alignItems: props.gridAlignItems }}>
			<div
				htmlFor={props.id}
				className="form-group checkbox"
				style={{
					marginTop: props.divMarginTop,
					marginRight: props.divMarginRight,
					marginBottom: props.divMarginBottom,
					marginLeft: props.divMarginLeft,
					cursor: "pointer",
					textAlign: props.alignCenter ? "center" : "left",
				}}
				title={props.value || (props.input && props.input.value === true) ? titleMarcado : titleDesmarcado}
			>
				<label
					htmlFor={props.id}
					style={{
						...props.style,
						fontWeight: "bold",
						display: "flex",
						justifyContent: props.style && props.style.justifyContent,
						alignItems: (props.style && props.style.alignItems) || "center",
						cursor: "pointer",
					}}
				>
					{props.onChange && props.readOnly ? (
						<input
							id={props.id}
							{...props.input}
							style={{
								cursor: "pointer",
								width: 15,
								height: 15,
								margin: "0 15px 1px 0",
							}}
							placeholder={props.placeholder}
							disabled
							type="checkbox"
							onChange={props.onChange}
							checked={props.value}
						/>
					) : props.onChange && !props.readOnly ? (
						<input
							id={props.id}
							{...props.input}
							style={{
								cursor: "pointer",
								width: 15,
								height: 15,
								margin: "0 15px 1px 0",
							}}
							placeholder={props.placeholder}
							type="checkbox"
							onChange={props.onChange}
							checked={props.value}
						/>
					) : !props.onChange && props.readOnly ? (
						<input
							id={props.id}
							{...props.input}
							style={{
								cursor: "pointer",
								width: 15,
								height: 15,
								margin: "0 15px 1px 0",
							}}
							placeholder={props.placeholder}
							type="checkbox"
							checked={props.input.value === true}
							disabled
						/>
					) : (
						<input
							id={props.id}
							{...props.input}
							style={{
								cursor: "pointer",
								width: 15,
								height: 15,
								margin: "0 15px 1px 0",
							}}
							placeholder={props.placeholder}
							type="checkbox"
							checked={props.input.value === true}
						/>
					)}
					{props.label}
				</label>
			</div>
		</Grid>
	);
};
