import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";

import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import Tr from "../common/table/tr";
import NewTr from "../common/table/newTr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import Button from "../common/button/button";
import Form from "./empenhoForm";
import Select from "../common/form/select";
import { adicionarReticencias } from "../utils/adicionarReticencias";
import { adicionarPontosMilhar } from "../utils/adicionarPontosMilhar";
import { setModoTela, initForm, salvar, excluir, setFiltro, getLista, getListaCliente, getListaUnidadeConsumidora } from "./empenhoActions";
import { Loading } from "../common/loading/loading";

class Empenho extends Component {
	componentWillMount() {
		this.props.getListaCliente();
		this.props.getListaUnidadeConsumidora();
		this.props.getLista();
	}

	render() {
		//Sem acesso
		if (!this.props.usuarioLogado || this.props.usuarioLogado.acesso_cliente || this.props.usuarioLogado.usuario_implantacao) {
			return <div />;
		}

		return (
			<div>
				{this.props.aguardando ? <Loading /> : null}

				<Content>
					{this.props.modoTela == "visualizacao" ? <Form visualizar onSubmit={() => {}} /> : null}
					{this.props.modoTela == "cadastro" ? <Form onSubmit={this.props.salvar} /> : null}
					{this.props.modoTela == "exclusao" ? <Form excluir onSubmit={this.props.excluir} /> : null}
					{this.props.modoTela == "lista" ? this.lista() : null}
				</Content>
			</div>
		);
	}

	lista() {
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols="6 6 4 2">
							<Button
								text="Adicionar"
								type={"success"}
								icon={"fa fa-plus"}
								disabled={!this.props.usuarioLogado.acesso_empenho_adicionar}
								event={() => {
									this.props.setModoTela("cadastro", {});
									this.props.initForm({});
								}}
							/>
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>
						<Select
							name="id_cliente"
							options={this.props.listaCliente}
							label="Cliente"
							cols="12 6 4"
							placeholder="Selecione o Cliente"
							value={this.props.filtro.id_cliente}
							onChange={(value) => {
								this.props.setFiltro({
									...this.props.filtro,
									id_cliente: value,
								});
								this.props.getLista(value, null);
							}}
						/>
					</Row>
					<Table responsive>
						<THead>
							<Tr>
								<Th sticky>Cliente</Th>
								<Th sticky>Nº do Empenho</Th>
								<Th sticky>Ano de Referência</Th>
								<Th sticky>Valor Previsto</Th>
								<Th sticky>Unidades Pertencentes</Th>
								<Th sticky></Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.lista.map((item) => {
								const itemForm = {
									...item,
									valor_previsto: String(item.valor_previsto).replace(".", ","),
									unidades_consumidoras: item.unidades_consumidoras.map((uc) => ({ label: uc.nome, value: uc.id })),
								};

								return (
									<NewTr
										key={item.id}
										celulas={
											<>
												<Td maxWidth={200}>{item.nome_cliente}</Td>
												<Td>{item.numero}</Td>
												<Td>{item.ano_referencia}</Td>
												<Td>{"R$ " + adicionarPontosMilhar(String(item.valor_previsto).replace(".", ","))}</Td>
												<Td maxWidth={200}>
													<ul>
														{item.unidades_consumidoras.map((uc) => (
															<li>{adicionarReticencias(uc.numero + " - " + uc.nome, 50)}</li>
														))}
													</ul>
												</Td>
											</>
										}
										botoes={[
											{
												type: "primary",
												icon: "fas fa-eye",
												disabled: !this.props.usuarioLogado.acesso_empenho_visualizar,
												event: () => {
													this.props.setModoTela("visualizacao", itemForm);
													this.props.initForm(itemForm);
												},
											},
											{
												type: "warning",
												icon: "fas fa-pencil-alt",
												disabled: !this.props.usuarioLogado.acesso_empenho_editar,
												event: () => {
													this.props.setModoTela("cadastro", itemForm);
													this.props.initForm(itemForm);
												},
											},
											{
												type: "danger",
												icon: "fas fa-trash-alt",
												disabled: !this.props.usuarioLogado.acesso_empenho_excluir,
												event: () => {
													this.props.setModoTela("exclusao", itemForm);
													this.props.initForm(itemForm);
												},
											},
										]}
									/>
								);
							})}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}
}

const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	modoTela: state.empenho.modoTela,
	formularioValues: getFormValues("empenhoForm")(state),
	aguardando: state.empenho.aguardando,
	filtro: state.empenho.filtro,
	lista: state.empenho.lista,
	listaCliente: state.empenho.listaCliente,
	listaUnidadeConsumidora: state.empenho.listaUnidadeConsumidora,
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setModoTela,
			initForm,
			setFiltro,
			salvar,
			excluir,
			getLista,
			getListaCliente,
			getListaUnidadeConsumidora,
		},
		dispatch
	);
export default connect(mapStateToProps, mapDispatchToProps)(Empenho);
