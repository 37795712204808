import React from "react";

export default (props) => (
    <button
        title={props.title}
        type={props.submit ? "submit" : "button"}
        className={`btn  ${props.flex ? "" : "btn-block"} btn-lg  btn${props.solid ? "" : "-outline"}-${props.type}`}
        onClick={() => (props.disabled || !props.event ? () => {} : props.event())}
        disabled={props.disabled}
        style={props.style}
    >
        <i className={props.icon || ""} style={{ marginRight: props.icon ? 10 : 0 }}></i>
        {props.text}
    </button>
);

/*
primary
secondary
success
danger
warning
info
light
dark
link
*/
